import React from 'react';
import { ItemButton, t } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { Driver, DriverStore } from '../../store/Driver';
import { DriverEditModal } from 'react-logistics-masterdata/src/container/Driver/EditModal';
import AdminOverview, {
    TableHeader,
    StyledTable,
} from 'component/AdminOverview';
import { Table, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import DriverExpandRow from 'react-logistics-masterdata/src/container/Driver/ExpandRow';
import { Route } from 'react-logistics-masterdata/src/store/Route';
import { RouteEditModal } from 'react-logistics-masterdata/src/container/Route/EditModal';

const StyledTableCell = styled(Table.Cell)`
    ${({ expandEnabled, expandable }) =>
        expandable && expandEnabled && 'cursor: pointer;'}
`;
const ExpandRow = styled(Table.Row)`
    > td {
        padding: 0 !important;
    }
`;
const OverviewTable = styled(StyledTable)`
    /* we have to apply it directly to that table, because there is also table inside that table that should not have this style */
    > thead > tr > th {
        z-index: 2;
    }
`;

@observer
export default class DriverOverviewScreen extends AdminOverview {
    store = new DriverStore({
        relations: ['upcomingRoute', 'currentRoute', 'user'],
    });

    title = t('masterData:driver.overview.title');
    allowExport = true;
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;
    myFilterKey = 'driver-overview';

    ExpandRow = ExpandRow;
    TableCell = StyledTableCell;
    TableHeader = TableHeader;
    Table = OverviewTable;

    settings = (() => [
        {
            attr: (driver: Driver) => this.renderCallbackAppointmentPane(driver),
            collapsing: true,
            cellProps: { style: { padding: 0, height: 0 } },
        },
        {
            expand: (driver: Driver) =>
                driver.routesCount ? (
                    <DriverExpandRow driver={driver} />
                ) : (
                    <></>
                ),
            attr: (driver: Driver) => {
                const expanded = this.expandedCells[driver.cid] === 1;
                let icon = 'angle right';
                if (expanded) {
                    icon = 'angle down';
                }
                return driver.routesCount ? (
                    <Icon name={icon} size="large" />
                ) : (
                    <>&nbsp;</>
                );
            },
            cellProps: (driver: Driver) => ({
                style: {
                    paddingLeft: '0.8rem',
                    paddingRight: '0',
                },
                expandable: !!driver.routesCount,
            }),
            collapsing: true,
        },
        {
            label: t('masterData:driver.field.id.label'),
            attr: 'id',
            collapsing: true,
        },
        {
            label: t('masterData:driver.field.firstName.label'),
            attr: 'firstName',
            collapsing: true,
        },
        {
            label: t('masterData:driver.field.lastName.label'),
            attr: 'lastName',
            collapsing: true,
        },
        {
            label: t('masterData:driver.field.phoneNumber.label'),
            attr: 'phoneNumber',
            collapsing: true,
        },
        { collapsing: true },
    ])();

    buttons = [
        {
            type: 'custom',
            callback: (driver: Driver, id, props) => {
                return (
                    <DriverEditModal
                        model={driver}
                        store={this.store}
                        trigger={
                            <ItemButton
                                data-test-driver-edit-button
                                icon="edit"
                                label={t(
                                    'masterData:driver.overview.editButton'
                                )}
                                {...props}
                            />
                        }
                        afterSave={() => this.store.fetch()}
                    />
                );
            },
        },
        {
            type: 'custom',
            callback: (driver: Driver, id, props) => {
                return (
                    <RouteEditModal
                        model={
                            new Route(
                                { driver: driver.toJS() },
                                { relations: ['driver'] }
                            )
                        }
                        trigger={
                            <ItemButton
                                data-test-route-add-button
                                icon="add"
                                {...props}
                                label={t('masterData:driver.overview.addRouteButton')}
                            />
                        }
                        afterSave={() => this.store.fetch()}
                    />
                );
            },
        },
        { type: 'delete' },
    ];

    renderCallbackAppointmentPane(driver: Driver) {
        const route = driver.currentRoute.id
            ? driver.currentRoute
            : driver.upcomingRoute;
        if (
            route?.hasUpcomingValidFromIn14Days ||
            route?.hasUpcomingValidToIn14Days
        ) {
            const bgColor = route.hasUpcomingValidFromIn14Days ? 'red' : 'blue';
            return (
                <Popup
                    hoverable
                    data-test-route-popup
                    trigger={
                        <div
                            style={{
                                backgroundColor: bgColor,
                                width: '6px',
                                height: '100%',
                            }}
                            data-test-row-pane={driver.id}
                        ></div>
                    }
                    content={
                        <span style={{ whiteSpace: 'pre-line' }}>
                            {t('masterData:driver.overview.popup.info', {
                                n: route.daysLeftForUpcomingEvent(),
                            })}
                        </span>
                    }
                    position="left center"
                />
            );
        } else {
            return <></>;
        }
    }

    rowProps = (driver: Driver) => {
        return {
            ...super.rowProps(driver),
            'data-test-driver': driver.id,
        };
    };

    toolbar = [
        (model: Driver) => (
            <DriverEditModal
                store={this.store}
                model={model}
                trigger={
                    <ItemButton
                        data-test-driver-create-button
                        compact
                        icon="add"
                        content={t('masterData:driver.overview.addButton')}
                    />
                }
                afterSave={() => this.store.fetch()}
            />
        ),
    ];

    filters = [
        {
            label: t('masterData:driver.field.firstName.label'),
            type: 'text',
            name: '.first_name:icontains',
            placeholder: t('masterData:driver.field.firstName.placeholder'),
        },
        {
            label: t('masterData:driver.field.lastName.label'),
            type: 'text',
            name: '.last_name:icontains',
            placeholder: t('masterData:driver.field.lastName.placeholder'),
        },
        {
            label: t('masterData:driver.field.phoneNumber.label'),
            type: 'text',
            name: '.phone_number:icontains',
            placeholder: t('masterData:driver.field.phoneNumber.placeholder'),
        },
    ];
}
