import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Loader } from 'semantic-ui-react';
import { ChatMessageStore, ChatMessage, UnreadMessagesStore } from '../../store/ChatMessage';
import ChatBox, { DateStamp } from '../../component/Chat/Box'
import MessageInput from '../../component/Chat/MessageInput';
import MessageOverview from './MessageOverview'
import { formatCustomValidationErrors } from 'helpers';
import { DateTime } from 'luxon';
import { t, ViewStore, Store, Subscription } from '@code-yellow/spider';
import { Model } from 'mobx-spine';
import { HighTemplarRoom } from 'mixin/subscribe';

const MAX_FILE_SIZE_MB = 10;

/**
 * Merge two stores
 *
 * Prevents duplication of existing models. Used f.e. when reconnecting and
 * fetching the missed messages without duplicating the existing ones.
 *
 * @param {Store} mergeFromStore The store to merge objects from
 * @param {Store} mergeIntoStore The store to merge objects into
 * @param {Function} afterAdd The function to run when an object is merged
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function mergeStores<M extends Model = Model, S extends Store<M> = Store<M>>(mergeFromStore: S, mergeIntoStore: S, afterAdd?: (message: M) => void ) {
    // Loop over all items in the mergeFromStore and add them to the
    // mergeIntoStore if they do not already exist.
    mergeFromStore.models.forEach(model => {
        if (!model.id) {
            return;
        }

        const objectExists = mergeIntoStore.get(model.id);

        if (objectExists) {
            return;
        }

        const object = mergeIntoStore.add(model.toJS());
        afterAdd && afterAdd(object)
    });
}


export type ChatBoxContainerProps<
    MessageType extends ChatMessage = ChatMessage,
    MessageStoreType extends ChatMessageStore<MessageType> = ChatMessageStore<MessageType>
> = {
    viewStore: ViewStore;
    messageStore: MessageStoreType;
    unreadMessagesStore: UnreadMessagesStore<MessageType>;
    onMessageAdded?: (message: MessageType) => void;
    /**
     * Runs after the initial fetch of messages is done.
     *
     * This allows for loading the chat first and using this callback to
     * fetch any other data.
     *
     * Example usage:
     *
     * afterFetch: messageStore => {
     *     console.log(messageStore.length);
     *
     *     this.showMap = true;
     * }
     */
    afterFetch?: (messageStore: MessageStoreType) => void;
}


@observer
export default class ChatBoxContainer<
    MessageType extends ChatMessage = ChatMessage,
    MessageStoreType extends ChatMessageStore<MessageType> = ChatMessageStore<MessageType>,
    T extends ChatBoxContainerProps<MessageType, MessageStoreType> = ChatBoxContainerProps<MessageType, MessageStoreType>
> extends Component<T> {

    static defaultProps = {
        afterFetch: () => undefined,
    }

    // Relations of the ChatMessage, to be overwritten by concrete implementations
    @observable chatMessageRelations: string[] = [];

    // Store params of the ChatMessageStore, to be overwritten by concrete implementations
    @observable chatMessageStoreParams: { [key: string]: any } = {};


    // Internal fields
    @observable isInitialFetch = true;
    @observable attachedFiles: File[] = [];
    @observable messageStore?: MessageStoreType;
    @observable messageIdsMarkedAsUnread: number[] = [];
    @observable currentMessage = new ChatMessage({},
        { relations: this.chatMessageRelations },
    );

    messageOverviewRef?: React.RefObject<MessageOverview<MessageType, MessageStoreType>> = React.createRef<MessageOverview<MessageType, MessageStoreType>>();
    msgSubscription: Subscription | null = null;
    receivedSubscription: Subscription | null = null;
    seenSubscription: Subscription | null = null;
    tempStore?: MessageStoreType;

    datestampRef? : HTMLDivElement;
    @observable datestampText = 'Undefined';

    constructor(props: Readonly<T>) {
        super(props);

        this.currentMessage.text = localStorage.getItem(`chat-box-concept-message-${this.getChatId}`)  || ''
    }

    componentDidMount() {
        this.startSync(this.getChatId());
    }

    componentWillUnmount() {
        this.unsubscribeChatbox();
    }

    /**
     * Return a unique identifier for this chat box
     *
     * Provides a way of identifying this chat box for the purpose of
     * checks and fetching/syncing.
     */
    getChatId() {
        return -1;
    }

    /**
     * Check whether this chat box is allowed to fetch
     *
     * Use case: Chat box is connected to a newly created arbitrary receiver
     * model which isn't saved to the database yet (id is null).
     */
    allowFetch() {
        return true;
    }


    /**
     * Start the sync
     *
     * Does the initial fetch MessageStore and starts the websocket subscriptions.
     */
    startSync(chatId : number) {
        const { afterFetch, messageStore } = this.props;
        let pMessageStore: Promise<void>;

        this.unsubscribeChatbox();

        // Fetch the message store for this chat box (if allowed)
        if (this.allowFetch()) {
            pMessageStore = messageStore.fetch().then(() => {
                this.isInitialFetch = false;
                messageStore.forEach((message) => this.checkAndMarkMessageAsRead(message as MessageType));

                this.messageOverviewRef?.current?.scrollToBottom();
            });

            // Always call `afterFetch`, even if allowFetch returns false.
            Promise.resolve(pMessageStore).finally(() => afterFetch && afterFetch(messageStore));
        }

        this.subscribeChatbox(chatId)
    }

    /**
     * START TEMPORARY WEBSOCKET MIXIN-NOT-WORKING FIX
     *
     * The IAE subscription mixin doesn't appear to be working yet for this
     * application. Being way over budget, choosing this temp fix. When the
     * mixin works, just remove the code below.
     */
    subscriptions: Subscription[] = [];

    subscribe(room: HighTemplarRoom, callback: (data: any) => void) {
        const { messageStore } = this.props;

        if (!messageStore || !messageStore.api.socket) {
            return null;
        }

        const subscription = messageStore.api.socket.subscribe({
            room: room,
            onPublish: callback,
        })

        this.subscriptions.push(subscription);

        return subscription;
    }

    unsubscribe = () => {
        const { messageStore } = this.props;

        if (!messageStore || !messageStore.api.socket) {
            return null;
        }

        this.subscriptions.forEach(s => (messageStore && messageStore.api.socket) ? messageStore.api.socket.unsubscribe(s) : null);
        this.subscriptions = [];
    }
    /**
     * END TEMPORARY WEBSOCKET MIXIN-NOT-WORKING FIX
     */


    /**
     * Subscribe to all relevant websockets
     *
     * @returns void
     */
    subscribeChatbox(chatId : number) {
        const { messageStore } = this.props;

        if (!messageStore) {
            return;
        }

        this.msgSubscription = this.subscribe({
            target: 'message',
            entity: chatId.toString(),
        }, this.handleMsgPublish);

        this.receivedSubscription = this.subscribe({
            target: 'message-received-by-external',
            entity: chatId.toString(),
        }, this.handleMessageReceived);

        this.seenSubscription = this.subscribe({
            target: 'message-seen-by-external',
            entity: chatId.toString(),
        }, this.handleMessageSeen);
    }


    /**
     * Unsubscribe from all relevant websockets
     *
     * @returns void
     */
    unsubscribeChatbox() {
        this.unsubscribe();
    }


    /**
     * Check if the message can be marked as read and if so, do it
     *
     * @param {MessageType} message
     */
    checkAndMarkMessageAsRead(message: MessageType) {
        // Logic for marking messages as read
        if (message.seenByReceiverAt === null && message.source === 'external') {
            message.markRead(false).then(() => {
                this.markMessageAsRead(message);
            });
        }
    }


    /**
     * Handle the event of reconnecting
     *
     * Handles the event by fetching all messages and adding the missed ones
     * to the messageStore in the props.
     */
    handleReconnect = () => {
        const { messageStore } = this.props;
        const tempStore = this.generateStore();

        // Merge the stores and after adding the item to the new store, handle
        // it using handleMessageAdded()
        tempStore.fetch().then(() => {
            mergeStores<MessageType, MessageStoreType>(tempStore, messageStore, (message) => this.handleMessageAdded(message));
        })
    };


    /**
     * Handles the event of a new message being published
     *
     * @param {{ data: {}}} message
     */
    handleMsgPublish = (message) => {
        const { messageStore } = this.props;
        const chatMessage = (messageStore.get(message.data.id) || messageStore.add(message.data)) as MessageType;

        chatMessage.fetch().then(() => this.handleMessageAdded(chatMessage));
    };

    /**
     * Hook to inject into this method from the lower component MessageOverview. Often used to scroll to the bottom.
     */
    handleMessageAdd = () => {
        return;
    }


    /**
     * Handle the event of a new message being added (on publish and on reconnect)
     *
     * @param {MessageType} message
     */
    handleMessageAdded = (message: MessageType) => {
        // Hook to inject into this method from the lower component MessageOverview. Often
        // used to scroll to the bottom.
        this.handleMessageAdd()

        // Run the passed onMessageAdded hook
        if (this.props.onMessageAdded) {
            this.props.onMessageAdded(message);
        }
    };


    /**
     * Handle the event of a message changing to the received state
     *
     * @param {MessageType} message
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleMessageReceived = (message: MessageType) => undefined;


    /**
     * Handle the event of a message changing to the seen state
     *
     * @param {MessageType} message
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleMessageSeen = (message: MessageType) => undefined;



    /**
     * Creates and fetches a chat message store with default settings
     *
     * @returns ChatMessageStore
     */
    generateStore(): MessageStoreType {
        const store = new ChatMessageStore<MessageType>({
            limit: 20,
            relations: this.chatMessageRelations
        });

        store.params = {
            order_by: 'written_at',
            ...this.chatMessageStoreParams,
        };

        return store as MessageStoreType;
    }


    /**
     * Manually mark a message as read (purely in the frontend)
     *
     * @param {MessageType} message
     */
    markMessageAsRead = (message: MessageType) => {
        const { unreadMessagesStore } = this.props;

        if (!message.id) {
            return;
        }

        const unreadMessage = unreadMessagesStore.get(message.id) ? unreadMessagesStore.get(message.id) : unreadMessagesStore.add({ id: message.id });

        unreadMessage?.markRead(message);
    };


    /**
     * Manually mark a message as unread (purely in the frontend)
     *
     * @param {MessageType} message
     */
    markMessageAsUnread = (message: MessageType) => {
        const { unreadMessagesStore } = this.props;
        const chatId = this.getChatId();

        if (!message.id) {
            return;
        }

        if (!unreadMessagesStore.get(chatId)) {
            unreadMessagesStore.add({ id: chatId });
        }

        // Keep track of which messages you marked as unread
        if (this.messageIdsMarkedAsUnread.includes(message.id)) {
            return;
        } else {
            this.messageIdsMarkedAsUnread.push(message.id);
        }

        const chat = unreadMessagesStore.get(chatId);
        chat && chat[message.type]++;
    };


    /**
     * Send messages (including attachments if applicable)
     *
     * @param {File[]} files
     * @param {MessageType} messageToSend Optional message to send instead of the current message
     * @returns Promise.all object containing all files for which to run saveMessage()
     */
    sendMessagesWithFiles = (files: File[], messageToSend?: MessageType): Promise<any> => {
        const { viewStore } = this.props;
        const authorName = viewStore.currentUser.fullName;
        const now = DateTime.now();
        const inputMessage = messageToSend ?? this.currentMessage;

        // Prepare the current chat message and include attachments if applicable
        const promise = this.saveMessage(
            this.prepareMessage(authorName, now, files, inputMessage as MessageType) as MessageType
        );

        this.saveConceptMessage();
        this.currentMessage.wrapPendingRequestCount(promise);

        // After preparing and saving all messages, clear the attached files
        promise.then(() => {
            this.attachedFiles = [];
        }).catch(err => {
            if (err.response) {
                // Show an error notification for every error encountered
                formatCustomValidationErrors(err).map(e => viewStore.showNotification(e));
                viewStore.showGlobalValidationErrors(this.currentMessage);
                return;
            }

            throw err;
        });

        // Clear the current chat message
        this.clearCurrentMessage();

        return promise;
    };

    clearCurrentMessage() {
        this.currentMessage = new ChatMessage({}, { relations: this.chatMessageRelations });
    }


    /**
     * Save a chat message and add it to the store
     *
     * @param {MessageType} message
     * @returns save promise
     */
    saveMessage = (message: MessageType) => {
        const { messageStore } = this.props;
        const savePromise = message.save();

        messageStore.models.push(message);

        return savePromise;
    }


    /**
     * Prepare a message given the provided arguments and `this.currentMessage`
     *
     * Constructs a ChatMessage object with the given data.
     *
     * @param {string} authorName
     * @param {DateTime} now
     * @param {Document} file
     * @param {MessageType} messageToSend Optional message to send instead of the current message
     * @returns ChatMessage
     */
    prepareMessage = (authorName: string, now: DateTime, files: File[], messageToSend?: MessageType) => {
        const inputMessage = messageToSend ?? this.currentMessage;

        const message = new ChatMessage({
            ...inputMessage.toJS(),
            writtenAt: now,
            seenByPlannerAt: now,
            writtenBy: authorName,
            isDraft: false,
        }, { relations: this.currentMessage.__activeCurrentRelations });

        return message;
    }


    /**
     * Save a concept message to local storage
     *
     * @param {String} str
     */
    saveConceptMessage = (str = '') => {
        localStorage.setItem(`chat-box-concept-message-${this.getChatId()}`, str);
    };


    /**
     * Fetch a specific page of the store by merging it to the existing store
     *
     * @returns store setPage promise
     */
    @action
    fetchPage = async () => {
        const { messageStore } = this.props;

        // Only fetch when not already fetching, and when there is something new to fetch
        if (this.tempStore) {
            return Promise.resolve();
        }

        // The tempStore handles the fetch of the next page
        this.tempStore = this.generateStore();

        if (!this.tempStore) {
            return;
        }

        await this.tempStore.setPage(messageStore.__state.currentPage + 1);

        // Merge the (newly fetched) tempStore into the messageStore and handle the
        // message after adding it to the new store
        mergeStores<MessageType, MessageStoreType>(this.tempStore, messageStore, (message) => this.checkAndMarkMessageAsRead(message));

        // Keep track which page we already fetched
        messageStore.__state.currentPage += 1;

        // Tell the view we are done fetching data
        delete this.tempStore;
    };


    /**
     * Handle the onFileUploaded hook of the message input
     *
     * Attach valid files (if they do not exceed the max size) and display error
     * notifications for invalid files.
     *
     * @param {File[]} files
     * @param {File[]} invalidFiles
     */
    attachFiles = (files: File[], invalidFiles: File[]) => {
        const { viewStore } = this.props;

        if (files) {
            const selectedFiles = [...this.attachedFiles];

            files.forEach((file) => {
                if (file.size < (MAX_FILE_SIZE_MB * 1024 * 1024)) {
                    selectedFiles.push(file);
                } else {
                    viewStore.showNotification({
                        message: t('communication:chatmessage.maxSizeExceeded', {
                            size: MAX_FILE_SIZE_MB,
                        })
                    });
                }
            });

            this.setAttachedFiles(selectedFiles);
        }

        if (invalidFiles) {
            invalidFiles.forEach((file) => {
                viewStore.showNotification({
                    message: t('communication:chatmessage.invalidFileFormat', { name: file.name })
                });
            });
        }
    };


    /**
     * Set the list of attached files
     *
     * @param {File[]} files
     */
    setAttachedFiles = (files: File[]) => {
        this.attachedFiles = [...files];
    }


    /**
     * Adds the document to the list of attached files
     *
     * @param {File} selectedDocument
     */
    handleDocumentSelected = (selectedDocument: File) => {
        if (selectedDocument) {
            this.setAttachedFiles([...this.attachedFiles, selectedDocument]);
        }
    }


    renderNoFetchAllowed() {
        return (
            <div>Unable to load the chat</div>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModal(file?: File) {
        return;
    }

    handleDatestampRef = ref => {
        this.datestampRef = ref;
    }

    setDatestampText = (text: string) => {
        if (this.datestampRef?.innerText) {
            this.datestampRef.innerText = text
        }
    }

    render() {
        const { messageStore } = this.props;
        const disabled = this.isInitialFetch && messageStore.isLoading;

        if (!this.allowFetch()) {
            return this.renderNoFetchAllowed();
        }

        return (
            <ChatBox>
                {disabled
                    ? <Loader active={disabled} />
                    : (
                        <>
                            <DateStamp innerRef={this.handleDatestampRef}>Undefined</DateStamp>
                            <MessageOverview
                                ref={this.messageOverviewRef}
                                store={messageStore}
                                fetchPage={this.fetchPage}
                                onMessageAdd={f => (this.handleMessageAdd = f)}
                                markRead={message => this.markMessageAsRead(message as MessageType)}
                                markUnread={message => this.markMessageAsUnread(message as MessageType)}
                                onFileUploaded={this.attachFiles}
                                showAttachedFiles={(file?: File) => this.openModal(file)}
                                setDatestamp={(text : string) => this.setDatestampText(text)}
                            />
                            <MessageInput<MessageType>
                                attachedFiles={this.attachedFiles}
                                message={this.currentMessage}
                                onSubmit={this.sendMessagesWithFiles}
                                onChange={this.saveConceptMessage}
                                disabled={disabled}
                                onFileUploaded={this.attachFiles}
                                onFilesChanged={this.setAttachedFiles}
                                showAttachedFiles={() => this.openModal()}
                            />
                        </>
                    )}
            </ChatBox>
        );
    }
}
