import { t } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { GoodsDescriptionStore } from '../../store/GoodsDescription';
import AdminOverview from 'component/AdminOverview';

@observer
export default class GoodsDescriptionOverviewScreen extends AdminOverview {
    title = t('masterData:goodsDescription.overview.title');

    store = new GoodsDescriptionStore({ relations: [] });
    allowExport = true;
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;
    myFilterKey = 'goods-description-overview';

    settings = (() => [
        { label: t('masterData:goodsDescription.field.id.label'), attr: (goodsDescription) => goodsDescription.id },
        { label: t('masterData:goodsDescription.field.usageCount.label'), attr: (goodsDescription) => goodsDescription.usageCount },
        { label: t('masterData:goodsDescription.field.name.label'), attr: (goodsDescription) => goodsDescription.name },
        { collapsing: true },
    ])();

    buttons = [
        { type: 'edit', to: goods => `/master-data/goods-description/${goods.id}/edit` },
        { type: 'delete' },
    ];

    toolbar = [
        { type: 'add', to: '/master-data/goods-description/add' },
    ];

    filters = [
        {
            type: 'text',
            label: t('masterData:goodsDescription.field.name.label'),
            name: '.name:icontains',
            placeholder: t('masterData:goodsDescription.field.name.label'),
        },
    ];
}
