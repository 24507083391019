import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import EntityInvoiceTemplate from 'react-core-administration/src/store/enums/EntityInvoiceTemplate';
import { CustomerStore } from './Customer';

export class Entity extends Model {
    static backendResourceName = 'entity';

    @observable name = null;
    @observable id = null;
    @observable remarks = '';
    @observable invoiceTemplate: EntityInvoiceTemplate | null = null;
    @observable newInvoiceNumberFormat = [
        { 'type': 'date', 'part': 'year', 'format': 'yy' },
        { 'type': 'text', 'content': '01' },
        { 'type': 'code', 'digits': 6, 'expand': true, 'alphabet': '0123456789' },
      ]

    // Twinfield specific fields used for xml export.
    @observable twinfieldOffice = '';
    @observable twinfieldCode = '';

    @observable createdAt = null;
    @observable updatedAt = null;

    @observable customers = this.relation(CustomerStore);
}

export class EntityStore extends Store<Entity> {
    Model = Entity;
    static backendResourceName = 'entity';
}
