// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

i18next.addResourceBundle('nl', 'finance', nl, true);
i18next.addResourceBundle('en', 'finance', en, true);
// i18next.fallbackNS = ['finance']

// Containers

// Screen

// Store
export * from './store/Invoice';
export * from './store/Contract';
export * from './store/InvoiceLine';


// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as FinanceSettings from './settings';




