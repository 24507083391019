import styled from 'styled-components';

export const DateStamp = styled.div`
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 22px;
    background-color: #f6f6f6;
    box-shadow: 0.5px 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 5;
`;

export const MessageOverview = styled.div`
    flex: 1;
    background: white;
    overflow-y: scroll;
`;

type ChatBoxProps = {
    disabled?: boolean
}

export default styled.div<ChatBoxProps>`
    /*
        Somehow need 1px for border... In overview details left chat box would
        just be rendered over the border...
    */
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    ${({ disabled }) => disabled && `
        pointer-events: none;
    `}
`;
