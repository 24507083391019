import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { User } from 'store/User';
import { t, Subscription } from '@code-yellow/spider';
import { Button, Table } from 'semantic-ui-react';
import { DATETIME_FORMAT, DATE_FORMAT } from 'helpers';
import { IReactionDisposer, observable, reaction } from 'mobx';
import { DriverActivity, DriverActivityStore } from '../../store/DriverActivity';
import { DriverActionDocument } from '../../store/DriverActionDocument';
import subscribe from 'decorator/subscribe';
import { DriverActionComponent } from './DriverAction';

export type DriverActivitiesPreviewProps = {
    user: User,
    activitySelected: (activity: DriverActivity) => void,
    documentSelected: (document: DriverActionDocument) => void,
}

@observer
@subscribe
export class DriverActivitiesPreview extends Component<DriverActivitiesPreviewProps> {
    userReaction?: IReactionDisposer;
    driverActivitySubscription: Subscription | null = null;

    @observable driverActivityStore: DriverActivityStore = new DriverActivityStore({
        relations: ['location', 'assignedUser', 'actions.documents'],
        params: {
            '.assigned_user': null,
        },
    });

    componentDidMount() {
        this.userReaction = reaction(
            () => this.props.user?.id,
            () => {
                const { user } = this.props;

                if (user?.id) {
                    this.driverActivityStore.params['.assigned_user'] = user?.id;
                    this.driverActivityStore.fetch();
                    this._subscribeUser(user);
                } else {
                    this.driverActivityStore.clear();
                }
            },
            { fireImmediately: true },
        )

    }

    componentWillUnmount(): void {
        this.userReaction?.();
    }

    _subscribeUser(user) {
        this.driverActivitySubscription?.unsubscribe();

        this.driverActivitySubscription = this.subscribe({
            target: 'driver-app-activity-update',
            'user': user.id,
        }, () => {
            this.driverActivityStore.fetch();
        });
    }

    _renderAction = (action) => {
        const { documentSelected } = this.props;

        return (
            <DriverActionComponent action={action} documentSelected={(document) => documentSelected?.(document)} />
        );
    }

    _renderActions = (activity) => {
        return (
            <>
                {activity.actions?.map(this._renderAction)}
            </>
        );
    }

    render() {
        const { activitySelected } = this.props;

        return (
            <Table striped className="activityTable" data-test-driver-activities>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>{t('driverApp:driver.activities.activity')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('driverApp:driver.activities.location')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('driverApp:driver.activities.status')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('driverApp:driver.activities.history')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('driverApp:driver.activities.actions')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.driverActivityStore.map(activity => (
                        <Table.Row data-test-driver-activity-row={activity.id}>
                            <Table.Cell>
                                <span title={`${activity.id} ${activity.orderedArrivalDatetimeFrom?.toFormat(DATETIME_FORMAT)}`}>{activity.orderedArrivalDatetimeFrom?.toFormat(DATE_FORMAT)}</span>
                            </Table.Cell>
                            <Table.Cell>
                                {activity.type}
                            </Table.Cell>
                            <Table.Cell data-test-driver-activity-cell-city={activity.id}>
                                {activity.location.city}
                            </Table.Cell>
                            <Table.Cell data-test-driver-activity-cell-status={activity.id}>
                                {activity.status}
                            </Table.Cell>
                            <Table.Cell>
                                <Button
                                    primary
                                    size='small'
                                    icon='history'
                                    onClick={() => activitySelected?.(activity)}
                                />
                            </Table.Cell>
                            <Table.Cell data-test-driver-activity-cell-actions>
                                {this._renderActions(activity)}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}