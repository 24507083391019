import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Customer } from 'react-core-administration/src';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

const MAX_NUMBER_OF_VISIBLE_CHARACTERS = 24;

export const StyledGeneralBlueLink = styled.a`
    display: flex;
    align-items: center;
    min-width: max-content;
    color: var(--blue-500);
    padding: 4px 8px;
    margin: 4px;
    :hover {
        background: var(--gray-200);
    }
`


export class CustomerLink extends Component {
    static propTypes = {
        customer: PropTypes.instanceOf(Customer).isRequired,
    };

    render() {
        const { customer } = this.props;

        if (customer.id == null) {
            return null;
        }

        let link = (
            <StyledGeneralBlueLink
                tabIndex="-1"
                data-test-link-customer-id={customer.id}
                href={`/administration/customer/${customer.id}/edit`}
            ><>{customer?.name?.length <= MAX_NUMBER_OF_VISIBLE_CHARACTERS ?
                customer.name :
                `${customer.name.slice(0, MAX_NUMBER_OF_VISIBLE_CHARACTERS)}...`}
                </>
            </StyledGeneralBlueLink>
        )

        if (customer?.name?.length > MAX_NUMBER_OF_VISIBLE_CHARACTERS) {
            return <Popup content={customer.name} trigger={link}></Popup>
        } else {
            return link;
        }
    }
}
