export default {
    nav: {
        finance: {
            label: 'Financiën',
            invoice: 'Facturen',
            contract: 'Contracten',
            ledger: 'Grootboekrekeningen',
        },
    },
    contract: {
        create: {
            title: 'Contract',
        },
        edit: {
            title: 'Contract bewerken: {{name}}'
        },
        overview: {
            title: 'Contracten',
            addButton: 'Contract',
        },
        tab: {
            info: 'Informatie'
        },
        field: {
            name: {
                label: 'Naam'
            },
            customer: {
                label: 'Klant'
            },
            startDate: {
                label: 'Startdatum'
            },
            endDate: {
                label: 'Einddatum'
            }
        }
    },
    ledger: {
        overview: {
            title: 'Grootboekrekeningen',
        },
        create: {
            title: 'Nieuw Grootboekrekening'
        },
        edit: {
            title: 'Edit Grootboekrekening',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entiteit',
            },
            number: {
                label: 'Nummer',
            },
            description: {
                label: 'Omschrijving',
            },
            vatCode: {
                label: 'BTW Code',
            },
            vatName: {
                label: 'BTW Naam',
            },
            vatPercentage: {
                label: 'BTW Percentage',
                noVat: 'Geen BTW'
            },
        },
    },
    invoice: {
        create: {
            title: 'Nieuw factuur {{number}}'
        },
        edit: {
            noContract: 'Geen contract',
        },
        overview: {
            title: 'Facturen',
        },
        field: {
            id: {
                label: 'ID',
            },
            contract: {
                label: 'Contract'
            },
            issueDate: {
                label: 'Datum'
            },
            number: {
                label: 'Nummer'
            },
            reference: {
                label: 'Referentie'
            },
            remarks: {
                label: 'Opmerking'
            },
            totalAmount: {
                label: 'Totaalbedrag'
            },
            dueDate: {
                label: 'Vervaldatum'
            },
            customer: {
                label: 'Klant'
            },
        }
    },
    invoiceLine: {
        field: {
            description: {
                label: 'Beschrijving'
            },
            amount: {
                label: 'Bedrag'
            },
            vat: {
                label: 'BTW'
            },
            totalAmount: {
                label: 'Totaalbedrag'
            }
        }
    }
};
