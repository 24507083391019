import React, { Component } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { t, RightDivider, TargetSelect, showSaveNotification, CancelButton } from '@code-yellow/spider';

import { Entity, EntityStore } from 'react-core-administration/src/store/Entity';


@observer
export default class AssignToEntityModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        selection: PropTypes.array.isRequired,
        afterSave: PropTypes.func,
        onClose: PropTypes.func,
    };

    @observable selectedEntity = new Entity();
    @observable entityStore = new EntityStore();

    close = () => this.props.onClose?.()

    allocate = () => {
        const { selection } = this.props;

        const promiseArray = [];

        // Perform the bulk action
        selection.forEach(customer => {
            customer.entity = this.selectedEntity || new Entity();
            promiseArray.push(customer.save())
        });

        Promise.all(promiseArray)
            .then(showSaveNotification)
            .then(() => this.selectedEntity = null)
            .then(this.props.afterSave?.())
            .then(this.close);
    }

    renderContent() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <Form>
                <TargetSelect search remote clearable fluid
                    searchKey=".name:icontains"
                    label={t('administration:customer.field.entity.label')}
                    name="entity"
                    store={this.entityStore}
                    toOption={(e) => ({ value: e.id, text: e.name })}
                    onChange={(value) => this.selectedEntity = this.entityStore.get(value)}
                />
            </Form>
        );
    }

    render() {
        return (
            <Modal data-test-assign-to-entity-modal
                open={this.props.isOpen}
                onClose={this.close}
                size="mini"
            >
                <Modal.Header>
                    {t('administration:customer.overview.assignToEntityModal.title')}
                </Modal.Header>
                <Modal.Content>
                    {this.renderContent()}
                </Modal.Content>
                <Modal.Actions style={{ display: 'flex' }}>
                    <CancelButton data-test-assign-to-entity-modal-cancel
                        content={t('administration:customer.overview.assignToEntityModal.cancelButton')}
                        onClick={this.close}
                    />
                    <RightDivider />
                    <Button primary data-test-assign-to-entity-modal-confirm
                        labelPosition="left" icon="check" content={t('administration:customer.overview.assignToEntityModal.saveButton')}
                        onClick={this.allocate}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
