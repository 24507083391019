import { api } from '@code-yellow/spider';
import { EmailAttachment } from 'react-core-communication/src/store/EmailMessage';

function getUrl(entity) {
    let url = entity.url;
    if (typeof url === 'function') {
        url = entity.url();
    }

    return url;
}
/**
 * Send out an email regarding a specific entity.
 *
 * @param  {object} entity One of the models. Example: object representing a truck, activity, trip, order etc.
 * @param  {array} recipients Array of recipients. Example: ['burhan@codeyellow.nl', 'klaas@codeyellow.nl']
 * @param  {string} subject Subject of email. Variables will still be replaced. Example: 'Your package with order id {{order.id}} has arrived'
 * @param  {string} htmlContent Content of email. Variables will still be replaced. Example: 'Hello {{customer.name}}, package with order id {{order.id}} has arrived'
 * @return {Promise}
 */
export function sendEntityEmail(entity, sentTo, subject, htmlContent, selectedAttachments: EmailAttachment[], senderEmail, templateName) {
    return entity.wrapPendingRequestCount(
        api.post(getUrl(entity) + 'send_email_template/', {
            sentTo: [].concat(sentTo), // Make sure it's an array.
            subject,
            html_content: htmlContent,
            selected_attachments: selectedAttachments.map(a => a.id),
            entity: entity.toBackend(),
            from: senderEmail,
            template_name: templateName
        }
        ));
}

/**
 * Get template for entity.
 *
 * @param  {object} entity One of the models. Example: object representing a truck, activity, trip, order etc.
 * @param  {object} data that will be send in the get request
 * @return {Promise}
 */
export function getEntityEmailTemplate(entity, data) {
    // Dont wrap into entity pending requests, because that rerenders all the components below in which the entity is used and causes a lot of unnecessary requests.
    // If you need it then wrap it in the component itself manually.
    return api.get(getUrl(entity) + 'get_email_template/', data);
}
