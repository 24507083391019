import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Truck } from './Truck';
import { Trailer } from './Trailer';
import { Driver } from './Driver';
import { DRIVER, TRAILER, TRUCK } from './enums/AssetType';

export class Asset extends Model {
    static backendResourceName = 'asset';

    @observable id = null;
    @observable type = '';

    @observable truck = this.relation(Truck);
    @observable trailer = this.relation(Trailer);
    @observable driver = this.relation(Driver);

    get label() {
        switch (this.type) {
            case DRIVER:
                return 'driver: ' + this.driver.label
            case TRAILER:
                return 'trailer: ' + this.trailer.label
            case TRUCK:
                return 'truck: ' + this.truck.label
            default:
                return null
        }
    }
}

export class AssetStore extends Store {
    Model = Asset;
    static backendResourceName = 'asset';
}
