import { Model, Store } from '@code-yellow/spider';
import { computed, observable } from 'mobx';
import { DRIVER } from './enums/AssetType';
import { TachoEvent } from './TachoEvent';
import { RouteStore, Route } from './Route';
import { User } from 'store/User';

export class Driver extends Model {
    static backendResourceName = 'driver';
    static omitFields = ['routesCount'];

    @observable id = null;
    @observable type = DRIVER;
    @observable firstName = '';
    @observable lastName = '';
    @observable phoneNumber = '';
    @observable dataReference = '';
    @observable weekendBreak = '';

    //Annotations
    @observable routesCount = 0;

    @observable routes = this.relation(RouteStore);
    @observable upcomingRoute = this.relation(Route);
    @observable currentRoute = this.relation(Route);
    @observable currentTachoEvent = this.relation(TachoEvent);
    @observable user = this.relation(User);

    @computed
    get label() {
        return `${this.firstName} ${this.lastName}`;
    }

    @computed
    get subLabel() {
        return `${this.phoneNumber}`;
    }

    @computed
    get weekendBreakFormatted() {
        if (this.weekendBreak) {
            return JSON.parse(this.weekendBreak);
        }
    }

    createUser({ email, password }) {
        return this.api.put(`${this.url}create_user/`, {
            email: email,
            password: password,
        });
    }

    changeDriverData({ email, password }) {
        return this.api.put(`${this.url}change_driver_data/`, {
            email: email,
            password: password,
        });
    }

}

export class DriverStore extends Store<Driver> {
    Model = Driver;
    static backendResourceName = 'driver';
}
