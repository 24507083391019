import React from 'react';
import { CancelButton, AdminEditModal, t } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Route } from 'react-logistics-masterdata/src/store/Route';

@observer
export class RouteEditModal extends AdminEditModal {
    static propTypes = {
        model: PropTypes.instanceOf(Route).isRequired,
        ...AdminEditModal.propTypes,
    };

    Model = Route;
    relations = ['driver']
    saveRelations = ['driver'];

    fields = [
        {
            label: t('masterData:route.field.validFrom.label'),
            name: 'validFrom',
            autoComplete: 'off',
            type: 'date',
            clearable: true,
        },
        {
            label: t('masterData:route.field.validTo.label'),
            name: 'validTo',
            autoComplete: 'off',
            type: 'date',
            clearable: true,
        }
    ]

    renderHeader() {
        return this.model?.id
            ? t('masterData:route.edit.title', { id: this.model.id })
            : t('masterData:route.create.title')
    }

    renderLeftButtons() {
        return (
            <>
                <CancelButton onClick={this.onClose} />
            </>
        );
    }
}
