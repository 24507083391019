import React, { Component } from 'react';
import { Loader } from 're-cy-cle';
import styled from 'styled-components';

export const StyledPageLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:10;
`;

export class PageLoader extends Component {
    render() {
        return (
            <StyledPageLoader>
                <Loader height={50} {...this.props} />
            </StyledPageLoader>
        );
    }
}
