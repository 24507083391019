import { ViewEdit, CancelButton, RightDivider, SaveButton, TargetTextInput, t } from '@code-yellow/spider';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { Driver } from '../../store/Driver';

@observer
export class DriverEdit extends Component {
    static propTypes = {
        driver: PropTypes.instanceOf(Driver).isRequired,
        afterSave: PropTypes.func.isRequired,
    };


    save = () => {
        const { driver, afterSave } = this.props;

        return driver
            .save({ onlyChanges: true, relations: [] })
            .then(() => afterSave({ goBack: false }));
    };

    cancel = action(() => {
        const { driver } = this.props;
        return driver
            .clear();
    })


    _renderMainContent(driver) {
        return (
            <Form>
                <TargetTextInput
                    target={driver}
                    name="firstName"
                    label={t('masterData:driver.field.firstName.label')}
                    placeholder={t('masterData:driver.field.firstName.placeholder')}
                />
                <TargetTextInput
                    target={driver}
                    name="lastName"
                    label={t('masterData:driver.field.lastName.label')}
                    placeholder={t('masterData:driver.field.lastName.placeholder')}
                />
                <TargetTextInput
                    target={driver}
                    name="phoneNumber"
                    label={t('masterData:driver.field.phoneNumber.label')}
                    placeholder={t('masterData:driver.field.phoneNumber.placeholder')}
                />
                <TargetTextInput
                    target={driver}
                    name="dataReference"
                    label={t('masterData:driver.field.dataReference.label')}
                    placeholder={t('masterData:driver.field.dataReference.placeholder')}
                />
            </Form>
        )
    }

    _renderToolbarContent(driver) {
        return (
            <>
                <RightDivider />
                <CancelButton compact
                    onClick={() => { if (window.confirm(t('masterData:form.clearConfirmation'))) this.cancel() }} content={t('masterData:form.cancel')}
                    loading={driver.isLoading}
                />
                <SaveButton primary compact
                    loading={driver.isLoading}
                    onClick={this.save}
                />
            </>
        )
    }

    _renderTitle(driver) {
        return driver.id
            ? t('masterData:driver.edit.title', { id: driver.id })
            : t('masterData:driver.create.title')
    }

    render() {
        const { driver } = this.props;

        return (
            <ViewEdit
                renderTitle={() => this._renderTitle(driver)}
                renderMainContent={() => this._renderMainContent(driver)}
                renderToolbarContent={() => this._renderToolbarContent(driver)}>
            </ViewEdit>
        );
    }
}
