import { t } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { TruckingCompanyStore } from '../../store/TruckingCompany';
import AdminOverview from 'component/AdminOverview';

@observer
export default class TruckingCompanyOverviewScreen extends AdminOverview {
    store = new TruckingCompanyStore({ relations: [] });

    title = t('masterData:truckingCompany.overview.title');
    myFilterKey = 'trucking-company-overview';
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;
    allowExport = true;

    settings = [
        { label: t('masterData:truckingCompany.field.name.label'), attr: (truckingCompany) => truckingCompany.name },
        { label: t('masterData:truckingCompany.field.emailAddress.label'), attr: (truckingCompany) => truckingCompany.emailAddress },
        { label: t('masterData:truckingCompany.field.phone.label'), attr: (truckingCompany) => truckingCompany.phone },
        { label: t('masterData:truckingCompany.field.specialization.label'), attr: (truckingCompany) => truckingCompany.specialization },
        { collapsing: true },
    ];

    buttons = [
        { type: 'edit', to: (u) => `/master-data/trucking-company/${u.id}/edit` },
    ];

    toolbar = [
        { type: 'add', to: '/master-data/trucking-company/add', label: t('masterData:truckingCompany.overview.addButton') },
    ];

    filters = [
        {
            type: 'text',
            label: t('masterData:truckingCompany.field.name.label'),
            name: '.name:icontains',
        },
    ];
}
