import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderFinanceMenu = () => {
    return (
        <NavItem
            title={t('finance:nav.finance.label')}
            to="/finance/invoice/add"
            activePath="/finance"
        />
    );
};

export const renderFinanceSubMenu = () => {
    return (
        <NavMenu data-test-menu-finance>
            <NavItem
                title={t('finance:nav.finance.invoiceEntry')}
                to="/finance/invoice/add"
                activePath='Fake active path to not show the selector'
            />
            <NavItem
                title={t('finance:nav.finance.invoice')}
                to="/finance/invoice/overview"
                activePath="/finance/invoice/overview"
            />
            <NavItem
                title={t('finance:nav.finance.contract')}
                to="/finance/contract/overview"
                activePath="/finance/contract"
            />
            <NavItem
                title={t('administration:nav.administration.entity')}
                to="/finance/entity/overview"
                activePath="/finance/entity"
            />
            <NavItem
                title={t('finance:nav.finance.ledger')}
                to="/finance/ledger/overview"
                activePath="/finance/ledger"
            />
        </NavMenu>
    );
};

