import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Truck } from '../../store/Truck';
import { Card } from 'semantic-ui-react';
import { TargetTextInput, TargetSelect } from '@code-yellow/spider';
import { Link } from '@code-yellow/spider';
import { ItemButton } from '@code-yellow/spider';
import { Col } from 're-cy-cle';
import { Row as BaseRow } from '@code-yellow/spider';
import { DriverStore } from '../../store/Driver'
import styled from 'styled-components';
import { t } from '@code-yellow/spider';

const WrapperContentContact = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

@observer
export class TruckDriverEdit extends Component {
    static propTypes = {
        truck: PropTypes.instanceOf(Truck).isRequired,
    };

    driverStore = new DriverStore({
        params: {
            '.name:not': '',
            '.truck.id:not': null,
        },
        relations: ['truck'],
    });

    addDriver = () => {
        const { truck } = this.props;
        truck.drivers.add({});
    }

    componentDidMount() {
        this.driverStore.fetch();
    }

    render() {
        const { truck } = this.props;

        return (
            <Card fluid style={{ margin: '20px 0px' }}>
                {/* <GoodsCardCustom>
                    <HeaderRight as='h2' content={t('masterData:driver.field.header.label')}>
                        <Button primary
                            size='tiny'
                            content={t('masterData:driver.field.addDriver.label')}
                            icon='plus'
                            floated='right'
                            labelPosition='left'
                            onClick={() => this.addDriver()}
                            data-test-add-contact
                            disabled={truck.drivers.length}
                        />
                    </HeaderRight>
                </GoodsCardCustom> */}

                {truck.drivers.length > 0 ?
                    <Card.Content>
                        {truck.drivers.map(driver =>
                            <WrapperContentContact data-test-wrapper-contact-field>
                                <BaseRow style={{ flexGrow: '1' }}>
                                    <Col xs={6}>
                                        <TargetTextInput
                                            target={driver}
                                            name="name"
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <TargetTextInput
                                            target={driver}
                                            name="phoneNumber"
                                        />
                                    </Col>
                                </BaseRow>
                                <ItemButton as={Link} icon='edit' label={t('masterData:tooltips.edit')} to={`/master-data/driver/${driver.id}/edit`}></ItemButton>
                                <ItemButton icon='delete' label={t('masterData:tooltips.delete')} onClick={() => driver.delete()}></ItemButton>
                            </WrapperContentContact>
                        )}
                    </Card.Content>
                    :
                    this.driverStore.length > 0 && (
                        <Card.Content>
                            <WrapperContentContact data-test-wrapper-contact-field>
                                <BaseRow style={{ flexGrow: '1' }}>
                                    <Col xs={12}>
                                        <TargetSelect search remote
                                            label={t('masterData:driver.field.selectDriver.label')}
                                            searchKey=".name:icontains"
                                            target="driver"
                                            store={this.driverStore}
                                            toOption={driver => ({
                                                value: driver.id,
                                                text: driver.name,
                                            })}
                                        />
                                    </Col>
                                </BaseRow>
                            </WrapperContentContact>
                        </Card.Content>
                    )
                }
            </Card>
        );
    }
}
