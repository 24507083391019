import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Invoice } from './Invoice';
import InvoiceLineType from './enums/InvoiceLineType';
import { getObjectConstructor } from 'helpers';
import { round } from 'lodash';

export class InvoiceLine extends Model {
    static backendResourceName = 'invoice_line';
    static omitFields = ['totalAmount', 'linkedObject'];

    @observable id: number | null = null;
    @observable type?: InvoiceLineType;
    @observable vat?: number = 0;
    @observable group = 0;
    @observable ordering = 0;
    @observable amount?: number = 0;
    @observable description? : string;

    @observable invoice = this.relation(Invoice);

    get totalAmount(): number {
        return round((this.amount ?? 0) + this.vatAmount, 0);
    }

    get vatAmount(): number {
        return round((this.amount ?? 0) * (this.vat ?? 0) / 100, 0);
    }

    @observable
    private __linkedObject?: IInvoiceLineLinkedObject;
    public get linkedObject(): IInvoiceLineLinkedObject | undefined {
        return this.__linkedObject;
    }
    public set linkedObject(value: IInvoiceLineLinkedObject | undefined) {
        this.__linkedObject = value;
        if(value) {
            this.linkedObjectId = value.id;
            const Type = getObjectConstructor(value);
            this.linkedObjectType = Type.backendResourceName;
        }
        else {
            this.linkedObjectId = null;
            this.linkedObjectType = undefined;
        }
    }
    @observable linkedObjectId?: number | null;
    @observable linkedObjectType?: string;

    fetchLinkedObject<T extends IInvoiceLineLinkedObject>(type: new(data, options)=>T, options): Promise<void> {
        if(!this.linkedObjectId) {
            throw new Error('No linked object id');
        }
        this.linkedObject = new type({ id: this.linkedObjectId }, options);

        return this.linkedObject.fetch();
    }
}

export class InvoiceLineStore<M extends InvoiceLine = InvoiceLine> extends Store<M> {
    Model = InvoiceLine;
    static backendResourceName = 'invoice_line';
}

export interface IInvoiceLineLinkedObject extends Model {
    getDescription(language?: string): string;
    getAmount(): number;
}
