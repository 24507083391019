import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderDriverAppMenu = () => {
    return (
        <NavItem
            title={t('driverApp:nav.driverApp.label')}
            to="/driver-app/template/overview"
            activePath="/driver-app/template/overview"
        />
    );
};

export const renderDriverAppSubMenu = () => {
    return (
        <NavMenu data-test-menu-administration>
            <NavItem
                title={t('driverApp:nav.driverApp.driverActivityTemplate')}
                to="/driver-app/template/overview"
                activePath="/driver-app/template/overview"
            />
        </NavMenu>
    );
};

