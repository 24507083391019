import { IconCheck, IconDoneAll } from 're-cy-cle';
import styled from 'styled-components';

export const IconSeen = styled(IconDoneAll)`
    fill: #4fc3f7;
`;

export const IconReceived = styled(IconDoneAll)`
    fill: #92a58c;
`;

export const IconSent = styled(IconCheck)`
    fill: #92a58c;
`;
