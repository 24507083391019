/**
 * Default pop-up style values (text color, background color).
 */
const defaultColors = {
  dark: {
    text: '#fff',
    background: '#222',
    border: 'transparent',
    arrow: '#222'
  },
  success: {
    text: '#fff',
    background: '#8DC572',
    border: 'transparent',
    arrow: '#8DC572'
  },
  warning: {
    text: '#fff',
    background: '#F0AD4E',
    border: 'transparent',
    arrow: '#F0AD4E'
  },
  error: {
    text: '#fff',
    background: '#BE6464',
    border: 'transparent',
    arrow: '#BE6464'
  },
  info: {
    text: '#fff',
    background: '#337AB7',
    border: 'transparent',
    arrow: '#337AB7'
  },
  light: {
    text: '#222',
    background: '#fff',
    border: 'transparent',
    arrow: '#fff'
  }
};

export function getDefaultPopupColors(type) {
  return defaultColors[type] ? { ...defaultColors[type] } : undefined;
}

export const DEFAULT_PADDING = '8px 21px';

export const DEFAULT_RADIUS = {
  tooltip: 3,
  arrow: 0
};
