import Load from './../../../container/Loadable';

const CustomerOverviewScreen = Load(() => import('../screen/Customer/Overview'));
const CustomerEditScreen = Load(() => import('../screen/Customer/Overview'));
const CustomerGroupsOverviewScreen = Load(() => import('../screen/CustomerGroups/Overview'));
const CustomerGroupsEditScreen = Load(() => import('../screen/CustomerGroups/Edit'));
const DocumentOverviewScreen = Load(() => import('../screen/Document/Overview'));
const DocumentGroupsEditScreen = Load(() => import('../screen/Document/Edit'));

export const administrationRoutesConfig = [
    { path: '/administration/customer/overview', component: CustomerOverviewScreen },
    { path: '/administration/customer/:id/edit', component: CustomerEditScreen },
    { path: '/administration/customer/add', component: CustomerEditScreen },

    { path: '/administration/customer-groups/overview', component: CustomerGroupsOverviewScreen },
    { path: '/administration/customer-groups/:id/edit', component: CustomerGroupsEditScreen },
    { path: '/administration/customer-groups/add', component: CustomerGroupsEditScreen },

    { path: '/administration/document/overview', component: DocumentOverviewScreen },
    { path: '/administration/document/:id/edit', component: DocumentGroupsEditScreen },
    { path: '/administration/document/add', component: DocumentGroupsEditScreen },
];
