import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { CustomerStore } from './Customer';

export class CustomerGroup extends Model {
    static backendResourceName = 'customer_group';

    @observable id = null;
    @observable name = '';
    @observable groupNumber = '';
    @observable color = '';
    @observable remarks = '';

    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            customers: CustomerStore
        };
    }
}

export class CustomerGroupStore extends Store {
    Model = CustomerGroup;
    static backendResourceName = 'customer_group';
}
