export default {
    nav: {
        administration: {
            label: 'Administration',
            customer: 'Customers',
        },
    },
    customer: {
        overview: {
            title: 'Customer overview',
            addButton: 'Add Customer',
            bulkActions: {
                assignToCustomerGroup: 'Assign to customer group',
                assignToEntity: 'Assign to entity',
            },
            assignToCustomerGroupModal: {
                title: 'Assign to customer group',
                selectCustomerGroup: 'Select customer group',
                cancelButton: 'Cancel',
                saveButton: 'Assign',
            },
            assignToEntityModal: {
                title: 'Assign to entity',
                selectCustomerGroup: 'Select entity',
                cancelButton: 'Cancel',
                saveButton: 'Assign',
            },
        },
        create: {
            title: 'Add customer',
        },
        edit: {
            title: 'Edit customer {{name}}',
            basicInfoTitle: 'Basic info',
            updateDetailsTitle: 'Update details',
        },
        field: {
            name: {
                label: 'Name',
            },
            reference: {
                label: 'Reference',
            },
            emailAddress: {
                label: 'Email Address',
            },
            phone: {
                label: 'Phone number',
            },
            debtorNumber: {
                label: 'Debtor number',
                shortLabel: 'DebNr.'
            },
            emailFromName: {
                label: 'Customer Update "From" name'
            },
            emailFromAddress: {
                label: 'Customer Update "From" email address '
            },
            emailFromNameDocumentsInvoices: {
                label: 'Documents & Invoices "from" name',
            },
            emailFromAddressDocumentsInvoices: {
                label: 'Doc. & Invoices "from" e-mail address',
            },
            iban: {
                label: 'IBAN',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
            },
            paymentTerm: {
                label: 'Payment term (days)',
            },
            amountDue: {
                label: 'Amount due',
            },
            creditLimitAmount: {
                label: 'Credit limit',
            },
            creditLimitUsed: {
                label: 'Current credit',
            },
            creditLimitExpiryDate: {
                label: 'Expiry date',
            },
            visitingAddress:
            {
                label: 'Street'
            },
            visitingNumber:
            {
                label: 'Number'
            },
            visitingZipCode: {
                label: 'Zip Code'
            },
            visitingCity: {
                label: 'City'
            },
            visitingCountry: {
                label: 'Country'
            },
            invoicingAddress:
            {
                label: 'Street'
            },
            invoicingNumber:
            {
                label: 'Number'
            },
            invoicingZipCode: {
                label: 'Zip Code'
            },
            invoicingCity: {
                label: 'City'
            },
            invoicingCountry: {
                label: 'Country'
            },
            vatNumber: {
                label: 'VAT number'
            },
            vatCode: {
                label: 'VAT code',
                name: 'VAT name',
            },
            documents: {
                label: 'Upload documents',
            },
            emailAddressUpdate: {
                label: 'Email addresses'
            },
            invoiceLanguage: {
                label: 'Invoice language',
                option: {
                    nl: 'Dutch',
                    en: 'English',
                    de: 'German',
                }
            }
        },
    },
    customerLocation: {
        modal: {
            addHeader: 'Add address',
            editHeader: 'Edit address',
        },
        field: {
            id: { label: 'ID' },
            name: { label: 'Name' },
        }
    },
    entity: {
        field: {
            invoiceTemplate: {
                label: 'Default invoice template',
                option: {
                    nl: 'Dutch',
                    en: 'English',
                    de: 'German',
                }
            },
            newInvoiceNumberFormat: {
                label: 'New invoice number format',
            },
            twinfieldOffice: {
                label: 'Twinfield office',
            },
            twinfieldCode: {
                label: 'Twinfield code',
            },
        }
    },
    vat: {
        option: {
            de: {
                IH: {
                    code: 'IH',
                    name: 'BTW 19%'
                },
                IHMWST: {
                    code: 'IHMWST',
                    name: 'MWST 19%'
                },
                IHMWST16: {
                    code: 'IHMWST16',
                    name: 'MWST 16%'
                },
                IL: {
                    code: 'IL',
                    name: 'BTW 9%'
                },
                IN: {
                    code: 'IN',
                    name: 'BTW 0%'
                },
                VH: {
                    code: 'VH',
                    name: 'BTW 19%'
                },
                VHMWST: {
                    code: 'VHMWST',
                    name: 'MWST 19%'
                },
                VHMWST16: {
                    code: 'VHMWST16',
                    name: 'MWST 16%'
                },
                VHMWST19: {
                    code: 'VHMWST19',
                    name: 'MWST 19%'
                },
                VL: {
                    code: 'VL',
                    name: 'BTW 9%'
                },
                VN: {
                    code: 'VN',
                    name: 'BTW 0%'
                },
            },
            nl: {
                ICP: {
                    code: 'ICP',
                    name: 'BTW 0%'
                },
                ICV: {
                    code: 'ICV',
                    name: 'BTW 0%'
                },
                IH: {
                    code: 'IH',
                    name: 'BTW 21%'
                },
                IHMWST: {
                    code: 'MWST',
                    name: 'MWST 19%'
                },
                IL: {
                    code: 'IL',
                    name: 'BTW 9%'
                },
                IN: {
                    code: 'IN',
                    name: 'BTW 0%'
                },
                VH: {
                    code: 'VH',
                    name: 'BTW 21%'
                },
                VL: {
                    code: 'VL',
                    name: 'BTW 9%'
                },
                VN: {
                    code: 'VN',
                    name: 'BTW 0%'
                },
            },
            en: {
                VH: {
                    code: 'VH',
                    name: 'VAT 19%'
                },
                ICP: {
                    code: 'ICP',
                    name: 'VAT 0%'
                },
            }
        }
    }
};
