import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { uniq } from 'lodash';
import styled from 'styled-components';
import { TargetTextInput, TargetSelect, TargetTextArea } from '@code-yellow/spider';
import { UserStore } from 'store/User';
import { ContactStore } from 'react-logistics-administration/src';


const RequiredValuesWrapper = styled.div`
    ${props => props.requiredValues.length > 0 && props.requiredValues.map(val =>
        `[value=${CSS.escape(val)}] .delete.icon {
            display: none;
        }`
    )}
`;

@observer
export default class ComposeEmail extends Component {
    static propTypes = {
        wysiwyg: PropTypes.bool,
        model: PropTypes.object.isRequired,
        contactStore: PropTypes.instanceOf(ContactStore).isRequired,
        userStore: PropTypes.instanceOf(UserStore).isRequired,
        requiredContactStore: PropTypes.instanceOf(ContactStore),
        className: PropTypes.string,
        errorMessages: PropTypes.array.isRequired,
        addRecipients: PropTypes.bool,
        afterChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        errorMessages: [],
        afterChange: () => {},
    }

    handleChange = (name, value) => {
        this.props.model.setInput(name, value);
    };

    /**
     * User can add recipients which are not in the contactStore.
     */
    getRecipientOptions = () => {
        const { contactStore, userStore, model } = this.props;
        const emails = [];
        let options = [];

        if (contactStore.length) {
            contactStore.models.forEach(contact => {
                emails.push(contact.emailAddress);
                options.push({
                    value: contact.emailAddress,
                    text: contact.name + ' ' + contact.emailAddress,
                });
            });
        }

        if (userStore.length) {
            userStore.models.forEach(contact => {
                options.push({
                    value: contact.email,
                    text: contact.displayName,
                });
            });
        }

        if (model.sentTo) {
            model.sentTo.forEach(email => {
                if (!emails.includes(email)) {
                    options.push({
                        value: email,
                        text: email,
                    });
                }
            });
        }

        options = _.uniqBy(options, (item) => item.value)
        return options;
    }

    render() {
        const { model, className, errorMessages, requiredContactStore, addRecipients, afterChange } = this.props;
        const InputComponent = TargetTextArea;
        const requiredValues = requiredContactStore ? requiredContactStore.map(this.formatRecipientOption).map(c => c.value) : [];
        return (
            <div className={className}>
                <RequiredValuesWrapper requiredValues={requiredValues}>
                    <TargetSelect
                        allowAdditions
                        multiple
                        search
                        required
                        className={addRecipients === true && 'error' }
                        upward={false}
                        label={t('communication:modal.sendEmail.recipients.label')} // Model can be multiple models, even a fake one without translation (like used in invoice).
                        target={model}
                        name="sentTo"
                        options={this.getRecipientOptions()}
                        toTarget={value => uniq([...requiredValues, ...value])}
                        onBlur={(e) => {
                            // [TODO] move to TargetSelect
                            if (e.target.value !== '') {
                                model.sentTo.push(e.target.value);
                            }
                        }}
                        style={{ marginBottom: '1em' }} // overwrite the forced semantic ui selector
                    />
                </RequiredValuesWrapper>
                {/* The subject cannot contain html. */}
                <TargetTextInput
                    label={t('communication:modal.sendEmail.subject.label')} // Model can be multiple models, even a fake one without translation (like used in invoice).
                    target={model}
                    name="subject"
                    afterChange={afterChange}
                />
                <InputComponent autoHeight
                    label={t('communication:modal.sendEmail.content.label')}
                    target={model}
                    name="text"
                    mapErrors={errors => [...errors, ...errorMessages]}
                    afterChange={afterChange}
                />
            </div>
        );
    }
}
