import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import Frame from 'react-frame-component';

import { getEntityEmailTemplate } from '../services/MailService';
import { EmailMessage as Email } from '../store/EmailMessage';

const StyledFrame = styled(Frame)`
    width: 100%;
    height: 100%;
`;

const BASIC_HTML = `
<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <title></title>
    </head>
    <body>
        <div></div>
    </body>
</html>
`;


@observer
export default class RenderedEmailTemplatePreview extends Component {
    static propTypes = {
        entity: PropTypes.instanceOf(Object).isRequired,
        email: PropTypes.instanceOf(Email).isRequired,
        afterRenderTemplate: PropTypes.instanceOf(Email).isRequired,
        templateArguments: PropTypes.object,
    }

    static defaultProps = {
        afterRenderTemplate: () => { },
    }

    @observable renderedEmailTemplate = {};

    decodeHtmlEntities(str) {
        let txt = new DOMParser().parseFromString(str, 'text/html');
        return txt.documentElement.textContent;
    }

    componentDidMount() {
        const { entity, email } = this.props;

        this.updateUserMessage().then((res) => {
            email.setInput('subject', this.decodeHtmlEntities(res?.subject));
            email.setInput('text', res?.raw?.user_message);
        }).catch((err) => console.error(err));

        this.disposeEmailReaction = reaction(
            () => `${email.text} ${JSON.stringify(entity.toJS())}`,
            () => this.debouncedUpdateUserMessage(),
        );
    }

    componentWillUnmount() {
        this.disposeEmailReaction();
    }

    componentDidCatch(error, errorInfo) {
        console.log('componentDidCatch(error, errorInfo) {', error, errorInfo)
    }

    debouncedUpdateUserMessage = debounce(() => {
        const { email } = this.props;
        this.updateUserMessage(email.text).catch((err) => console.error(err));
    }, 1000)

    debouncedUpdateTemplate = debounce((message) => {
        const { email } = this.props;
        this.updateUserMessage(message).then((res) => {
            email.setInput('subject', res?.subject);
            email.setInput('text', res?.raw?.user_message);
        }).catch((err)=> console.error(err));
    }, 1000)

    updateUserMessage = userMessage => {
        const { entity, email, afterRenderTemplate, templateArguments } = this.props;

        if (entity.id == null) {
            return Promise.reject('Entity id missing');
        }

        if (entity.isLoading) {
            return Promise.reject('Entity is Loading');
        }

        if (email.isLoading) {
            return Promise.reject('Email is Loading');
        }

        return getEntityEmailTemplate(entity, {
            user_message: userMessage,
            data: {
                entity: entity.toBackend(),
            },
            ...templateArguments
        }).then(res => {
            this.renderedEmailTemplate = res;

            afterRenderTemplate(this.renderedEmailTemplate);
            return res;
        }).catch(() => {
            // Simply do nothing for now.
        });
    }

    render() {
        return (
            <StyledFrame data-test-email-preview
                key={this.renderedEmailTemplate.content}
                // Frame will crash if you don't supply a valid HTML doc. If
                // renderedEmailTemplate.content has no content yet, just render
                // an empty valid HTML document.
                // Setting initialContent is not really the way to go, since it
                // is meant for only initial render. That's why the hack where
                // key is this.renderedEmailTemplate.content to force rerender.
                initialContent={this.renderedEmailTemplate.content || BASIC_HTML}
            />
        );
    }
}
