export default {
    nav: {
        communication: {
            label: 'Communicatie',
            messagelog: 'Berichten geschiedenis',
            emailTemplate: 'E-mail templates',
        },
    },
    communication: {
        messagelog: {
            title: 'Berichten geschiedenis',
        }
    },
    message: {
        field: {
            type: {
                label: 'Type',
                value: {
                    email: 'E-mail',
                    sms: 'SMS',
                    telegram: 'Telegram',
                },
            },
            status: {
                label: 'Status',
                value: {
                    initialization_pending: 'Initialisatie in behandeling',
                    initialization_failed: 'Initialisatie gefaald',
                    delivery_pending: 'Levering in behandeling',
                    delivery_success: 'Levering succesvol',
                    delivery_failed: 'Levering gefaald',
                },
            },
            receivers: {
                label: 'Ontvanger',
            },
            dateSent: {
                label: 'Verzenddatum',
            },
            text: {
                label: 'Tekst',
            },
        },
        overview: {
            filter: {
                view: {
                    label: 'Weergaven type',
                    value: {
                        individual: 'Individueel',
                        combined: 'Gecombineerd',
                    },
                },
                clearButton: {
                    label: 'Filters wissen',
                }
            }
        },
    },
    chatmessage: {
        field: {
            text: {
                label: 'Tekst',
                placeholder: 'Schrijf je bericht hier...',
            },
            writtenAt: {
                label: 'Geschreven',
            },
            receivedAt: {
                label: 'Ontvangen',
            },
            seenAt: {
                label: 'Gezien',
            },
        },
        maxSizeExceeded: 'Bestand: {{name}} is te groot. Maximale toegestane bestandsformaat: {{size}} MB',
        invalidFileFormat: 'Bestand: {{name}} is een ongeldig formaat. Toegestaan: foto/jpeg, foto/png, bestand/pdf',
    },
    chat: {
        driverChat: {
            label: 'Chauffeur chat'
        },
    },
    emailTemplate: {
        overview: {
            title: 'E-mail template overzicht',
            addButton: 'E-mail template',
        },
        edit: {
            title: 'Bewerk e-mail template',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam',
            },
            tag: {
                label: 'Tag',
            },
            subject: {
                label: 'Onderwerp',
            },
            senderEmail: {
                label: 'Verzender',
            },
            userMessage: {
                label: 'Notitie',
            },
            manageAttachments: {
                label: 'Optie om bestanden toe te voegen',
                options: {
                    enabled: 'Actief',
                    disabled: 'Inactief',
                }
            }
        }
    },
    modal: {
        send: 'Verzenden',
        sendEmail: {
            header: 'E-mail verzenden',
            recipients: {
                label: 'Ontvanger',
            },
            subject: {
                label: 'Onderwerp',
            },
            content: {
                label: 'Bericht',
            },
            template: {
                label: 'Template',
            },
            attachments: {
                label: 'Bijlagen',
            }

        },
    }
}
