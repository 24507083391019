export default {
    nav: {
        finance: {
            label: 'Financiën',
            invoice: 'Facturen',
            contract: 'Contracten',
            invoiceEntry: 'Nieuw Factuur',
        },
    },
    contract: {
        create: {
            title: 'Contract aanmaken',
        },
        edit: {
            title: 'Contract bewerken: {{name}}'
        },
        overview: {
            title: 'Contracten',
            addButton: 'Contract',
        },
        tab: {
            info: 'Informatie'
        },
        field: {
            name: {
                label: 'Naam'
            },
            customer: {
                label: 'Klant'
            },
            startDate: {
                label: 'Start datum'
            },
            endDate: {
                label: 'Eind datum'
            }
        }
    },
    invoice: {
        create: {
            title: 'Nieuw factuur {{number}}'
        },
        edit: {
            noContract: 'Geen contract',
            button: {
                addCustomLine: 'Add custom line',
                viewInvoice: 'Opslaan & Bekijken',
            },
            subtotal: 'Subtotal',
            vat: 'BTW',
            total: 'Total',
            creditBalance: 'Credit balance',
            billableCustomers: 'Billable customers',
            otherCurrency: 'Other currency',
            customerInvoice: 'Customer invoice {{number}}',
            weekNumber: 'W nr.',
            dossierNumber: 'Dossier nr.',
            numberOfTrips: '# trips',
            dossierGroupAccordionTitle: 'Dossier remarks, documents',
            selfBillGroupAccordionTitle: 'Self-bill remarks, documents',
            lineGroupHeader: {
                trip: {
                    from: 'From',
                    to: 'To',
                    pod: 'POD',
                    tripRemarks: 'Route opmerkingen',
                    total: 'Total',
                },
                selfBillItem: {
                    costCentreNumber: 'Cost centre number',
                    distributionItem: 'Distribution item',
                    type: 'Type',
                    total: 'Total',
                },
            },
        },
        overview: {
            title: 'Facturen',
            noInvoicableCustomers: 'Er zijn geen gecontracteerde klanten met factureerbare werkzaamheden.',
            availableInvoices: 'Mogelijke facturen',
            downloadButton: 'Download Twinfield export',
        },
        field: {
            contract: {
                label: 'Contract'
            },
            issueDate: {
                label: 'Uitgiftedatum',
                week: 'Week',
                period: 'Periode',
            },
            number: {
                label: 'Nummer',
                short: 'Nr.',
                long: 'Factuurnummer',
            },
            reference: {
                label: 'Referentie'
            },
            remarks: {
                label: 'Opmerking',
                labelDetailed: 'Internal remarks (will not be send to the customer)'
            },
            totalAmount: {
                label: 'Totaalbedrag',
                short: 'Totaal',
            },
            dueDate: {
                label: 'Vervaldatum'
            },
            customer: {
                label: 'Klant'
            },
            entity: {
                label: 'Factuur entiteit',
            },
            debtorNumber: {
                label: 'Debiteurennummer',
            },
            language: {
                label: 'Factuur taal',
                option: {
                    nl: 'Nederlands',
                    en: 'Engels',
                    de: 'Duits',
                }
            },
            vatNumber: {
                label: 'BTW number'
            },
            vatCode: {
                label: 'BTW rate'
            },
            vatName: {
                label: 'BTW name'
            },
            dossier: {
                label: 'Dossier',
                dossierNumber: {
                    short: 'nr.',
                    label: 'Dossier nummer',
                },
                invoiceReference: {
                    short: 'Ref.',
                    label: 'Dossier referentie',
                },
                documents: {
                    label: 'Documenten'
                },
                remarks: {
                    label: 'Interne dossier opmerkingen'
                }
            },
            currency: {
                label: 'Valuta'
            },
            subtotalAmount: {
                label: 'Subtotaal'
            },
            dossiers: {
                label: 'Dossiers'
            },
            period: {
                label: 'Periode'
            },
            week: {
                label: 'Week'
            },
            documents: {
                label: 'Documenten'
            },
            selfBill: {
                remarks: {
                    label: 'Self-bill remarks'
                },
                documents: {
                    label: 'Documents'
                },
                reference: {
                    label: 'Self-bill referentie'
                },
            },
            selfBills: {
                label: 'Self-bills'
            },
        },
        button: {
            saveAndSend: 'Opslaan en versturen',
            infoToggle: {
                loadedInfo: '"From To"',
                customInfo: 'Description'
            }
        },
        email: {
            attachment: {
                group: {
                    document: 'Document',
                    pod: 'POD',
                }
            }
        }
    },
    invoiceLine: {
        field: {
            description: {
                label: 'Beschrijving'
            },
            amount: {
                label: 'Bedrag'
            },
            vat: {
                label: 'BTW'
            },
            totalAmount: {
                label: 'Totaalbedrag'
            }
        }
    },
    selector: {
        title: 'Selector',
        search: {
            placeholder: 'Search...',
        },
        invoice: {
            selfBilling: 'Self-billing',
        },
        selfBillItem: {
            weekNumber: 'W',
            reference: 'Reference',
            costCentreNumber: 'Cost centre number',
        }
    },
    selfBill: {
        modal: {
            linkToSelfBill: {
                header: {
                    new: 'Link self-bill',
                    linked: 'Linked self-bill',
                    dossiers: 'Dossiers {{count}}',
                    invoices: 'Invoices {{count}}',
                },
                actions: {
                    button: {
                        deleteItem: {
                            tooltip: 'Cannot delete already invoiced self-bill item',
                        },
                        linkAndGenerateInvoiceRequests: 'Link self-bill & generate invoice requests',
                        saveAndClose: 'Save & close',
                        cancel: 'Cancel',
                        unlink: {
                            label: 'Undo link and cancel invoices',
                            header: 'Undo link and cancel invoices',
                            content: 'Are you sure you want to unlink the self-bill and cancel the invoices?',
                        },
                    }
                }
            }
        },
        field: {
            period: {
                label: 'Period'
            },
            reference: {
                label: 'Reference'
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'Add remark'
            },
            documents: {
                label: 'Documents'
            },
            items: {
                error: 'Self-bill requires at least one item.',
            }
        },
        button: {
            uploadDocument: 'Upload document',
            addItem: 'Add item',
        }
    },
    selfBillItem: {
        button: {
            add: 'Add item'
        },
        field: {
            costCentreNumber: {
                label: 'Cost centre number'
            },
            distributionItem: {
                label: 'Distribution item'
            },
            type: {
                label: 'Type'
            },
            amount: {
                label: 'Amount EURO'
            },
        },
    }
};
