import Load from './../../../container/Loadable';
import { MessageLog } from '../screen/MessageLog';

const CommunicationEmailTemplateOverview = Load(() => import('../screen/EmailTemplate/Overview'));
const CommunicationEmailTemplateEdit = Load(() => import('../screen/EmailTemplate/Edit'));

export const communicationRoutesConfig = [
    { path: '/communication/messagelog/overview', component: MessageLog },
    { path: '/communication/email-template/overview', component: CommunicationEmailTemplateOverview },
    { path: '/communication/email-template/:id/edit', component: CommunicationEmailTemplateEdit },
    { path: '/communication/email-template/add', component: CommunicationEmailTemplateEdit },
];
