export default {
    nav: {
        driverApp: {
            label: 'Driver App',
            driverActivityTemplate: 'Driver Activity Template',
        }
    },
    driver: {
        authentication: {
            title: 'Driver app login',
            description: 'The driver can use the generated login link to log in to the Driver app.\n Alternatively, they can also log in using the configured email address and password.\n Note: for security reasons, the login and link are only displayed once.\n If a new password/link is required, the email and password must be entered again.',
            link: 'Driver app link',
            copy: 'Copy',
            password: 'Set new password',
            generate: 'Generate login link',
            hint: 'First save the new driver to unlock the driverapp login',
        },
        activities: {
            dossier: 'Dossier',
            trip: 'Trip',
            date: 'Date',
            driver: 'Driver',
            activity: 'Activity',
            location: 'Location',
            history: 'History',
            status: 'Status',
            actions: 'Actions',
        },
        reports: {
            title: 'Activity reports',
            actions: {
                history: 'View changes',
            },
            attachFile: 'Attach to dossier',
        }
    },
    driverActivityTemplate: {
        overview: {
            title: 'Driver Activity Templates',
        },
        headers: {
            type: 'Input type',
            label: 'Label',
            details: 'Additional instructions',
            optional: 'Required',
        },
        title: '{{type}} Template',
        add: 'Add'
    },
    driverActionTemplate: {
        types: {
            text: 'Text field',
            numeric: 'Numeric input',
            image: 'Photo',
            check: 'Checkbox',
        }
    },
    driverAppActivity: {
        field: {
            status: {
                label: 'status',
            }
        }
    },
};
