import React from 'react';
import { t, TargetSelect } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import ActiveButtons from 'react-logistics-masterdata/src/component/ActiveButtons';
import { TruckStore } from '../../store/Truck';
import { TruckingCompanyStore } from '../../store/TruckingCompany';
import AdminOverview from 'component/AdminOverview';
import { debounce } from 'lodash';
@observer
export default class TruckOverviewScreen extends AdminOverview {
    store = new TruckStore({
        relations: ['truckingCompany'],
        params: {},
    });
    fullWidth = true;
    truckingCompanyStore = new TruckingCompanyStore();

    allowExport = true;
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;
    title = t('masterData:truck.overview.title');
    myFilterKey = 'truck-overview';

    brandTypes = ['MAN', 'MB']

    componentDidMount() {
        super.componentDidMount();
        this.truckingCompanyStore.fetch();
    }

    rowProps(item, i) {
        return { 'data-test-truck': item.id };
    }

    renderButtonActive = (truck) => {
        return (
            <ActiveButtons
                asset={truck}
                renderType={this.selectedRenderType}
            />
        );
    };

    formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('ES-CL') : '';
    };

    settings = [
        {
            label: t('masterData:truck.field.fleetNumber.label'),
            attr: 'fleetNumber',
            sortKey: 'fleet_number',
            collapsing: true,
            cellProps: {
                'data-test-fleet-number': true,
            },
        },
        {
            label: t('masterData:truck.field.licensePlate.label'),
            attr: ({ licensePlate }) => licensePlate,
            sortKey: 'license_plate',
            cellProps: {
                'data-test-license-plate': true,
            },
        },
        {
            label: t('masterData:truck.field.brandType.label'),
            attr: ({ brandType }) => brandType,
            sortKey: 'brand_type',
            cellProps: {
                'data-test-brand-type': true,
            },
        },
        {
            label: t('masterData:truck.field.mileage.label'),
            attr: ({ mileage }) => mileage,
            sortKey: 'mileage',
            cellProps: {
                'data-test-mileage': true,
            },
        },
        {
            label: t('masterData:truck.field.nextApk.label'),
            attr: ({ nextApk }) => this.formatDate(nextApk),
            sortKey: 'next_apk',
            cellProps: {
                'data-test-next-apk': true,
            },
        },
        {
            label: t('masterData:truck.field.nextTacho.label'),
            attr: ({ nextTacho }) => this.formatDate(nextTacho),
            sortKey: 'next_tacho',
            cellProps: {
                'data-test-next-tacho': true,
            },
        },
        {
            label: t('masterData:truck.field.permit.label'),
            attr: ({ permit }) => permit,
            sortkey: 'permit',
            cellProps: {
                'data-test-permit': true,
            },
        },
        {
            label: t('masterData:truck.field.permitExpirationDate.label'),
            attr: ({ permitExpirationDate }) => this.formatDate(permitExpirationDate),
            sortKey: 'permit_expiration_date',
            cellProps: {
                'data-test-permit-expiration-date': true,
            },
        },
        {
            label: t('masterData:truck.field.waPermit.label'),
            // Print value as string if not a date, else as date
            attr: ({ waPermit }) => {
                if (!waPermit || isNaN(waPermit.replace(/-/g, ''))) {
                  return waPermit
                }

                return this.formatDate(new Date(waPermit))
            },
            sortKey: 'wa_permit',
            cellProps: {
                'data-test-wa-permit': true,
            },
        },
        {
            label: t('masterData:truck.field.wabco.label'),
            attr: ({ wabco }) => wabco,
            sortKey: 'wabco',
            collapsing: true,
            cellProps: {
                'data-test-wabco': true,
            },
        },
        {
            label: t('masterData:truck.field.truckingCompany.label'),
            attr: ({ truckingCompany }) => truckingCompany.name,
            sortKey: 'trucking_company.name',
            cellProps: {
                'data-test-trucking-company': true,
            },
        },
        {
            label: t('masterData:truck.field.dataSource.label'),
            attr: ({ dataSource }) => dataSource,
            sortKey: 'dataSource',
            cellProps: {
                'data-test-truck-data-source': true,
            },
        },
        {
            label: t('masterData:truck.field.active.overviewColumn'),
            attr: this.renderButtonActive,
            sortKey: 'active',
            collapsing: true,
            cellProps: {
                'data-test-active': true,
            },
        },
        { collapsing: true },
    ]

    buttons = [
        { type: 'edit', to: (u) => `/master-data/truck/${u.id}/edit` },
    ];

    toolbar = [
        { type: 'add', to: '/master-data/truck/add', label: t('masterData:truck.overview.addButton') },
    ];

    filters = [
        {
            type: 'text',
            label: t('masterData:truck.field.fleetNumber.label'),
            name: '.fleet_number_whitespace',
        },
        {
            type: 'text',
            label: t('masterData:truck.field.licensePlate.label'),
            name: '.license_plate_whitespace',
        },
        {
            type: 'custom',
            callback: () => (
                <TargetSelect
                    multiple
                    remote clearable
                    store={this.truckingCompanyStore}
                    name=".trucking_company.id:in"
                    target={this.store}
                    label={t('masterData:truck.field.owner.label')}
                    placeholder={t('masterData:truck.field.owner.label')}
                    afterChange={debounce(() => this.store.fetch(), 250)}
                    options={this.truckingCompanyStore.map(item => ({
                        value: item.id,
                        text: <span>{item.name}</span>,
                    }))}
                />
            )
        },
        {
            type: 'custom',
            callback: () => (
                <TargetSelect
                    multiple
                    remote clearable
                    store={this.entityStore}
                    name=".brand_type:in"
                    target={this.store}
                    label={t('masterData:truck.field.brandType.label')}
                    placeholder={t('masterData:truck.field.brandType.label')}
                    afterChange={debounce(() => this.store.fetch(), 250)}
                    options={this.brandTypes.map(item => ({
                        value: item,
                        text: item,
                    }))}
                />
            )
        },
        {
            type: 'radioButtons',
            name: '.active',
            label: t('masterData:truck.field.active.label'),
            options: [
                { text: t('masterData:truck.field.active.options.all'), value: undefined },
                { text: t('masterData:truck.field.active.options.yes'), value: 'true' },
                { text: t('masterData:truck.field.active.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.wabco',
            label: t('masterData:truck.field.wabco.label'),
            options: [
                { text: t('masterData:truck.field.wabco.options.all'), value: undefined },
                { text: t('masterData:truck.field.wabco.options.yes'), value: 'true' },
                { text: t('masterData:truck.field.wabco.options.no'), value: 'false' },
            ],
            primary: true
        },
    ];
}
