import { IconButton, RightDivider, TargetTextArea, t } from '@code-yellow/spider';
import { action, observable, reaction, IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';
import { FormField } from 're-cy-cle';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Location } from '../store/Location';
import { debounce } from 'lodash';
import { DEBOUNCE_WAIT } from 'helpers';
import MapComponent from 'react-logistics-planning/src/component/MapComponent';
import { Confirm } from 'semantic-ui-react';
import { CustomerLocation, CustomerLocationStore } from 'react-logistics-administration/src';
const LocationTargetTextArea = styled(TargetTextArea)`
    margin: 0!important;

    &.error {
        position: relative;

        .red {
            position: absolute !important;
            bottom: -50%;
            left: 50%;
        }
    }
    flex-grow: 1;
`
const HintSection = styled.div`
    min-height: 0px !important;
    padding: 0px !important;
    position: absolute!important;
    bottom: 0;
    width: 100%;

    .geocode {
        background-color: var(--gray-100) !important;
        border-top: 1px solid var(--gray-300) !important;
        &.saveAddressBook {
            font-size: 12px !important;
            color: var(--blue-500) !important;
        }
        &.addressNotFound {
            font-size: 12px !important;
            color: var(--red-500) !important;
        }
    }

    .hintSection > div:hover {
        background-color: var(--gray-300) !important;
    }

    font-family: sans-serif;
`;
const OpenMapText = styled.span`
    color: var(--blue-500)!important;
    font-size: 10px;
    &:hover{
        text-decoration: underline;
    }
`
const OpenMapIcon = styled(IconButton)`
    position: absolute;
    right: 0;
    opacity: 0.4!important;
`

type LocationEditFormProps = {
    location: Location;
    label?: string;
    error?: object;
    skipGeocode?: boolean;
    disabled?: boolean;
    onChange?: (location: Location | null) => void;
    onBlur?: () => void;
    onTextChange?: (text: string) => void;
    changeOnEmpty?: boolean;
    required?: boolean;
    children?: React.ReactNode;
    companyName?: string;
    collectionList?: CustomerLocationStore;
    saveAddressBook?: () => void;
    noLabel?: boolean;
}


@observer
export class LocationEditForm extends Component<LocationEditFormProps>  {
    @observable location: Location = new Location(this.props.location?.toJS());
    @observable properLocation: Location = new Location(this.props.location?.toJS());
    @observable showLocationOptions = false;
    @observable locationCurrentVal = '';
    @observable hintLocation = '';
    @observable showMapModal = false;

    static defaultProps: Partial<LocationEditFormProps> = {
        required: true,
        onBlur: () => null,
    };

    reaction?: IReactionDisposer;

    constructor(props) {
        super(props);
        this.reaction = reaction(
            () => `${this.showLocationOptions} ${this.props.companyName}`,
            () => {
                if (!this.showLocationOptions) {
                    this.location?.parse(this.props.location.toJS());
                    this.location.clearUserFieldChanges();
                    this.properLocation = this.location;
                    this.location = new Location(this.props.location?.toJS());
                }
            },
        );
    }

    componentWillUnmount() {
        this.reaction?.();
    }

    @action
    handleChange = (value) => {
        this.locationCurrentVal = value;
        const { onTextChange, onChange } = this.props;

        this.location.geoStatus = '';
        this.location.setInput('address', value);
        this.properLocation.setInput('address', value);

        onTextChange?.(value);

        if (value.length >= 3) {
            this.getGeoCodeLocation();
        } else {
            onChange?.(null);
        }
    };

    getGeoCodeLocation = debounce(() => {
        this.geocode();
    }, DEBOUNCE_WAIT)

    geocode = () => {
        const { skipGeocode, onChange, changeOnEmpty } = this.props;

        if (!skipGeocode && (this.location.address !== '' || changeOnEmpty) && this.location.__changes.includes('address')) {
            const p = this.location.geocode();

            p.then(() => {
                if (onChange) {
                    onChange(this.location);
                    this.hintLocation = this.location.address;
                }
            })
            .catch(() => {
                // Empty to make cypress happy. Tests will fail if an error occure.
                if (onChange) {
                    this.hintLocation = '';
                }
            });

            return p;
        }
    };

    handleShowLocationPoint = ( ) => {
        this.showMapModal = true;
    }

    render() {
        const { disabled, label, noLabel, required, collectionList, saveAddressBook, onChange, onBlur, ...rest } = this.props;
        const location: Location = this.properLocation;
        const errors: Array<string> = [];

        if(location.geoStatus === 'failed-no-results'){
            errors.push(t('masterData:geo.notFound'));
        }
        if(location.geoStatus === 'not-started'){
            errors.push(t('masterData:geo.notStarted'));
        }
        return (
            <>
                <Confirm
                    open={this.showMapModal}
                    onCancel={()=>this.showMapModal = false}
                    content={<>
                        <MapComponent
                            markers={[{ position: location.point }]}
                            showMap={true}
                        />
                    </>}
                    confirmButton={null}
                    data-test-map-modal
                />
                <FormField
                    error={!['', 'not-started', 'failed-no-results', 'failed-error', 'loading', 'success'].includes(location.geoStatus) ? [`masterData:geo.${location.geoStatus}`] : this.props.error}
                    required={required}
                    {...rest}
                >
                    {<div style={{ display: 'flex' }}>
                        {<LocationTargetTextArea autoHeight data-test-location
                            {...rest}
                            autoComplete="off"
                            name="address"
                            onChange={this.handleChange}
                            onBlur={() => {
                                onBlur?.();
                                this.showLocationOptions=false;
                            }}
                            noLabel={noLabel}
                            onFocus={() => this.showLocationOptions = true}
                            value={location.address}
                            target={this.properLocation}
                            disabled={disabled}
                            label={<>
                                {label || t('masterData:locationEditFreeForm.label')}
                                <RightDivider />
                                {location.id ? <OpenMapText data-test-show-location onClick={this.handleShowLocationPoint}>{t('masterData:locationEditFreeForm.showLocationText')}</OpenMapText> : null}
                            </>}
                            placeholder={t('masterData:locationEditFreeForm.placeholder')}
                            errors={errors}
                            id="locationBox"
                        />}
                        {noLabel && location.id && <OpenMapIcon onClick={this.handleShowLocationPoint} name='map outline' data-test-show-location-map />}
                    </div>}
                    {this.showLocationOptions && (
                        <HintSection className="ui active visible search selection dropdown">
                            <div aria-multiselectable="false" role="listbox" className="visible menu transition hintSection">
                                {collectionList && collectionList.map((cl: CustomerLocation) => (
                                    <div data-test-customer-location
                                        role="option"
                                        aria-checked="false"
                                        aria-selected="false"
                                        className="item"
                                        onMouseDown={() => {
                                            onChange?.(cl.location)
                                            this.showLocationOptions = false;
                                        }}
                                    >
                                        <span className="text">{cl.location?.address}</span>
                                    </div>
                                ))}
                                {this.locationCurrentVal.length >= 3 && (
                                    <>
                                        <div
                                            role="option"
                                            aria-checked="false"
                                            aria-selected="false"
                                            className={'item geocode ' + (this.hintLocation ? '' : 'addressNotFound')}
                                        >
                                            {this.hintLocation ? <span className="text" data-test-geo-location>{this.hintLocation}</span> : <span className="text" data-test-geo-location-not-found>{t('administration:activity.customerLocation.save.nf')}</span>}
                                        </div>
                                        {this.hintLocation && saveAddressBook && (
                                            <div data-test-save-as-customer-location
                                                role="option"
                                                aria-checked="false"
                                                aria-selected="false"
                                                className="item geocode saveAddressBook"
                                                onMouseDown={() => saveAddressBook()}
                                            >
                                                <span className="text">{t('administration:activity.customerLocation.save.addressBook')}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                    </HintSection>)}
                </FormField>
            </>
        );
    }
}
