import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Customer } from './Customer';

export class Contact extends Model {
    static backendResourceName = 'contact';

    @observable id = null;
    @observable type = '';
    @observable name = '';
    @observable emailAddress = '';

    @observable customer = this.relation(Customer);
}

export class ContactStore extends Store<Contact> {
    Model = Contact;
    static backendResourceName = 'contact';
}
