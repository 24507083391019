import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderPlanningMenu = () => {
    return (
        <NavItem
            title={t('planning:nav.planning.label')}
            to="/planning/operations"
            activePath="/planning"
        />
    );
};

export const renderPlanningSubMenu = () => {
    return (
        <NavMenu data-test-menu-administration>
            <NavItem
                title={t('planning:nav.planning.operations')}
                to="/planning/operations"
                activePath="/planning/operations"
            />
            <NavItem
                title={t('planning:nav.planning.forwarding')}
                to="/planning/forwarding"
                activePath="/planning/forwarding"
            />
            <NavItem
                title={t('planning:nav.planning.archive')}
                to="/planning/activity/overview"
                activePath="/planning/activity/overview"
            />
        </NavMenu>
    );
};

