import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const FullHeightModal = styled(Modal)`
    &&& {
        height: calc(90vh - 2em) !important;

        display: flex !important;
        flex-direction: column;

        > .content {
            flex: 1;
            display: flex;
            max-height: 100%!important;

            position: relative;
        }

        .header {
            background-color: var(--gray-100);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 80px !important;
        }

        .content > div {
            overflow-x: hidden;
            flex: 1;
        }

        .grid {
            height: 100%;
        }
    }
`;

export const FullHeightTableModal = styled(Modal)`
    &&& {
        height: calc(90vh - 2em) !important;

        display: flex !important;
        flex-direction: column;

        > .content {
            flex: 1;
            flex-direction: column;
            display: flex;
            padding: 0px;
            position: relative;
            max-height: 100%!important;
            background-color:red;
        }

        .content > div {
            overflow-x: hidden;
            flex: 1;
        }

        [data-test-floating-sidebar-toggle] {
            position: absolute;
            top: 0px;
            right: 0px;

            i {
                top: 0px;
                background-color: rgba(0,0,0,0.6);
            }
        }
    }
`;
