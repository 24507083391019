import { observable } from 'mobx';
import { Model, Store, Casts } from '@code-yellow/spider';
import { UserStore } from '../../../store/User';
import { DateTime } from 'luxon';

export class Message extends Model {
    static backendResourceName = 'message';
    static STATUSES = ['initialization_pending', 'initialization_failed', 'delivery_pending', 'delivery_success', 'delivery_failed'];

    @observable id: number | null = null;
    @observable type = null;
    @observable text = null;
    @observable status = 'initialization_pending';
    @observable dateSent: DateTime | null = null;
    @observable sentTo: string[] = [];
    @observable receivers = this.relation(UserStore);

    casts() {
        return {
            dateSent: Casts.luxonDatetime,
        }
    }
}

export class MessageStore extends Store<Message> {
    Model = Message
    static backendResourceName = 'message'
}
