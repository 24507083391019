import { t } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { DistrictStore } from '../../store/District';
import AdminOverview from 'component/AdminOverview';

@observer
export default class DistrictOverviewScreen extends AdminOverview {
    title = t('masterData:district.overview.title');

    store = new DistrictStore({ relations: [] });
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;
    settings = (() => [
        { label: t('masterData:district.field.id.label'), attr: (district) => district.id },
        { label: t('masterData:district.field.name.label'), attr: (district) => district.name },
        { collapsing: true },
    ])();
    myFilterKey = 'district-overview';

    filters = [
        {
            type: 'text',
            label: t('masterData:district.field.name.label'),
            name: '.name:icontains',
            placeholder: t('masterData:district.field.name.label'),
        },
    ];
}
