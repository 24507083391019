enum DossierStatus {
    NEW = 'new',
    ACCEPTED = 'accepted',
    PLANNED = 'planned',
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    ERROR = 'error',
    CANCELED = 'canceled',
}

export default DossierStatus;
