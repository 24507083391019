import { Model, Store, Casts } from '@code-yellow/spider';
import { observable, computed } from 'mobx';
import { Driver } from './Driver';

export class TruckPosition extends Model {
    static backendResourceName = 'truck_position';

    @observable id = null;
    @observable dataExternalID = null;
    @observable dataSource = null;
    @observable point = { lng: '', lat: '' };
    @observable truck = null;
    @observable measuredAt = null;
    @observable odometer = 0;
    @observable fuelLevel = null;

    @observable closestCity = '';
    @observable closestStreet = '';
    @observable closestCountry = '';

    @observable driver = this.relation(Driver);

    casts() {
        return {
            measuredAt: Casts.datetime,
        };
    }

    toLocation() {
        return {
            address: `${this._street}, ${this._city}, ${this._country}`,
            city: this._city,
            country: this._country,
            point: {
                lat: this.point.lat,
                lng: this.point.lng,
            },
        };
    }

    toLocationClosest() {
        return {
            address: this.closestAddress,
            city: this.closestCity,
            country: this.closestCountry,
            point: {
                lat: this.point.lat,
                lng: this.point.lng,
            },
        };
    }

    @computed get closestAddress() {
        return `${this.closestStreet} ${this.closestCity} (${this.closestCountry})`;
    }

    @computed get latLng() {
        return `${this.point.lat}, ${this.point.lng}`;
    }
}

export class TruckPositionStore extends Store {
    Model = TruckPosition;
    static backendResourceName = 'truck_position';
}
