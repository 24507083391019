import { afterSave } from '@code-yellow/spider';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Truck } from '../../store/Truck';
import { TruckingCompany } from '../../store/TruckingCompany';
import { TruckEdit } from '../../container/Truck/Edit';


@observer
export default class TruckEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    @observable truck = new Truck({
        id: this.props.match.params.id ? parseInt(this.props.match.params.id) : null
    },
        {
            relations: [
                'truckingCompany',
            ]
        });

    @observable truckingCompany = new TruckingCompany({
        id: this.props.match.params.truckCompanyId ? parseInt(this.props.match.params.truckCompanyId) : null,
    });

    async componentDidMount() {
        if (this.props.match.params.fleetNumber) {
            this.truck = await this.truck.getByFleetNumber(this.props.match.params.fleetNumber);
        } else if (this.truck.id) {
            this.truck.fetch();
        }

        if (this.truckingCompany.id) {
            this.truck.truckingCompany = this.truckingCompany;
        }
    }

    createNewTruck = action(() => {
        this.truck.clear();
    })

    render() {
        return (
            <TruckEdit
                truck={this.truck}
                afterSave={afterSave(this.props.history, this.props.viewStore, this.truck)}
                createNewTruck={this.createNewTruck}
                match={this.props.match}
                history={this.props.history}
                viewStore={this.props.viewStore}
            />
        );
    }
}
