import Load from './../../../container/Loadable';

const CustomerOverviewScreen = Load(() => import('../screen/Customer/Overview'));
const CustomerEditScreen = Load(() => import('../screen/Customer/Edit'));

const TripOverviewScreen = Load(() => import('../screen/Trip/Overview'));

const DossierOverviewScreen = Load(() => import('../screen/Dossier/Overview'));
const DossierEditScreen = Load(() => import('../screen/Dossier/Edit'));

const CustomerGroupsOverviewScreen = Load(() => import('react-core-administration/src/screen/CustomerGroups/Overview'));
const CustomerGroupsEditScreen = Load(() => import('react-core-administration/src/screen/CustomerGroups/Edit'));

const DocumentOverviewScreen = Load(() => import('react-core-administration/src/screen/Document/Overview'));
const DocumentGroupsEditScreen = Load(() => import('react-core-administration/src/screen/Document/Edit'));

export const administrationRoutesConfig = [
    { path: '/administration/customer/overview', component: CustomerOverviewScreen },
    { path: '/administration/customer/:id/edit', component: CustomerEditScreen },
    { path: '/administration/customer/add', component: CustomerEditScreen },

    { path: '/administration/trip/overview', component: TripOverviewScreen },

    { path: '/administration/dossier/overview', component: DossierOverviewScreen },
    { path: '/administration/dossier/add', component: DossierEditScreen },
    { path: '/administration/dossier/:id/edit', component: DossierEditScreen },
    { path: '/administration/dossier/number/:dossierNumber/edit', component: DossierEditScreen },

    { path: '/administration/customer-groups/overview', component: CustomerGroupsOverviewScreen },
    { path: '/administration/customer-groups/:id/edit', component: CustomerGroupsEditScreen },
    { path: '/administration/customer-groups/add', component: CustomerGroupsEditScreen },

    { path: '/administration/document/overview', component: DocumentOverviewScreen },
    { path: '/administration/document/:id/edit', component: DocumentGroupsEditScreen },
    { path: '/administration/document/add', component: DocumentGroupsEditScreen },
];
