import { observable, computed } from 'mobx';
import { Model, Store } from '@code-yellow/spider';
import { DriverAction } from './DriverAction';

export class DriverActionDocument extends Model {
    static backendResourceName = 'driver_app_action_document';

    @observable id = null;
    @observable file = null;
    @observable type = '';
    @observable name = '';
    @observable action = this.relation(DriverAction);

    @computed get downloadUrl() {
        return `${this.file}&download=true`;
    }
}

export class DriverActionDocumentStore extends Store {
    Model = DriverActionDocument;
    static backendResourceName = 'driver_app_action_document';
}