import React, { Component, createRef, type RefObject } from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { IconSend } from 're-cy-cle';
import { Ref, Label, Icon, List, Segment, Button, Dropdown } from 'semantic-ui-react';
import { IconButton, TargetTextArea, DroppableButton as DroppableButtonBase, t } from '@code-yellow/spider';
import styled from 'styled-components';
import { ChatMessage } from 'react-core-communication/src/index';
import { getCurrentUser } from 'helpers/currentUser';
import { Image } from 'semantic-ui-react';

const SendButton = styled(Button)`
    align-self: flex-end;
    margin-bottom: 1.5rem !important;
    margin-right: 1rem !important;
    height: 41px !important;
    width: 41px !important;
    padding: 0 !important;
`;

const Container = styled.form`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    position: relative;
`;

const StyledDropdown = styled(Dropdown)`
    position: absolute !important;
    right: 1rem;
    bottom: 0;
    color: #a7a6a6;
    z-index: 5;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
    right: 0 !important;
    left: auto !important;
    bottom: 100% !important;
    top: auto !important;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
    display: flex !important;
    align-items: center;
`;

// We don't need the TextInput component
const StyledTargetTextArea = styled(TargetTextArea)`
    flex: 1;
    padding: 1rem 1rem 1.5rem;

    textarea {
        display: block;
        max-height: 17em;
        resize: none;
        border: none !important;
        width: 100% !important;
        padding: 1rem 1.5rem;
        border-radius: 0.28571429rem !important;
        background-color: #f5f6f8;
    }
`;

const MemeArrow = styled(IconSend)`
    transition: transform 0.2s ease-out;
    transform: rotate(330deg) translateX(2px);
`;

const FilesPreviewContainer = styled.div`
    position: absolute;
    left: 1px;
    bottom: 60px;
    background: white;
    width: 100%;
    height: 130px;
    padding: 10px;
    display: inline;
    z-index : 1;
`;

const StyledFileName = styled.div`
    max-width: 80px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

const DroppableButton = styled(DroppableButtonBase)`
    margin-left: 1em !important;
`;

export type MessageInputProps<
    MessageType extends ChatMessage = ChatMessage
> = {
    message: ChatMessage,
    onSubmit: (files: File[], messageToSend?: MessageType) => Promise<any>,
    onChange: (text: string) => void,
    onFileUploaded: (files: File[], invalidFiles: File[]) => void,
    disabled: boolean,
    attachedFiles: File[],
    onFilesChanged?: (files: File[]) => void,
    showAttachedFiles: () => void,
    initialValue?: string,
}


@observer
export default class MessageInput<
    MessageType extends ChatMessage = ChatMessage,
    Props extends MessageInputProps<MessageType> = MessageInputProps<MessageType>
> extends Component<Props> {

    @observable val = '';
    @observable settingCtrlEnterToSend = false;
    @observable acceptedFiles: File[] = [];
    refChatInput: RefObject<HTMLElement> = createRef();

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.renderFileElement = this.renderFileElement.bind(this);
        this.renderFilesPreview = this.renderFilesPreview.bind(this);
    }


    componentDidMount() {
        const currentUser = getCurrentUser();

        this.settingCtrlEnterToSend = localStorage.getItem(`setting-ctrl-enter-to-send-${currentUser?.id}`) === 'true';
    }

    componentDidUpdate(prevProps) {
        const { attachedFiles } = this.props;

        if (prevProps.attachedFiles !== attachedFiles) {
            if (attachedFiles && attachedFiles.length > 0) {
                this.acceptedFiles = [...attachedFiles];
            }
        }
    }

    UNSAFE_componentWillMount() {
        const { initialValue } = this.props;

        if (initialValue) {
            this.val = initialValue;
        }
    }

    setSettingCtrlEnterToSend = (ctrlEnterToSend) => {
        const currentUser = getCurrentUser();

        this.settingCtrlEnterToSend = ctrlEnterToSend;

        if (currentUser) {
            localStorage.setItem(`setting-ctrl-enter-to-send-${currentUser.id}`, this.settingCtrlEnterToSend ? 'true' : 'false');
        }
    }

    submit = () => {
        if (this.props.disabled) {
            return
        }

        this.props.onSubmit([...this.acceptedFiles]).then(() => {
            if (this.refChatInput?.current?.firstChild) {
                const chatInput = this.refChatInput.current.firstChild as HTMLElement;
                chatInput.focus();
            }
        });
        this.val = '';
        this.acceptedFiles = [];
    };

    cancelAllFiles = () => {
        const { onFilesChanged } = this.props;

        this.acceptedFiles = [];
        onFilesChanged?.(this.acceptedFiles);
    }

    cancelFile = (file) => {
        const { onFilesChanged } = this.props;

        this.acceptedFiles = this.acceptedFiles.filter((f) => f !== file);
        onFilesChanged?.(this.acceptedFiles);
    }

    @computed
    get shouldDisableInput() {
        const { message, disabled } = this.props;
        const canSendFiles = this.acceptedFiles && this.acceptedFiles.length > 0;

        // When the message is loading, don't allow another request.
        if (message.isLoading) {
            return true;
        }

        // When the message is empty, there is nothing to send.
        if (!(message.text || canSendFiles)) {
            return true;
        }

        // When `disabled` is true in the props, simple disable.
        if (disabled) {
            return true;
        }

        return false;
    }

    renderFileElement = (file: File) => {
        let isPdf = false;
        let url: string | null = null;
        let fileName: string | null = null;

        if (file) {
            isPdf = file.type === 'application/pdf';
            url = file.preview;

            if (!url) {
                url = URL.createObjectURL(file);
            }

            fileName = file.name;
        }

        return (
            <List.Item>
                <Segment>
                    {!isPdf && <Image centered
                        src={url}
                        height='64px'
                        width='64px'
                    />}
                    {isPdf && <IconButton centered
                        onClick={() => window.open(`${url}`, '_blank')}
                        size='huge'
                        name="file"
                    />}
                    <Label
                        onClick={() => this.cancelFile(file)}
                        as='a'
                        corner='right'
                        icon='delete'>
                        <Icon name='delete' />
                    </Label>
                    <StyledFileName>{fileName}</StyledFileName>
                </Segment>
            </List.Item>
        );
    }

    renderFilesPreview = () => {
        return (
            <FilesPreviewContainer>
                <Icon name='close'
                    onClick={() => this.cancelAllFiles()}
                    size='large'
                    style={{
                        float: 'right',
                        color: 'black',
                    }}
                />
                <List horizontal style={{
                    display: 'flex',
                    overflowX: 'scroll'
                }}>
                    {this.acceptedFiles.map((file) => this.renderFileElement(file))}
                </List>
            </FilesPreviewContainer>
        );
    }

    render() {
        const { message, onChange, onFileUploaded, disabled } = this.props;
        const showFilesPreview = this.acceptedFiles && this.acceptedFiles.length > 0

        return (
            <Container key={message.cid} data-test-chat-input>
                {showFilesPreview && this.renderFilesPreview()}
                <DroppableButton data-test-upload-document-modal
                    onDrop={onFileUploaded}
                    accept={'image/jpeg, image/png, application/pdf'}
                    multiple={false}
                    title={t('communication:chat.uploadDocuments.label')}
                />
                <Ref innerRef={this.refChatInput}>
                    <StyledTargetTextArea autoFocus autoHeight noLabel fluid
                        onKeyPress={e => {
                            if (e.which === 13) {
                                if (this.settingCtrlEnterToSend && e.ctrlKey) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.submit();
                                } else if (!this.settingCtrlEnterToSend && !e.shiftKey) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.submit();
                                }
                            }
                        }}
                        placeholder={t('communication:chatmessage.field.text.placeholder') ?? undefined}
                        target={message}
                        name="text"
                        disabled={disabled || message.isLoading}
                        afterChange={() => {
                            onChange(message.text);
                        }}
                    />
                </Ref>
                <SendButton data-test-send-button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.submit();
                }} disabled={this.shouldDisableInput}>
                    <MemeArrow width="22" height="22" />
                </SendButton>
                <StyledDropdown text={this.settingCtrlEnterToSend ? 'ctrl + enter to send' : 'enter to send'} pointing="bottom right" data-test-send-shortcut-dropdown>
                    <StyledDropdownMenu>
                        <StyledDropdownItem onClick={() => this.setSettingCtrlEnterToSend(true)} data-test-setting-ctrl-enter>
                            <Icon name={this.settingCtrlEnterToSend ? 'check' : undefined} />
                            <div>
                                <Label horizontal>ctrl + enter</Label>to send, <Label horizontal>enter</Label>to add a new line
                            </div>
                        </StyledDropdownItem>
                        <Dropdown.Divider />
                        <StyledDropdownItem onClick={() => this.setSettingCtrlEnterToSend(false)} data-test-setting-enter>
                            <Icon name={!this.settingCtrlEnterToSend ? 'check' : undefined} />
                            <div>
                                <Label horizontal>enter</Label>to send, <Label horizontal>shift + enter</Label>to add a new line
                            </div>
                        </StyledDropdownItem>
                    </StyledDropdownMenu>
                </StyledDropdown>
            </Container>
        );
    }
}
