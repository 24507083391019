import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderAdministrationMenu = () => {
    return (
        <NavItem
            title={t('administration:nav.administration.label')}
            to="/administration/dossier/overview"
            activePath="/administration"
        />
    );
};

export const renderAdministrationSubMenu = () => {
    return (
        <NavMenu data-test-menu-administration>
            <NavItem
                title={t('administration:nav.administration.dossierEntry')}
                to="/administration/dossier/add"
                activePath="/administration/dossier/add"
            />
            <NavItem
                title={t('administration:nav.administration.dossier')}
                to="/administration/dossier/overview"
                activePath="/administration/dossier/overview"
            />
            <NavItem
                title={t('administration:nav.administration.customer')}
                to="/administration/customer/overview"
                activePath="/administration/customer/"
            />
            <NavItem
                title={t('administration:nav.administration.tripsOverview')}
                to="/administration/trip/overview"
                activePath="/administration/trip/overview"
            />
            <NavItem
                title={t('administration:nav.administration.customerGroups')}
                to="/administration/customer-groups/overview"
                activePath="/administration/customer-groups/"
            />
            <NavItem
                title={t('administration:nav.administration.document')}
                to="/administration/document/overview"
                activePath="/administration/document"
            />
        </NavMenu>
    );
};

