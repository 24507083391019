// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

// Components

// Containers

// Screen

// Store

// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as PlanningSettings from './settings';

i18next.addResourceBundle('nl', 'planning', nl, true);
i18next.addResourceBundle('en', 'planning', en, true);
i18next.fallbackNS = ['planning']

export default function noDefaultExport() {
    throw Error('Default export is not defined')
 }