import React from 'react';
import { AdminOverview as BaseAdminOverview, TableHeader as BaseTableHeader }  from '@code-yellow/spider';
import { TAB_TITLE_PREFIX } from 'helpers';
import Toolbar from 'component/Toolbar'
import { toJS } from 'mobx';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Store } from 'mobx-spine';

// Copy paste of IAE because its not fucking exported, geez...
export const StyledTable = styled(Table)`
    &.ui[class*="very compact"].table {
        td {
            padding: 4px 8px;
        }
        th {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
`;

// HOC to pass className property properly to the TableHeader component(it needs to be inside setting parameter)
function TableHeader ({
    className,
    setting: baseSetting,
    ...rest
}) {
    const setting = {
        ...baseSetting,
        props: {
            className: className,
            ...baseSetting?.props,
        }
    }

    return (
        <BaseTableHeader {...rest} setting={setting} />
    );
}

TableHeader.propTypes = {
    className: PropTypes.string,
    overview: PropTypes.object.isRequired,
    store: PropTypes.instanceOf(Store).isRequired,
    setting: PropTypes.object.isRequired,
}

export { TableHeader };

export default class AdminOverview extends BaseAdminOverview {
    tabTitlePrefix = TAB_TITLE_PREFIX + ' - ';
    Toolbar = Toolbar
    Table = StyledTable;
    TableHeader = TableHeader;

    // Spider does not go well with dynamic observable settings, this one fixes that without losing mobx functionalities
    getSettings() {
        return toJS(super.getSettings());
    }
}
