import Load from './../../../container/Loadable';

const TruckingCompanyOverviewScreen = Load(() => import('../screen/TruckingCompany/Overview'));
const TruckingCompanyEditScreen = Load(() => import('../screen/TruckingCompany/Edit'));
const TruckOverviewScreen = Load(() => import('../screen/Truck/Overview'));
const TruckEditScreen = Load(() => import('../screen/Truck/Edit'));
const TrailerOverviewScreen = Load(() => import('../screen/Trailer/Overview'));
const TrailerEditScreen = Load(() => import('../screen/Trailer/Edit'));
const DriverOverviewScreen = Load(() => import('../screen/Driver/Overview'));
const DriverEditScreen = Load(() => import('../screen/Driver/Edit'));
const DistrictOverviewScreen = Load(() => import('../screen/District/Overview'));
const GoodsDescriptionOverviewScreen = Load(() => import('../screen/GoodsDescription/Overview'));
const GoodsDescriptionEditScreen = Load(() => import('../screen/GoodsDescription/Edit'));

export const masterDataRoutesConfig = [
    { path: '/master-data/trucking-company/overview', component: TruckingCompanyOverviewScreen },
    { path: '/master-data/trucking-company/:id/edit', component: TruckingCompanyEditScreen },
    { path: '/master-data/trucking-company/add', component: TruckingCompanyEditScreen },

    { path: '/master-data/truck/overview', component: TruckOverviewScreen },
    { path: '/master-data/truck/:id/edit', component: TruckEditScreen },
    { path: '/master-data/truck/fleet-number/:fleetNumber/edit', component: TruckEditScreen },
    { path: '/master-data/truck/add/trucking-company/:truckCompanyId', component: TruckEditScreen },
    { path: '/master-data/truck/add', component: TruckEditScreen },

    { path: '/master-data/trailer/overview', component: TrailerOverviewScreen },
    { path: '/master-data/trailer/add/trucking-company/:truckCompanyId', component: TrailerEditScreen },
    { path: '/master-data/trailer/add', component: TrailerEditScreen },
    { path: '/master-data/trailer/:id/edit', component: TrailerEditScreen },
    { path: '/master-data/trailer/fleet-number/:fleetNumber/edit', component: TrailerEditScreen },

    { path: '/master-data/driver/overview', component: DriverOverviewScreen },
    { path: '/master-data/driver/:id/edit', component: DriverEditScreen },
    { path: '/master-data/driver/add', component: DriverEditScreen },

    { path: '/master-data/district/overview', component: DistrictOverviewScreen },

    { path: '/master-data/goods-description/overview', component: GoodsDescriptionOverviewScreen },
    { path: '/master-data/goods-description/:id/edit', component: GoodsDescriptionEditScreen },
    { path: '/master-data/goods-description/add', component: GoodsDescriptionEditScreen },
];
