import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ChatMessage } from '../../store/ChatMessage';
import MessageTypeTextExternal from './MessageTypes/TextExternal';
import MessageTypeTextInternal from './MessageTypes/TextInternal';

@observer
export default class MessageOverviewItem extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(ChatMessage).isRequired,
        markRead: PropTypes.func.isRequired,
        markUnread: PropTypes.func.isRequired,
    };

    render() {
        const { model } = this.props;

        // To concretely implement
        if (model && true) {
            return <MessageTypeTextInternal {...this.props} />;
        }
        return <MessageTypeTextExternal {...this.props} />;
    }
}
