import styled from 'styled-components';

const InnerRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: ${props => (props.isFromExternal ? '0 0 0 15px' : '0 0 0 0')};
    margin-bottom: 15px;

    &:first-child {
        margin-top: 15px;
    }
`;

export default InnerRow;
