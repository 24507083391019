import { Model, Store, Casts } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Customer } from 'react-core-administration/src/store/Customer';

export class Contract extends Model {
    static backendResourceName = 'contract';

    @observable id = null;

    @observable name = '';
    @observable startDate = null;
    @observable endDate = null;

    @observable customer = this.relation(Customer);

    casts() {
        return {
            startDate: Casts.luxonDate,
            endDate: Casts.luxonDate,
        };
    }
}

export class ContractStore extends Store<Contract> {
    Model = Contract;
    static backendResourceName = 'contract';
}
