import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Activity } from '../../../react-logistics-administration/src/store/Activity';
import { Asset } from 'react-logistics-masterdata/src';

export class AssetAllocation extends Model {
    static backendResourceName = 'asset_allocation';

    @observable id = null;
    @observable role = null;

    @observable activity = this.relation(Activity);
    @observable asset = this.relation(Asset);
}

/**
 * Comparator to order asset allocations chronologically by their activities
 *
 *
 * If the activities have the same date and time, the activity's `ordering` field
 * is used (the order of the activities in the dossier).
 */
export const AssetAllocationComperator = ((a1: AssetAllocation, a2: AssetAllocation): number => {
    if (!a1.activity?.orderedArrivalDatetimeFrom) {
        return 1;
    }

    if (!a2.activity?.orderedArrivalDatetimeFrom) {
        return -1;
    }

    // Compute the difference in date/time for the related activity of the asset allocations
    const diff = a1.activity.orderedArrivalDatetimeFrom.toMillis() - a2.activity.orderedArrivalDatetimeFrom.toMillis();

    if (diff !== 0) {
        return diff;
    }

    // If the dates/times are equal, use the activity's order in the dossier
    return a1.activity.ordering - a2.activity.ordering;
});

export class AssetAllocationStore extends Store<AssetAllocation> {
    Model = AssetAllocation;
    static backendResourceName = 'asset_allocation';

    comparator = AssetAllocationComperator

    add(rawModels: Partial<AssetAllocation> | Partial<AssetAllocation>[]): AssetAllocation | AssetAllocation[] {
        const result = super.add(rawModels);
        // [TODO] check if this sort is needed
        this.sort()
        return result;
    }

}
