import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';

export class GoodsDescription extends Model {
    static backendResourceName = 'goods_description';

    @observable id = null;
    @observable name = '';
    @observable usageCount = 0;
}

export class GoodsDescriptionStore extends Store {
    Model = GoodsDescription;
    static backendResourceName = 'goods_description';
}
