import { Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Message } from './Message';

export interface EmailAttachment {
    id: number | string;
    name: string;
    previewUrl: string;
    downloadUrl: string;
    optional: boolean;
    group?: string;
}

export interface IEntityEmailMixin {
    getAvailableAttachments(): Promise<EmailAttachment[]>;
}

export class EmailMessage extends Message {
    static backendResourceName = 'message_email';

    @observable emailType = null;
    @observable subject = '';
    @observable fromEmail = null;
    @observable cc = null;
    @observable bcc = null;
}

export class EmailMessageStore extends Store<EmailMessage> {
    Model = EmailMessage;
    static backendResourceName = 'message_email';
}
