import Load from './../../../container/Loadable';

const ActivityTemplateOverview = Load(() => import('../screen/Driver/ActivityTemplate'));

export const ActivityTemplateOverviewConfig = {
    path: '/driver-app/template/overview',
    component: ActivityTemplateOverview,
    componentProps: { items: ['here should be interface declaration that should be injected'] }
}

export const driverAppRoutesConfig = [
    ActivityTemplateOverviewConfig,
];