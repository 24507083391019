import { ViewEdit, CancelButton, RightDivider, SaveButton, TargetTextInput, t } from '@code-yellow/spider';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { GoodsDescription, GoodsDescriptionStore } from '../../store/GoodsDescription';

@observer
export class GoodsDescriptionEdit extends Component {
    static propTypes = {
        goods: PropTypes.instanceOf(GoodsDescription).isRequired,
        afterSave: PropTypes.func.isRequired,
    };

    goodsStore = new GoodsDescriptionStore({});

    save = () => {
        const { goods, afterSave } = this.props;

        return goods
            .save({ onlyChanges: true })
            .then(() => afterSave({ goBack: false }));
    };

    cancel = action(() => {
        const { goods } = this.props;
        return goods
            .clear();
    })


    _renderMainContent(goods) {
        return (
            <Form>
                <TargetTextInput
                    target={goods}
                    name="name"
                    label={t('masterData:goodsDescription.field.name.label')}
                />
            </Form>
        )
    }

    _renderToolbarContent(goods) {
        return (
            <>
                <RightDivider />
                <CancelButton compact
                    onClick={() => { if (window.confirm(t('masterData:form.clearConfirmation'))) this.cancel() }} content={t('masterData:form.cancel')}
                    loading={goods.isLoading}
                />
                <SaveButton primary compact
                    loading={goods.isLoading}
                    onClick={this.save}
                />
            </>
        )
    }

    _renderTitle(goods) {
        return goods.id
            ? t('masterData:goodsDescription.edit.title', { id: goods.id })
            : t('masterData:goodsDescription.create.title')
    }

    render() {
        const { goods } = this.props;

        return (
            <ViewEdit
                renderTitle={() => this._renderTitle(goods)}
                renderMainContent={() => this._renderMainContent(goods)}
                renderToolbarContent={() => this._renderToolbarContent(goods)}>
            </ViewEdit>
        );
    }
}
