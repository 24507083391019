import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Customer } from './Customer';
import { Location } from 'react-logistics-masterdata/src';

export class CustomerLocation extends Model {
    static backendResourceName = 'customer_location';

    @observable name = null;
    @observable id = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable customer = this.relation(Customer);
    @observable location = this.relation(Location);
}

export class CustomerLocationStore<M extends CustomerLocation = CustomerLocation> extends Store<M> {
    Model = CustomerLocation;
    static backendResourceName = 'customer_location';
}
