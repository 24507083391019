import { observable, action, computed } from 'mobx';
import { Model } from '@code-yellow/spider';
import { get, omit } from 'lodash';

export class Location extends Model {
    static backendResourceName = 'location';
    static omitFields = ['geoStatus', 'PreciseMatch', 'address'];

    // Hack so LocationEditForm reparses the given location.
    @observable _key = null;
    @observable id = null;
    @observable address = '';
    @observable street = '';
    @observable houseNumber = '';
    @observable zipCode = '';
    @observable city = '';
    @observable country = '';
    @observable geoStatus = '';
    @observable preciseMatch = true;
    @observable timezone = 'Europe/Amsterdam';
    @observable point: { lat: string, lng: string } | null = null;
    @observable code = '';


    toGPSCoordinates() {
        if (this.point == null || this.point.lat == null || this.point.lng == null) {
            return '';
        }
        return `${this.point.lat}°N, ${this.point.lng}°E`;
    }

    @computed get coordinates() {
        if (this.point == null || this.point.lat == null || this.point.lng == null) {
            return '';
        }
        return `${this.point.lat}, ${this.point.lng}`;
    }

    toString() {
        return `${this.country}, ${this.zipCode}, ${this.city}`;
    }

    @computed get localAddressString() {
        let localAddress = `${this.street}${+this.houseNumber !== 0 ? ' ' + this.houseNumber : ''}`
        if (!this.street) {
            localAddress = ''
        }
        return localAddress;
    }

    @computed get cityAddressString() {
        return `${this.zipCode ? this.zipCode + ' ' : ''}${this.city}${this.country ? ', ' + this.country : ''}`
    }

    @computed
    get toLogisticsString() {
        //When searching in PTV based on coordinates, if the location doesn't have house number assigned it will return empty string
        //If we search on address without house number specified, let's say "Bydgoszcz" it will return 0
        //With the logic below we omit asigning the title for the coordinates-based location which doesn't have house number(maybe not exist on the map yet)
        if (this.houseNumber === '') {
            return this.address;
        }

        const substrings = [this.localAddressString, this.cityAddressString].filter(x => !!x);
        return substrings.join(', ');
    }

    @computed
    get toLogisticsStringShort() {
        return `${this.country ? this.country : ''}${this.zipCode ? '-' + this.zipCode + ' ' : ' '}${this.city}`
    }

    @action
    fetchCountries() {
        return this.api.get('/location/country/').then(res => {
            return res.data;
        });
    }

    // {geocode-copy-pasta}
    @action
    geocode() {
        this.__pendingRequestCount += 1;
        const address = this.address;
        if (!address) {
            return Promise.resolve();
        }
        this.geoStatus = 'loading';
        return this.api
            .post(
                '/location/geocode/',
                { address: address }, //To optimize the search, we can use + 'Europe', but this will not throw an error if the location is not found.
                {
                    skipRequestError: true,
                }
            )
            .then(response => {
                if (response.data.address === 'Europe') {
                    this.geoStatus = 'notFound';
                } else {
                    this.fromBackend(response);
                    this.address = this.toLogisticsString;
                    this.geoStatus = 'success';
                    this.__pendingRequestCount -= 1;
                    return response
                }
            })
            .catch(err => {
                const errorCode = get(err, 'response.data.code');
                if (errorCode) {
                    this.geoStatus = errorCode;
                }
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }
}

export class LocationWithoutPoint extends Location {
    toBackend() {
        return omit(super.toBackend(), 'point');
    }
}
