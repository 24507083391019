import { observable, computed } from 'mobx';
import { Model, Store, Casts } from '@code-yellow/spider';
import { omit } from 'lodash';
import { TYPE_TEXT, SOURCE_INTERNAL } from './enums/ChatMessageEnums';
import { DateTime } from 'luxon';


export class ChatMessage extends Model {
    static backendResourceName = 'chat_message';

    @observable id: number | null = null;
    @observable type = TYPE_TEXT;
    @observable source = SOURCE_INTERNAL;
    @observable text = '';

    @observable writtenBy = null;
    @observable writtenAt = null;
    @observable receivedAt = null;
    @observable seenByReceiverAt: DateTime | null = null;

    @observable isDraft = false;

    @computed
    get displayedText() {
        let message = this.text;
        if (this.writtenBy) {
            const prefix = `${this.writtenBy}: `;

            if (message && !message.startsWith(prefix)) {
                message = prefix + message;
            }
        }
        return message;
    }

    casts() {
        return {
            writtenAt: Casts.datetime,
            receivedAt: Casts.datetime,
            seenByReceiverAt: Casts.datetime,
        };
    }

    relations() {
        return {};
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    markRead(updateField = true) {
        return Promise.resolve();
    }

    markUnread() {
        return Promise.resolve();
    }

    toBackend(...args) {
        const data = super.toBackend(...args);

        return omit(data);
    }
}

export class ChatMessageStore<T extends ChatMessage = ChatMessage> extends Store<T> {
    Model = ChatMessage;
    static backendResourceName = 'chat_message';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    markRead(chatId, types, unreadMessagesStore) {
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    markReadById(id) {
        return;
    }
}


type WebsocketUnreadMessageItem = { unread: '+1', chatId: number, type: string };

export class UnreadMessages<MessageType extends ChatMessage = ChatMessage> extends Model {
    @observable id: number | null = null;
    @observable text = 0;
    @observable form = 0;
    @observable route = 0;
    @observable activity = 0;
    @observable image = 0;
    @observable file = 0;

    @computed
    get count() {
        return this.text + this.form + this.activity + this.image + this.file;
    }

    /**
     * Mark message as read. Basically lowers unread count by 1.
     *
     * See T18134.
     */
    markRead(message: MessageType) {
        if (this[message.type] > 0) {
            this[message.type]--;
        }
    }
}

export class UnreadMessagesStore<MessageType extends ChatMessage = ChatMessage> extends Store<UnreadMessages<MessageType>> {
    Model = UnreadMessages<MessageType>;
    url = 'message/unread_internal/'

    /**
     * Item looks like:
     *
     * - { unread: "+1", chatId: 254, type: "text" }
     * - { unread: "+1", chatId: 297, type: "form" }
     * - { unread: "+1", chatId: 29, type: "activity" }
     *
     */
    updateFromSocket(item: WebsocketUnreadMessageItem) {
        const chatId = item.chatId;
        const type = item.type;
        const count = parseInt(item.unread);

        const unreadMessages = this.get(chatId)
            ? this.get(chatId)
            : this.add({ id: chatId });

        if(unreadMessages) {
            unreadMessages[type] += count;
        }
    }

    handlePublish(msg: { data: WebsocketUnreadMessageItem }) {
        this.updateFromSocket(msg.data);
    }
}
