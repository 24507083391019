import { RightDivider, ViewEdit } from '@code-yellow/spider';
import styled from 'styled-components';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable, reaction } from 'mobx';
import { omit } from 'lodash';
import {
    Row,
    Col,
    Subheading,
} from 're-cy-cle';
import { SaveButton, CancelButton } from '@code-yellow/spider';
import { Form, Tab } from 'semantic-ui-react';
import { TargetTextInput, TargetMoneyInput, t, TargetSelect } from '@code-yellow/spider';
import { Customer } from 'react-core-administration/src';
import { BaseRow } from 'component/Compact/BaseRow';
import { EntityStore } from 'react-core-administration/src/store/Entity';
import { CustomerGroupStore } from 'react-core-administration/src/store/CustomerGroup';
import { CustomerLocationStore } from 'react-logistics-administration/src/store/CustomerLocation';
import Toolbar from 'component/Toolbar';
import CustomerInvoiceLanguage from 'react-core-administration/src/store/enums/CustomerInvoiceLanguage';
import { LedgerStore } from 'react-core-finance/src/store/Ledger';

const SubheadingCustom = styled(Subheading)`
    &:nth-of-type(n + 2) {
        margin-top: 20px !important;
    }
    font-weight: 700;
    margin-bottom: 20px !important;
`;

const ColoredTargetMoneyInput = styled(TargetMoneyInput)`
    input {
        ${(props) => {
            if (props.value > props.limit) {
                return 'color: red !important;';
            } else {
                return 'color: black !important;';
            }
        }}
    }
`;

export const FlexibleTargetSelect = styled(TargetSelect)`
    > div {
        min-width: auto!important;
    }
`;

type CustomerEditProps = {
    customer: Customer,
    afterSave: () => null
}

@observer
export default class CustomerEdit extends Component<CustomerEditProps>{
    static saveRelations = ['entity', 'group'];
    @observable entityStore = new EntityStore()
    @observable customerGroupStore = new CustomerGroupStore({
        params: {
            'limit': '100',
        },
    });
    @observable customerLocations = new CustomerLocationStore({ relations: ['location'] });
    @observable ledgerStore = new LedgerStore();

    componentDidMount() {
        this.entityReaction = reaction(
            () => this.props.customer.entity.id,
            () => {
                this.ledgerStore.params['.entity'] = this.props.customer.entity.id;
                this.ledgerStore.fetch();
            },
            { fireImmediately: true }
        )
    }

    componentWillUnmount() {
        this.entityReaction?.();
    }

    save = ({ createNew = false, saveAndBack = false }) => {
        const { afterSave, customer } = this.props;
        return customer
            .save({
                onlyChanges: true,
                relations: this.saveRelations,
                mapData: data => omit(data, 'locations'), // Never save `locations` directly.
            })
            .then(() => {
                const afterSaveProps: {goTo?: string, goBack?: boolean} = { goBack: false };
                if (saveAndBack){
                    afterSaveProps.goTo = '/administration/customer/overview';
                    afterSaveProps.goBack = saveAndBack;
                }
                if(createNew){
                    afterSaveProps.goTo = '/administration/customer/add';
                    afterSaveProps.goBack = createNew;
                    customer.clear();
                }
                afterSave(afterSaveProps)
            })
    };

    cancel = action(() => {
        const { customer } = this.props;
        return customer.clear();
    });

    _renderTitle(customer) {
        return customer.id
            ? t('administration:customer.edit.title', { name: customer.name })
            : t('administration:customer.create.title');
    }

    _renderBasicInfoFields(customer) {
        // Not sure why but outside of the class the translations are not working, ¯\_(ツ)_/¯
        const CUSTOMER_INVOICE_LANGUAGE_OPTIONS = Object.values(CustomerInvoiceLanguage).map((language) => ({
            text: t(`administration:customer.field.invoiceLanguage.option.${language}`),
            value: language,
        }));

        return <>
            <SubheadingCustom>
                {t('administration:customer.edit.basicInfoTitle')}
            </SubheadingCustom>

            <TargetTextInput
                required
                target={customer}
                name='name'
                label={t('administration:customer.field.name.label')}
                value={customer.name}
            />
            <TargetSelect
                search
                remote
                searchKey=".name:icontains"
                target={customer}
                label={t('administration:customer.field.customerGroup.label')}
                name="group"
                store={this.customerGroupStore}
                toOption={(cg) => ({
                    value: cg.id,
                    text: cg.name,
                })}
            />
            <TargetTextInput
                target={customer}
                name='phone'
                label={t('administration:customer.field.phone.label')}
                placeholder='+31 6 12345678'
                onKeyPress={(event) => {
                    if (!['+', ' '].includes(event.key) && !/\d/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                // contentProps={{
                //     label: (
                //         <Dropdown
                //             defaultValue={countryOptions[0].value}
                //             options={countryOptions}
                //         />
                //     ),
                // }}
                error={customer.backendValidationErrors.phone}
                value={customer.phone}
            />
            <TargetTextInput
                target={customer}
                name='debtorNumber'
                label={t('administration:customer.field.debtorNumber.label')}
            />
            <TargetTextInput
                target={customer}
                label={t('administration:customer.field.iban.label')}
                error={customer.backendValidationErrors.iban}
                name='iban'
            />
            <TargetTextInput
                target={customer}
                label={t('administration:customer.field.chamberOfCommerce.label')}
                error={
                    customer.backendValidationErrors
                        .chamberOfCommerce
                }
                name='chamberOfCommerce'
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />
            <Form.Group>
                <TargetSelect search remote
                    searchKey=".name:icontains"
                    target={customer}
                    label={t('administration:customer.field.entity.label')}
                    name="entity"
                    store={this.entityStore}
                    afterChange={()=>customer.setInput('vatCode', null)}
                    toOption={entity => ({
                        value: entity.id,
                        text: entity.name,
                    })}
                />
                <TargetSelect
                    target={customer}
                    label={t('administration:customer.field.invoiceLanguage.label')}
                    name="invoiceLanguage"
                    options={CUSTOMER_INVOICE_LANGUAGE_OPTIONS}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <TargetTextInput
                    target={customer}
                    label={t('administration:customer.field.vatNumber.label')}
                    error={customer.backendValidationErrors.vatNumber}
                    name='vatNumber'
                />
                <FlexibleTargetSelect
                    disabled={customer.entity?.id == null}
                    target={customer}
                    name='defaultLedger'
                    label={t('administration:customer.field.vatCode.label')}
                    store={this.ledgerStore}
                    toOption={ledger => ({
                        value: ledger.id,
                        text: ledger.vatCode,
                    })}
                />
            </Form.Group>
            <BaseRow>
                <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.paymentTerm.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .paymentTerm
                        }
                        name='paymentTerm'
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Col>
                <Col xs={4}>
                    <TargetMoneyInput
                        fluid
                        toFixed
                        label={t('administration:customer.field.creditLimitAmount.label')}
                        target={customer}
                        name='creditLimitAmount'
                        placeholder='€0.000'
                    />
                </Col>
                <Col xs={4}>
                    <ColoredTargetMoneyInput
                        fluid
                        toFixed
                        disabled
                        label={t(
                            'administration:customer.field.creditLimitUsed.label'
                        )}
                        value={customer.creditLimitUsed}
                        limit={customer.creditLimitAmount}
                        placeholder='€0.000'
                    />
                </Col>
            </BaseRow>
        </>;
    }

    _renderVisitingAddressFields(customer) {
        return <>
            <SubheadingCustom>
                {t('administration:customer.edit.visitingLocationTitle')}
            </SubheadingCustom>
            <BaseRow>
                <Col xs={8}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.visitingAddress.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .visitingAddress
                        }
                        name='visitingAddress'
                        value={customer.visitingAddress}
                    />
                </Col>
                {/* <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.visitingNumber.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .visitingNumber
                        }
                        name='visitingNumber'
                        value={customer.visitingNumber}
                    />
                </Col> */}
                <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        name='visitingZipCode'
                        label={t('administration:customer.field.visitingZipCode.label')}
                        value={customer.visitingZipCode}
                    />
                </Col>
            </BaseRow>
            <BaseRow>
                <Col xs={6}>
                    <TargetTextInput
                        target={customer}
                        name='visitingCity'
                        label={t('administration:customer.field.visitingCity.label')}
                    />
                </Col>
                <Col xs={6}>
                    <TargetTextInput
                        fluid
                        target={customer}
                        name='visitingCountry'
                        label={t('administration:customer.field.visitingCountry.label')}
                    />
                </Col>
            </BaseRow>
        </>
    }

    _renderInvoicingAddressFields(customer) {
        return <>
            <SubheadingCustom>
                {t('administration:customer.edit.invoicingLocationTitle')}
            </SubheadingCustom>
            <BaseRow>
                <Col xs={8}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.invoicingAddress.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .invoicingAddress
                        }
                        name='invoicingAddress'
                        value={customer.invoicingAddress}
                    />
                </Col>
                {/* <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.invoicingNumber.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .invoicingNumber
                        }
                        name='invoicingNumber'
                        value={customer.invoicingNumber}
                    />
                </Col> */}
                <Col xs={4}>
                    <TargetTextInput
                        model={customer}
                        target={customer}
                        visitingCity
                        name='invoicingZipCode'
                        label={t('administration:customer.field.invoicingZipCode.label')}
                    />
                </Col>
            </BaseRow>
            <BaseRow>
                <Col xs={6}>
                    <TargetTextInput
                        model={customer}
                        target={customer}
                        name='invoicingCity'
                        label={t('administration:customer.field.invoicingCity.label')}
                    />
                </Col>
                <Col xs={6}>
                    <TargetTextInput
                        fluid
                        target={customer}
                        name='invoicingCountry'
                        label={t('administration:customer.field.invoicingCountry.label')}
                    />
                </Col>
            </BaseRow>
        </>;
    }

    _renderSidebarFields(customer) {
        return <>
            {this._renderBasicInfoFields(customer)}
            {this._renderVisitingAddressFields(customer)}
            {this._renderInvoicingAddressFields(customer)}
        </>;
    }

    _renderSidebarContent(customer) {

        return (
            <Form>
                <Row>
                    <Col xs={12}>
                        {this._renderSidebarFields(customer)}

                    </Col>
                </Row>
            </Form>
        );
    }

    _renderMainContent(customer) {
        return (
            <Tab
                cache='false'
                menu={{ secondary: true, pointing: true }}
                panes={this._getPanes(customer)}
                data-test-panes
            />
        );
    }

    _renderToolbarContent = (customer) => {
        return (
            <>
                <RightDivider />
                <CancelButton
                    compact
                    onClick={() => {
                        if (window.confirm(t('form.clearConfirmation'))) {
                            this.cancel();
                        }
                    }}
                    content={t('masterData:form.cancel')}
                    loading={customer.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ saveAndBack: true })}
                    content={t('masterData:form.saveAndBack')}
                    loading={customer.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ createNew: true })}
                    content={t('masterData:form.saveCreateEmpty')}
                    loading={customer.isLoading}
                />
                <SaveButton
                    primary
                    compact
                    data-test-save-same-customer-button
                    loading={customer.isLoading}
                    onClick={this.save}
                />
            </>
        );
    };

    render() {
        const { customer } = this.props;

        return (
            <>
                <ViewEdit
                    renderTitle={() => this._renderTitle(customer)}
                    renderSidebarContent={() =>
                        this._renderSidebarContent(customer)
                    }
                    renderMainContent={() => this._renderMainContent(customer)}
                    renderToolbarContent={() =>
                        this._renderToolbarContent(customer)
                    }
                    Toolbar={Toolbar}
                ></ViewEdit>
            </>
        );
    }
}
