import { afterSave } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GoodsDescription } from '../../store/GoodsDescription';
import { GoodsDescriptionEdit } from '../../container/GoodsDescription/Edit';


@observer
export default class GoodsDescriptionEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    goods = new GoodsDescription({
        id: this.props.match.params.id ? parseInt(this.props.match.params.id) : null,
    });

    componentDidMount() {
        if (this.goods.id) {
            this.goods.fetch();
        }
    }

    render() {
        return (
            <GoodsDescriptionEdit
                goods={this.goods}
                afterSave={afterSave(this.props.history, this.props.viewStore, this.goods)}
            />
        );
    }
}
