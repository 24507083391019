import { observable } from 'mobx';
import { Model } from '@code-yellow/spider';

export class DriverLocation extends Model {
    static backendResourceName = 'driver_app_location';

    @observable id = null;
    @observable address = '';
    @observable street = '';
    @observable houseNumber = '';
    @observable zipCode = '';
    @observable city = '';
    @observable point = {
        lng: null,
        lat: null,
    }
}