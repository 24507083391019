import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { User } from 'store/User';
import { ErrorLabel, showNotification, showSaveNotification, t, TargetTextInput } from '@code-yellow/spider';
import { Button, Form, Grid, GridColumn, GridRow, Header, Input, Popup } from 'semantic-ui-react';
import { observable } from 'mobx';
import styled from 'styled-components';

const ButtonCustom = styled(Button)`
    margin: 23px 0px 0px 0px!important;
`;

const Description = styled.span`
    color: var(--cy-gray-500);
    white-space: pre-line;
`;

const GridRowCustom = styled(GridRow)`
    padding: 5px 0px!important;
`;

const GridCustom = styled(Grid)`
    padding: 2rem 0px!important;
`;

const Content = styled.span`
    top: 16px;
    margin: 16px;
    position: relative;
`;

export type UserAuthenticationProps = {
    user: User,
    disabled?: boolean,
    saveUser: () => Promise<string>,
}

@observer
export class UserAuthentication extends Component<UserAuthenticationProps> {
    static defaultProps = {
        disabled: false,
    };

    @observable authUrl:string | null = null;
    @observable inputErrors = {};

    saveUserData = () => {
        const { saveUser } = this.props;

        this.inputErrors = {};

        saveUser?.().then((authUrl) => {
            this.authUrl = authUrl;
            showSaveNotification();
        })
        .catch(err => {
            if (err.response && err.response.data.errors) {
                this.inputErrors = err.response.data.errors;
            }
        });
    }

    copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);

        showNotification({
            message: t('modal.copiedSuccessfully')
        })
    }

    render() {
        const { user, disabled } = this.props;
        const hasData = user?.email && user?.password;

        const content = (
            <Content>
                <Header as='h5'>
                    {t('driverApp:driver.authentication.title')}
                </Header>
                <Description>{t('driverApp:driver.authentication.description')}</Description>
                <GridCustom verticalAlign='top' data-test-wrapper-user-field>
                    <GridRowCustom stretched>
                        <GridColumn width={6}>
                            <Form>
                                <TargetTextInput
                                    fluid
                                    target={user}
                                    name="email"
                                    autocomplete="off"
                                    disabled={disabled}
                                    action={{ content: t('driverApp:driver.authentication.copy'), disabled: disabled, onClick: () => this.copyToClipboard(user.email) }}
                                />
                                {this.inputErrors['email'] && (
                                    <ErrorLabel>
                                        {this.inputErrors['email'].map((error, i) => <div key={i}>{error}</div>)}
                                    </ErrorLabel>
                                )}
                            </Form>
                        </GridColumn>
                        <GridColumn width={6}>
                            <Form>
                                <TargetTextInput
                                    fluid
                                    target={user}
                                    name="password"
                                    label={t('driverApp:driver.authentication.password')}
                                    autocomplete="off"
                                    disabled={disabled}
                                    action={{ content: t('driverApp:driver.authentication.copy'), disabled: disabled, onClick: () => this.copyToClipboard(user.password) }}
                                />
                                {this.inputErrors['password'] && (
                                    <ErrorLabel>
                                        {this.inputErrors['password'].map((error, i) => <div key={i}>{error}</div>)}
                                    </ErrorLabel>
                                )}
                            </Form>
                        </GridColumn>
                        <GridColumn width={4}>
                            <ButtonCustom
                                data-test-save-user-data
                                icon='refresh'
                                content={t('driverApp:driver.authentication.generate')}
                                onClick={this.saveUserData}
                                disabled={disabled || !hasData}
                            />
                        </GridColumn>
                    </GridRowCustom>
                    <GridRowCustom columns={1}>
                        <GridColumn stretched>
                            <Input
                                disabled={disabled || user?.id !== null}
                                action={{ content: t('driverApp:driver.authentication.copy'), disabled: disabled, onClick: () => this.copyToClipboard(this.authUrl) }}
                                defaultValue={this.authUrl}
                            />
                        </GridColumn>
                    </GridRowCustom>
                </GridCustom>
            </Content>
        );

        return disabled ? (
            <Popup content={t('driverApp:driver.authentication.hint')} trigger={content} inverted />
        ) : (
            content
        );
    }
}