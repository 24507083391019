import { observable } from 'mobx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, IconVisibilityOff } from 're-cy-cle';
import { ChatMessage } from '../../../store/ChatMessage';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { format } from 'helpers'

export const TimeContainer = styled.div`
    width: 60px;
    text-align: center;
    font-size: 10px;
`;

const MarkUnread = styled.div`
    ${props => props.onClick ? `
        cursor: pointer;
    ` : ''}
    user-select: none;
`;

const Fragment = React.Fragment;

@observer
export default class MessageTime extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(ChatMessage).isRequired,
        markUnread: PropTypes.func,
    };

    @observable showIcon = false;

    handleMarkUnread = () => {
        const { markUnread } = this.props;

        markUnread(this.props.model);
        this.hideMarkUnreadIcon();
        this.props.model.markUnread();
    };

    maybeShowMarkUnreadIcon = () => {
        const canMarkUnread = this.canMarkUnread();

        if (canMarkUnread) {
            this.showIcon = true;
        }
    };

    hideMarkUnreadIcon = () => {
        this.showIcon = false;
    };

    canMarkUnread = () => {
        const { markUnread } = this.props;

        return !!markUnread;
    };

    render() {
        const { model } = this.props;
        const canMarkUnread = this.canMarkUnread();
        let content;

        if (!model.id || model.isLoading) {
            content = <Loader show />;
        } else {
            content = (
                <MarkUnread
                    onClick={canMarkUnread ? this.handleMarkUnread : undefined}
                >
                    {this.showIcon && <IconVisibilityOff />}
                    {!this.showIcon && (
                        <Fragment>
                            {format(model.expectedSendDatetime, 'D/M')}
                            <br key="a" />
                            {` ${format(model.expectedSendDatetime, 'HH:mm')}`}
                        </Fragment>
                    )}
                </MarkUnread>
            );
        }
        return (
            <TimeContainer
                onMouseEnter={this.maybeShowMarkUnreadIcon}
                onMouseLeave={this.hideMarkUnreadIcon}
            >
                {content}
            </TimeContainer>
        );
    }
}
