import { observable } from 'mobx';
import { Model, Store, Casts } from '@code-yellow/spider';
import { User } from '../../../store/User';

export class ChatMessageRecipient extends Model {
    static backendResourceName = 'chat_message_recipient';

    @observable id = null;
    @observable receivedAt = null;
    @observable seenAt = null;
    @observable user = this.relation(User);

    casts() {
        return {
            receivedAt: Casts.datetime,
            seenAt: Casts.datetime,
        };
    }
}

export class ChatMessageRecipientStore extends Store<ChatMessageRecipient> {
    Model = ChatMessageRecipient;
    static backendResourceName = 'chat_message_recipient';
}
