export const COLOR_INITIALIZATION_PENDING = '#FB8C00';
export const COLOR_INITIALIZATION_FAILED = '#E53935';
export const COLOR_DELIVERY_PENDING = '#FB8C00';
export const COLOR_DELIVERY_SUCCESS = '#43A047';
export const COLOR_DELIVERY_FAILED = '#E53935';

export const STATUS_COLORS_LIST = [
    COLOR_INITIALIZATION_PENDING,
    COLOR_INITIALIZATION_FAILED,
    COLOR_DELIVERY_PENDING,
    COLOR_DELIVERY_SUCCESS,
    COLOR_DELIVERY_FAILED
];

export const STATUS_COLORS = {
    initialization_pending: COLOR_INITIALIZATION_PENDING,
    initialization_failed: COLOR_INITIALIZATION_FAILED,
    delivery_pending: COLOR_DELIVERY_PENDING,
    delivery_success: COLOR_DELIVERY_SUCCESS,
    delivery_failed: COLOR_DELIVERY_FAILED
};

export const STATUS_PRIO = {
    initialization_pending: 1,
    initialization_failed: 4,
    delivery_pending: 2,
    delivery_failed: 3,
    delivery_success: 0,
};
