import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

// import enMasterData from 'react-logistics-masterdata/src/i18n/translation/en';
// import enAdministration from 'react-logistics-administration/src/i18n/translation/en';

export const initTranslations = () => {

    i18next.addResourceBundle('en', 'translation', en, true);
    i18next.addResourceBundle('nl', 'translation', nl, true);

    // // [TODO] move to administration module
    // i18next.addResourceBundle('en', 'masterdata', enMasterData, true);
    // i18next.addResourceBundle('en', 'administration', enAdministration, true);
}