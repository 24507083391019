import React from 'react';
import { t, TargetSelect } from '@code-yellow/spider';
import AdminOverview from 'component/AdminOverview';
import { observer } from 'mobx-react';
import { CustomerStore } from 'react-core-administration/src';
import { observable } from 'mobx';
import AssignToCustomerGroupModal from 'react-core-administration/src/component/AssignToCustomerGroupModal';
import AssignToEntityModal from 'react-core-administration/src/component/AssignToEntityModal';
import { EntityStore } from 'react-core-administration/src/store/Entity';
import { debounce } from 'lodash';
@observer
export default class CustomerOverviewScreen extends AdminOverview {
    params = {
        order_by: 'name',
    };
    store = new CustomerStore({
        'relations': []
    });

    allowExport = true;
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;
    title = t('administration:customer.overview.title');
    myFilterKey = 'customer-overview';
    entityStore = new EntityStore();

    // Variables for bulk actions
    @observable assignToCustomerGroupModalOpen = false;
    @observable assignToEntityModalOpen = false;

    multiSelection = true;
    bulkActions = [
        {
            key: 'assign-to-customer-group',
            text: t('administration:customer.overview.bulkActions.assignToCustomerGroup'),
            'data-test-bulk-action-assign-to-customer-group': true,
            action: () => this.assignToCustomerGroupBulkAction(),
        },
        {
            key: 'assign-to-entity',
            text: t('administration:customer.overview.bulkActions.assignToEntity'),
            'data-test-bulk-action-assign-to-entity': true,
            action: () => this.assignToEntityBulkAction(),
        },
    ]

    componentDidMount() {
        super.componentDidMount();
        this.entityStore.fetch();
    }

    async assignToCustomerGroupBulkAction() {
        // Open model and await the Promise (user closes the model or clicks "Assign")
        this.assignToCustomerGroupModalOpen = true;

        const assignToCustomerGroupPromise = new Promise((resolve, reject) => {
            this.assignToCustomerGroupResolve = resolve;
            this.assignToCustomerGroupReject = reject;
        })

        try {
            await assignToCustomerGroupPromise;
        } catch (e) {
            // User cancelled the modal, don't perform the bulk action
            return;
        }
    }

    async assignToEntityBulkAction() {
        // Open model and await the Promise (user closes the model or clicks "Assign")
        this.assignToEntityModalOpen = true;

        const assignToEntityPromise = new Promise((resolve, reject) => {
            this.assignToEntityResolve = resolve;
            this.assignToEntityReject = reject;
        })

        try {
            await assignToEntityPromise;
        } catch (e) {
            // User cancelled the modal, don't perform the bulk action
            return;
        }
    }

    renderContent() {
        const closeAssignToCustomerGroupModal = () => {
            this.assignToCustomerGroupModalOpen = false;
            this.assignToCustomerGroupReject?.();
        }

        const closeAssignToEntityModal = () => {
            this.assignToEntityModalOpen = false;
            this.assignToEntityReject?.();
        }

        return (
            <>
                <AssignToCustomerGroupModal
                    isOpen={this.assignToCustomerGroupModalOpen}
                    onClose={closeAssignToCustomerGroupModal}
                    afterSave={() => this.assignToCustomerGroupResolve?.()}
                    selection={this.getSelection()}
                />

                <AssignToEntityModal
                    isOpen={this.assignToEntityModalOpen}
                    onClose={closeAssignToEntityModal}
                    afterSave={() => this.assignToEntityResolve?.()}
                    selection={this.getSelection()}
                />

                {super.renderContent()}
            </>
        );
    }

    rowProps(item, i) {
        return { 'data-test-customer': item.id };
    }

    settings = [
        { attr: 'debtorNumber', label: t('administration:customer.field.debtorNumber.label'), sortKey: 'debtor_number' },
        { attr: 'name', label: t('administration:customer.field.name.label') },
        { attr: 'group', label: t('administration:customer.field.group.label') },
        { attr: 'visitingCity', label: t('administration:customer.field.visitingCity.label') },
        { attr: 'visitingCountry', label: t('administration:customer.field.visitingCountry.label') },
        { attr: 'phone', label: t('administration:customer.field.phone.label') },
        { collapsing: true },
    ];

    buttons = () => {
        let buttons = ['']

        buttons.push(
            { type: 'edit', to: (u) => `/administration/customer/${u.id}/edit` },
            { type: 'delete' },
        );

        return buttons;
    };

    toolbar = [
        { type: 'add', to: '/administration/customer/add', label: t('administration:customer.overview.addButton') },
    ];

    filters = [
        {
            type: 'text',
            label: t('administration:customer.field.name.label'),
            name: '.name:icontains',
        },
        {
            type: 'text',
            label: t('administration:customer.field.debtorNumber.label'),
            name: '.debtor_number:startswith',
            onKeyPress: (event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            },
        },
        {
            type: 'custom',
            callback: () => (
                <TargetSelect
                    multiple
                    remote clearable
                    store={this.entityStore}
                    name=".entity.id:in"
                    target={this.store}
                    label={t('administration:customer.field.entity.label')}
                    placeholder={t('administration:customer.field.entity.label')}
                    afterChange={debounce(() => this.store.fetch(), 250)}
                    options={this.entityStore.map(item => ({
                        value: item.id,
                        text: <span>{item.name}</span>,
                    }))}
                />
            )
        },
        {
            type: 'text',
            label: t('administration:customer.field.visitingCity.label'),
            name: '.visiting_city:icontains',
        },
        {
            type: 'text',
            label: t('administration:customer.field.visitingCountry.label'),
            name: '.visiting_country:icontains',
        },
        {
            type: 'text',
            label: t('administration:customer.field.groupNumber.label'),
            name: '.group.group_number',
        },
        {
            type: 'text',
            label: t('administration:customer.field.groupName.label'),
            name: '.group.name:icontains',
        },
    ];
}
