export enum ActivityStatus {
    NEW = 'new',
    PLANNED = 'planned',
    EXECUTING = 'executing',
    FINISHED = 'finished',
    FINALIZED = 'finalized',
    CANCELED = 'canceled'
}

export enum ActivityOperationsStatus {
    PLANNED = 'planned',
    EXECUTING = 'executing',
    FINISHED = 'finished'
}

export enum ActivitySubstatus {
    DRIVING = 'driving',
    WAITING = 'waiting',
    WORKING = 'working'
}
