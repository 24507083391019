import { observable } from 'mobx';
import { InvoiceLineStore as InvoiceLineStoreBase, InvoiceLine as InvoiceLineBase } from 'react-core-finance/src';
import { Trip } from 'react-logistics-administration/src';
import { SelfBillItem } from 'react-logistics-finance/src/store/SelfBillItem';

export class InvoiceLine extends InvoiceLineBase {
    static backendResourceName = 'logistics_invoice_line';

    @observable linkedTrip = this.relation(Trip);
    @observable linkedSelfBillItem = this.relation(SelfBillItem);
}

export class InvoiceLineStore extends InvoiceLineStoreBase<InvoiceLine> {
    Model = InvoiceLine;
    static backendResourceName = 'logistics_invoice_line';
}
