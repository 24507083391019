import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { format, DATETIME_FORMAT } from 'helpers'
import { IconSeen, IconReceived, IconSent } from '../Icon';
import { ChatMessage } from 'react-core-communication/src/index';
import ReceivedSeenPopup from './ReceivedSeenPopup';
import { t } from '@code-yellow/spider';

export const SeenStatus = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StatusIcon = styled(Icon)`
    opacity: 50% !important;
`;

type MessageSeenStatusProps = {
    model: ChatMessage,
};

@observer
export default class MessageSeenStatus extends Component<MessageSeenStatusProps> {

    SeenStatus = SeenStatus;

    renderStatusPending() {
        return (
            <this.SeenStatus data-test-message-status="pending">
                <StatusIcon name="paper plane" data-test-icon-pending />
            </this.SeenStatus>
        );
    }

    renderStatusSent(model) {
        return (
            <this.SeenStatus data-test-message-status="written">
                <Popup
                    trigger={<IconSent data-test-icon-sent />}
                    position="bottom right"
                    content={`${t('communication:chatmessage.field.writtenAt.label')}: ${format(model.writtenAt, DATETIME_FORMAT)}`}
                />
            </this.SeenStatus>
        );
    }

    renderStatusReceived(model) {
        return (
            <this.SeenStatus data-test-message-status="received">
                <ReceivedSeenPopup
                    trigger={<IconReceived data-test-icon-received />}
                    message={model}
                    label={t('communication:chatmessage.field.receivedAt.label')}
                    messageField="receivedAt"
                    messageRecipientField="receivedAt"
                />
            </this.SeenStatus>
        );
    }

    renderStatusSeen(model) {
        return (
            <this.SeenStatus data-test-message-status="seen">
                <ReceivedSeenPopup
                    trigger={<IconSeen data-test-icon-seen />}
                    message={model}
                    label={t('communication:chatmessage.field.seenAt.label')}
                    messageField="seenByReceiverAt"
                    messageRecipientField="seenAt"
                />
            </this.SeenStatus>
        );
    }

    render() {
        const { model } = this.props;

        if (model.isNew) {
            return this.renderStatusPending();
        }

        if (!model.receivedAt) {
            return this.renderStatusSent(model);
        }

        if (!!model.receivedAt && !model.seenByReceiverAt) {
            return this.renderStatusReceived(model);
        }

        if (model.seenByReceiverAt) {
            return this.renderStatusSeen(model);
        }
    }
}
