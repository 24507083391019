export default {
    nav: {
        driverApp: {
            label: 'Driver App',
            driverActivityTemplate: 'Driver Activiteit Template',
        }
    },
    driver: {
        authentication: {
            title: 'Driver app login',
            description: 'De chauffeur kan de gegenereerde inloglink gebruiken om in te loggen in de Driver-app.\n Als alternatief kan deze ook inloggen middels het ingestelde e-mailadres en wachtwoord.\n Opmerking: om veiligheidsredenen word de login en link maar eenmalig weergegeven.\n Indien een nieuw wachtwoord/link nodig is, dient het email en wachtwoord opnieuw ingevult te worden.',
            link: 'Driver app link',
            copy: 'Kopiëren',
            password: 'Nieuw wachtwoord instellen',
            generate: 'Genereer login link',
            hint: 'Om de driverapp login in te stellen moet de driver eerst opgeslagen worden',
        },
        activities: {
            dossier: 'Dossier',
            trip: 'Trip',
            date: 'Date',
            driver: 'Chauffeur',
            activity: 'Activiteit',
            location: 'Locatie',
            history: 'Geschiedenis',
            status: 'Status',
            actions: 'Acties',
        },
        reports: {
            title: 'Activiteitenrapporten',
            actions: {
                history: 'Wijzigingen bekijken',
            },
            attachFile: 'Bijvoegen bij dossier',
        }
    },
    driverActivityTemplate: {
        overview: {
            title: 'Driver Activiteit Template',
        },
        headers: {
            type: 'Type',
            label: 'Label',
            details: 'Aanvullende instructies',
            optional: 'Verplicht',
        },
        title: '{{type}} Template',
        add: 'Toevoegen'
    },
    driverActionTemplate: {
        types: {
            text: 'Tekstveld',
            numeric: 'Numerieke invoer',
            image: 'Foto upload',
            check: 'Checkbox',
        }
    },
    driverAppActivity: {
        field: {
            status: {
                label: 'status',
            }
        }
    },
};