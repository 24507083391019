import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ChatMessage } from '../../../store/ChatMessage';
import InnerRow from '../Message/InnerRow';
import MessageTime from '../Message/Time';
import { OtherTextBubble, Text, StyledSender } from '../Message/Bubble';

@observer
export default class MessageTypeTextExternal extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(ChatMessage).isRequired,
        markUnread: PropTypes.func.isRequired,
    };

    render() {
        const { model, markUnread } = this.props;

        return (
            <InnerRow isFromExternal={true} data-test-chat-box-message-from-external data-test-message={model.id}>
                <OtherTextBubble unread={!model.seenByReceiverAt}>
                    <StyledSender data-test-message-sender>{model.writtenBy}</StyledSender>
                    <Text text={model.text} />
                </OtherTextBubble>
                <MessageTime model={model} markUnread={markUnread} />
            </InnerRow>
        );
    }
}
