import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';

export class EmailTemplate extends Model {
    static backendResourceName = 'email_template';

    @observable id: number | null = null;
    @observable tag = '';
    @observable name = '';
    @observable design = {};
    @observable subject = '';
    @observable senderEmail = '';
    @observable content = '';
    @observable userMessage = '';

    @observable _initialFetchDone = false;

}

export class EmailTemplateStore extends Store<EmailTemplate> {
    Model = EmailTemplate;
    static backendResourceName = 'email_template';
}
