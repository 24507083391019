import React from 'react'
import { observer } from 'mobx-react'
import AdminOverview from 'component/AdminOverview'
import { MessageStore } from '../store/Message'
import { STATUS_COLORS, STATUS_PRIO } from '../store/enums/StatusEnums'
import { User } from 'store/User'
import { format, DATETIME_FORMAT } from 'helpers'
import { Button, Popup, Table } from 'semantic-ui-react'
import { t } from '@code-yellow/spider';
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'

const StatusLabel = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  background-color: ${props => props.color};
  text-transform: uppercase;
  white-space: nowrap;
`

const EllipsisSpan = styled.span`
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 400px;
`

@observer
export class MessageLog extends AdminOverview {
  store = new MessageStore({
    relations: ['receivers']
  })

  title = t('communication:communication.messagelog.title')
  emptyMessage = t('communication:communication.messagelog.empty')
  myFilterKey = 'communication-messagelog'

  params = {
    'view': 'combined',
  }

  settings = [
    {
      label: t('communication:message.field.type.label'),
      attr: ({ type }) => t(`communication:message.field.type.value.${type}`),
      sortKey: 'type',
    },
    {
      label: t('communication:message.field.status.label'),
      attr: (message) => {
        if (message.index !== undefined) {
          // Message object only has index in individual view, so we don't need to calculate the top status
          return <StatusLabel data-test-message-status color={STATUS_COLORS[message.status[message.index]]}>{t(`communication:message.field.status.value.${message.status[message.index]}`)}</StatusLabel>
        }

        let topStatus = null
        // eslint-disable-next-line no-unused-vars
        for (const status of message.status) {
          if (topStatus === null || STATUS_PRIO[status] > STATUS_PRIO[topStatus]) {
            topStatus = status
          }
        }
        return <StatusLabel data-test-message-status color={STATUS_COLORS[topStatus]}>{t(`communication:message.field.status.value.${topStatus}`)}</StatusLabel>
      },
      sortKey: 'status',
    },
    {
      label: t('communication:message.field.receivers.label'),
      attr: (message) => (
        <>
          {message.receivers.map((receiver: User, i: number) => (
            <Popup
              trigger={<p>{receiver.fullName}</p>}
              content={message.sentTo[i]}
            />
          ))}
        </>
      ),
    },
    {
      label: t('communication:message.field.dateSent.label'),
      attr: ({ dateSent }) => format(dateSent, DATETIME_FORMAT),
    },
    {
      label: t('communication:message.field.text.label'),
      attr: (message) => (
        <EllipsisSpan>
          {message.text}
        </EllipsisSpan>
      ),

    }
  ]

  filters = [
    {
      type: 'radioButtons',
      label: t('communication:message.overview.filter.view.label'),
      name: 'view',
      options: [
        { text: t('communication:message.overview.filter.view.value.individual'), value: 'individual', 'data-test-view-type-switch-button': 'individual' },
        { text: t('communication:message.overview.filter.view.value.combined'), value: 'combined', 'data-test-view-type-switch-button': 'combined' },
      ],
      afterChange: (value) => this.params['view'] = value,
    },
    {
      type: 'multiButtons',
      vertical: true,
      label: t('communication:message.field.type.label'),
      name: '.type:in',
      options: [
        { text: t('communication:message.field.type.value.email'), value: 'email' },
        { text: t('communication:message.field.type.value.sms'), value: 'sms' },
        { text: t('communication:message.field.type.value.telegram'), value: 'telegram' },
      ],
    },
    {
        type: 'select',
        clearable: true,
        label: t('communication:message.field.status.label'),
        name: '.status:contains',
        options: ['initialization_pending', 'initialization_failed', 'delivery_pending', 'delivery_success', 'delivery_failed'].map((status) => ({
            text: (<StatusLabel color={STATUS_COLORS[status]}>{t(`communication:message.field.status.value.${status}`)}</StatusLabel>),
            value: status
        })),
    },
    {
      type: 'dateRange',
      label: t('communication:message.field.dateSent.label'),
      name: '.date_sent:range',
    },
    {
      type: 'custom',
      callback: () => <Button fluid data-test-clear-button content={t('communication:message.overview.filter.clearButton.label')} onClick={this.handleClear} />
    }
  ]

  rowProps(item, i: number) {
    return { 'data-test-message-row': item.id };
  }

  tableProps() {
    return { ...super.tableProps(), singleline: true }
  }

  renderRow(item, i: number) {
    return this.params['view'] === 'individual' ? this.renderIndividualRow(item, i) : this.renderCombinedRow(item, i);
  }

  renderIndividualRow(item, i: number) {
    const TableRow = this.TableRow;

    const rows = item.receivers.map((receiverRow: User, index) => {
      const modifiedItem = cloneDeep(item)
      modifiedItem.receivers = [receiverRow]
      modifiedItem.index = index

      return <TableRow key={(modifiedItem.cid, receiverRow.id)} deleted={modifiedItem.deleted} {...this.rowProps(item, i)}>
          {this.mappedSettings.map((setting, i) => this.renderCell.bind(this)(
              modifiedItem, setting, i,
          ))}
          {this.finalButtons.length > 0 && (
              <Table.Cell collapsing singleLine textAlign="right">
                  {this.finalButtons.map((button, i) => this.renderButton.bind(this)(
                      modifiedItem, button, i + this.mappedSettings.length,
                  ))}
              </Table.Cell>
          )}
        </TableRow>
    })

    return rows
  }

  renderCombinedRow(item, i: number) {
    const TableRow = this.TableRow;

    return (
      <TableRow key={item.cid} deleted={item.deleted} {...this.rowProps(item, i)}>
        {this.mappedSettings.map((setting, i) => this.renderCell.bind(this)(
            item, setting, i,
        ))}
        {this.finalButtons.length > 0 && (
            <Table.Cell collapsing singleLine textAlign="right">
                {this.finalButtons.map((button, i) => this.renderButton.bind(this)(
                    item, button, i + this.mappedSettings.length,
                ))}
            </Table.Cell>
        )}
      </TableRow>
    )
  }

  resetCurrentPage = () => {
    this.store.__state.currentPage = 1;
  }

  handleClear = () => {
      this.store.params = this.getDefaultParams();
      this.resetCurrentPage();
      this.store.fetch();
  }
}
