import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
    position: absolute;
    right: 0;
    top: 0;
    border: none !important;
    outline: none !important;
    width: 100%;
    height: 100%;
`;

@observer
export class PdfViewer extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
    };

    renderPdfPreview() {
        const { url, ...rest } = this.props;

        return (
            <StyledIframe
                {...rest}
                src={url}
                onDragEnter={e => e.preventDefault()}
                onDragCapture={e => e.preventDefault()}
                onDrag={e => e.preventDefault()}
            />
        )
    }

    render() {
        return this.renderPdfPreview()
    }
}
