// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

i18next.addResourceBundle('nl', 'administration', nl, true);
i18next.addResourceBundle('en', 'administration', en, true);
i18next.fallbackNS = ['administration']

// Containers
export { default as CustomerEdit } from './container/Customer/Edit'

// Screen
export { default as CustomerEditScreen } from './screen/Customer/Edit'
export { default as CustomerOverviewScreen } from './screen/Customer/Overview'

// Components
export { ColorBox } from './component/ColorBox'

// Store
export * from './store/Customer';
export * from './store/CustomerGroup';
export * from './store/enums/CustomerGroupEnums';

export { CustomerLink } from './component/Links/CustomerLink';

// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as AdministrationSettings from './settings';
