import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { TruckStore } from '../store/Truck';
import { TrailerStore } from '../store/Trailer';

export class TruckingCompany extends Model {
    static backendResourceName = 'trucking_company';

    @observable id = null;
    @observable name = '';
    @observable emailAddress = '';
    @observable phone = '';

    @observable specialization = '';
    @observable iban = '';
    @observable swiftBic = '';

    @observable paymentTerms = 60;
    @observable chamberOfCommerce = '';
    @observable vatCode = '';

    relations() {
        return {
            trucks: TruckStore,
            trailers: TrailerStore,
        };
    }

    casts() {
        return {
        };
    }
}

export class TruckingCompanyStore extends Store {
    Model = TruckingCompany;
    static backendResourceName = 'trucking_company';
}
