// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';
import de from './i18n/translation/de';

i18next.addResourceBundle('nl', 'administration', nl, true);
i18next.addResourceBundle('en', 'administration', en, true);
i18next.addResourceBundle('de', 'administration', de, true);
i18next.fallbackNS = ['administration']

// Containers

// Screen

// Store
export * from './store/Trip';
export * from './store/Activity';
export * from './store/Customer';
export * from './store/CustomerLocation';
export * from './store/Contact';
export { default as ActivityType } from './store/enums/ActivityType';

export { CustomerLink } from 'react-core-administration/src';

// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as AdministrationSettings from './settings';




