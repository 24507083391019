import { Model, Store, Casts, showNotification, showErrorNotification, t } from '@code-yellow/spider';
import { computed, observable, action } from 'mobx';
import { Customer } from 'react-core-administration/src/store/Customer';
import { Customer as LogisticsCustomer } from './Customer';
import { User } from 'store/User';
import { TripStore } from './Trip';
import DossierStatus from './enums/DossierStatus';
import { formatMoney } from 'helpers';
import TripStatus from './enums/TripStatus';
import { EmailAttachment } from 'react-core-communication/src/store/EmailMessage';
import { SelfBill } from 'react-logistics-finance/src/store/SelfBill';
import { sortBy } from 'lodash';
import { TrailerStore, TruckStore } from 'react-logistics-masterdata/src';
import { InvoiceStore } from 'react-logistics-finance/src/store/Invoice';

export class Dossier extends Model {
    static backendResourceName = 'dossier';
    static omitFields = ['tripsCount', 'firstActivity', 'invoiceable', 'selfBilled', 'selfBill', 'datetimeFrom', 'datetimeUntil', 'trucks', 'trailers'];

    @observable __fetched = false;

    @observable id = null;
    @observable dossierNumber = '';
    @observable weekNumber = '';
    @observable remarks = '';
    @observable invoiceReference = '';
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable tripsCount = 0;
    @observable status = DossierStatus.NEW;
    @observable invoiceable = false;
    @observable selfBilled = false;
    @observable datetimeFrom = null;
    @observable datetimeUntil = null;

    @observable customer = this.relation(Customer);
    @observable logisticsCustomer = this.relation(LogisticsCustomer);
    @observable createdBy = this.relation(User);
    @observable trips = this.relation(TripStore);
    @observable selfBill = this.relation(SelfBill);
    @observable trucks = this.relation(TruckStore);
    @observable trailers = this.relation(TrailerStore);
    @observable invoices = this.relation(InvoiceStore);

    @computed get documentId() {
        return this.dossierNumber;
    }

    @computed get invoiceableTrips() {
        return this.trips.models.filter(trip => trip.status === TripStatus.COMPLETED && !trip.invoiced).sort((a, b) => a.id - b.id);
    }

    @computed get hasUnsavedChanges() {
        // We exclude these, because its filled in automatically and should not trigger unsaved changes prompt
        const excludedFields = ['dossierNumber', 'weekNumber'];

        if (this.__changes.filter(x => !excludedFields.includes(x)).length > 0) {
            return true;
        }
        return this.__activeCurrentRelations.some(rel => {
            return this[rel].hasUserChanges;
        });
    }

    @computed
    get displayName() {
        let displayName = '';
        if (this.id == null) {
            return '-';
        }
        if (this.customer?.id != null) {
            displayName = this.customer.name + ' ';
        }
        return displayName + `(D${this.dossierNumber})`
    }

    @computed
    get canBeCanceled() {
        return this.trips.models.every((trip) => trip.canBeCanceled && trip.activities.models.every(activity => activity.canBeCanceled));
    }

    @computed
    get isCanceled() {
        return this.status === 'canceled';
    }

    @computed
    get firstActivity() {
        return this.firstTrip?.firstActivity;
    }

    @computed get firstTrip() {
        return sortBy(this.trips.models, 'firstActivity.orderedArrivalDatetimeFrom').at(0);
    }

    @computed
    get fullDossierPrice() {
        return formatMoney(this.trips.models.map(trip=>trip.salesPrice).reduce((a, b) => a + b, 0));
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            datetimeFrom: Casts.luxonDatetime,
            datetimeUntil: Casts.luxonDatetime,
        };
    }

    async getNextNumber() {
        const res = await this.api.get('/dossier/get_next_number/');
        return res['next_number'];
    }

    @computed get pdfPreviewUrl() {
        return `/api${this.url}pdf/`;
    }

    @computed get pdfDownloadUrl() {
        return `${this.pdfPreviewUrl}?download=true`;
    }

    @action
    cancel(reason) {
        if(this.id === null) {
            return Promise.resolve();
        }

        return this.api.post(`/dossier/${this.id}/cancel_dossier/`, {
                reason: reason
            }).then(() => showNotification({
                message: t('administration:dossier.modal.cancel.success')
            }))
            .catch((err) => {
                showErrorNotification(err.response.data.errors);
            });
    }

    @action
    uncancel() {
        if(this.id === null) {
            return Promise.resolve();
        }

        return this.api.post(`/dossier/${this.id}/uncancel_dossier/`)
            .then(() => showNotification({
                message: t('administration:dossier.modal.uncancel.success')
            }))
            .catch((err) => {
                showErrorNotification(err.response.data.errors);
            });
    }

    fetch(options?: object) {
        return super.fetch(options).then((data) => {
            this.__fetched = true;
            return data;
        });
    }

    async getAvailableAttachments() {
        const res = await this.api.get<EmailAttachment[]>(`${this.url}get_available_attachments/`);
        return res.data;
    }
}

export class DossierStore extends Store<Dossier> {
    Model = Dossier;
    static backendResourceName = 'dossier';
}
