import { round, uniqBy } from 'lodash';
import { computed, observable } from 'mobx';
import { EmailAttachment, IEntityEmailMixin } from 'react-core-communication/src/store/EmailMessage';
import { InvoiceLine as LogisticsInvoiceLine, InvoiceLineStore as LogisticsInvoiceLineStore } from 'react-logistics-finance/src/store/InvoiceLine';
import { Invoice as InvoiceBase, InvoiceStore as InvoiceStoreBase } from 'react-core-finance/src/store/Invoice';
import { Customer as LogisticsCustomer } from 'react-logistics-administration/src';
import { DossierStore } from 'react-logistics-administration/src/store/Dossier';
import { SelfBillStore } from 'react-logistics-finance/src/store/SelfBill';

export class Invoice extends InvoiceBase<LogisticsInvoiceLine> implements IEntityEmailMixin {
    static backendResourceName = 'logistics_invoice';
    static omitFields = [...InvoiceBase.omitFields, 'totalAmount', 'subtotalAmount', 'dossiers', 'logisticsLines', 'invoicedTrips', 'invoicedDossiers', 'invoicedSelfBillItems', 'logisticsCustomer', 'selfBills'];

    @observable logisticsCustomer = this.relation(LogisticsCustomer);
    @observable logisticsLines = this.relation(LogisticsInvoiceLineStore);
    @observable dossiers = this.relation(DossierStore);
    @observable selfBills = this.relation(SelfBillStore);

    // These come from a reaction and are used in the overview
    @observable totalAmount = 0;
    @observable subtotalAmount = 0;

    // These are used to calculate the total amount for the edit view
    @computed get totalAmountComputed() {
        return round(this.lineStore.models.reduce((total, line) => total + line.totalAmount, 0), 0);
    }

    @computed get subtotalAmountComputed() {
        return round(this.lineStore.models.reduce((total, line) => total + (line.amount ?? 0), 0), 0);
    }

    // Little helper to setup the view easier
    @observable __fetched = false;
    fetch(options?: object) {
        return super.fetch(options).then((data)=>{
            this.__fetched = true;
            return data;
        })
    }

    @computed get pdfPreviewUrl() {
        return `/api${this.url}pdf/`;
    }

    @computed get pdfDownloadUrl() {
        return `${this.pdfPreviewUrl}?download=true`;
    }

    async getAvailableAttachments() {
        const res = await this.api.get<EmailAttachment[]>(`${this.url}get_available_attachments/`);
        return res.data;
    }

    @computed get dossierDocumentsVirtualPathRegex() {
        return `^/dossier/(?:${this.dossiers.map(d=>d.dossierNumber).join('|') || -1})/$`;
    }

    @computed get invoicedSelfBillItems() {
        return this.logisticsLines.filter(line => !line.linkedSelfBillItem?.isNew).map(line => line.linkedSelfBillItem);
    }

    @computed get invoicedTrips() {
        return this.logisticsLines.filter(line => !line.linkedTrip?.isNew).map(line => line.linkedTrip);
    }

    @computed get invoicedDossiers() {
        return uniqBy(this.invoicedTrips.map(trip => trip.dossier), 'id');
    }

    @computed get lineStore() {
        return this.logisticsLines;
    }
}

export class InvoiceStore extends InvoiceStoreBase<Invoice> {
    Model = Invoice;
    static backendResourceName = 'logistics_invoice';
}
