// DEPRECATED, use DRIVER_CHANGE insted
export const DRIVER_CHECK_IN = 'driver_check_in';
export const DRIVER_CHECK_OUT = 'driver_check_out';

export const DRIVER_CHANGE = 'driver_change';

export const TRUCK_PICK_UP = 'truck_pick_up';
export const TRUCK_DROP = 'truck_drop';
export const TRUCK_SOLO = 'truck_solo';

export const TRAILER_PICK_UP = 'trailer_pick_up';
export const TRAILER_DROP = 'trailer_drop';

export const LOAD = 'load';
export const UNLOAD = 'unload';

export const CUSTOM_ACTIVITY = 'custom_activity';
export const CUSTOMER_ACTIVITY = 'customer_activity';

export const CUSTOMER_GROUP_ALLOCATION = 'customer_group_allocation';

export const ACTIVITY_TYPES = [
    LOAD, UNLOAD,
    TRAILER_PICK_UP, TRAILER_DROP,
    TRUCK_PICK_UP, TRUCK_DROP, TRUCK_SOLO,
    CUSTOM_ACTIVITY, CUSTOMER_ACTIVITY,
    // DEPRECATED DRIVER_CHECK_IN, DRIVER_CHECK_OUT,
    DRIVER_CHANGE,
    CUSTOMER_GROUP_ALLOCATION
];

export default {
    LOAD, UNLOAD,
    TRAILER_PICK_UP, TRAILER_DROP,
    TRUCK_PICK_UP, TRUCK_DROP, TRUCK_SOLO,
    CUSTOM_ACTIVITY, CUSTOMER_ACTIVITY,
    // DEPRECATED DRIVER_CHECK_IN, DRIVER_CHECK_OUT,
    DRIVER_CHANGE,
    CUSTOMER_GROUP_ALLOCATION
}

