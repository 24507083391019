import styled from 'styled-components';
import { getColor } from '../store/enums/CustomerGroupEnums';

export const ColorBox = styled.div`
    background-color: ${(props) => getColor(props.color)};
    width: 40px;
    height: 16px;
    margin: 0 12px;
    padding: 0;
    border-radius: 4px;
`;
