import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { afterSave } from '@code-yellow/spider';
import { observable } from 'mobx';
import { TruckingCompany } from '../../store/TruckingCompany';
import { TruckingCompanyEdit } from '../../container/TruckingCompany/Edit';

@observer
export default class TruckingCompanyEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    @observable truckingCompany = new TruckingCompany({
        id: this.props.match.params.id ? parseInt(this.props.match.params.id) : null,
    }, {
        relations: ['trucks', 'trailers'
        ],
    });

    componentDidMount() {
        if (this.truckingCompany.id) {
            this.truckingCompany.fetch();
        }
    }

    render() {
        return (
            <TruckingCompanyEdit
                truckingCompany={this.truckingCompany}
                afterSave={afterSave(this.props.history, this.props.viewStore, this.truckingCompany)}
            />
        );
    }
}
