import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { afterSave } from '@code-yellow/spider';
import { Driver } from '../../store/Driver';
import { DriverEdit } from '../../container/Driver/Edit';


@observer
export default class DriverEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    @observable driver = new Driver({
        id: this.props.match.params.id ? parseInt(this.props.match.params.id) : null,
    }, {
        relations: [
        ],
    });

    componentDidMount() {
        if (this.driver.id) {
            this.driver.fetch();
        }
    }

    render() {
        return (
            <DriverEdit
                driver={this.driver}
                afterSave={afterSave(this.props.history, this.props.viewStore, this.driver)}
            />
        );
    }
}
