import { ViewEdit, Link } from '@code-yellow/spider';
import { CancelButton, SaveButton } from '@code-yellow/spider';
import { ItemButton, RightDivider, TargetNumberInput, TargetTextInput, t } from '@code-yellow/spider';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Tab, Table } from 'semantic-ui-react';
import { Location } from '../../store/Location';
import { TruckingCompany } from '../../store/TruckingCompany';
import Toolbar from 'component/Toolbar';

type TruckingCompanyEditProps = {
    truckingCompany: TruckingCompany,
    afterSave:() => null,
}

@observer
export class TruckingCompanyEdit extends Component<TruckingCompanyEditProps>{
    static propTypes = {
        truckingCompany: PropTypes.instanceOf(TruckingCompany).isRequired,
        afterSave: PropTypes.func.isRequired,
    };

    location = new Location();

    save = ({ createNew = false, saveAndBack = false }) => {
        const { truckingCompany, afterSave } = this.props;

        return truckingCompany
            .save({ onlyChanges: true, relations: ['trucks', 'trailers'] })
            .then(() => {
                let afterSaveProps = {
                    goBack: false
                };
                if (saveAndBack){
                    afterSaveProps = {
                        goTo: '/master-data/trucking-company/overview',
                        goBack: saveAndBack
                    }
                }
                if(createNew){
                    afterSaveProps = {
                        goTo: '/master-data/trucking-company/add',
                        goBack: createNew
                    }
                    truckingCompany.clear();
                }
                afterSave(afterSaveProps)
            })
            .catch(() => { return });
    };

    cancel = action(() => {
        const { truckingCompany } = this.props;
        return truckingCompany
            .clear();
    })

    @action
    _deleteTruck = (truck) => {
        const { truckingCompany } = this.props;
        truckingCompany.trucks.remove(truck);

        // [TODO] this is needed because of the tab component colliding with mobx observer
        // maybe there is a better way
        this.forceUpdate();
    }

    @action
    _deleteTrailer = (trailer) => {
        const { truckingCompany } = this.props;
        truckingCompany.trailers.remove(trailer);

        // [TODO] this is needed because of the tab component colliding with mobx observer
        // maybe there is a better way
        this.forceUpdate();
    }

    @action
    _deleteContract = (contract) => {
        const { truckingCompany } = this.props;
        truckingCompany.contracts.remove(contract);

        // [TODO] this is needed because of the tab component colliding with mobx observer
        // maybe there is a better way
        this.forceUpdate();
    }

    _renderTrucks(truckingCompany) {
        return (
            <>
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('masterData:truck.field.id.label')}</Table.HeaderCell>
                            <Table.HeaderCell singleLine>{t('masterData:truck.field.fleetNumber.label')}</Table.HeaderCell>
                            <Table.HeaderCell singleLine>{t('masterData:truck.field.licensePlate.label')}</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    {truckingCompany.trucks.map(truck =>
                        <Table.Body>
                            <Table.Row data-test-truck-row={truck.id}>
                                <Table.Cell>{truck.id}</Table.Cell>
                                <Table.Cell>{truck.fleetNumber}</Table.Cell>
                                <Table.Cell>{truck.licensePlate}</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <ItemButton as={Link} icon='edit' label={t('masterData:tooltips.edit')} to={`/master-data/truck/${truck.id}/edit`}></ItemButton>
                                    <ItemButton icon='delete' label={t('masterData:tooltips.delete')} onClick={() => this._deleteTruck(truck)}></ItemButton>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    )}
                </Table>
                <Button as={Link} primary
                    content={t('masterData:truckingCompany.trucks.add')}
                    icon='plus'
                    labelPosition='left'
                    to={`/master-data/truck/add/trucking-company/${truckingCompany.id}`}
                    disabled={truckingCompany.id === undefined}
                />
            </>
        )
    }

    _renderTrailers(truckingCompany) {
        return (
            <>
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('masterData:trailer.field.id.label')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('masterData:trailer.field.fleetNumber.label')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('masterData:trailer.field.licensePlate.label')}</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {truckingCompany.trailers.map(trailer =>
                        <Table.Body>
                            <Table.Row data-test-trailer-row={trailer.id}>
                                <Table.Cell>{trailer.id}</Table.Cell>
                                <Table.Cell>{trailer.fleetNumber}</Table.Cell>
                                <Table.Cell>{trailer.licensePlate}</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <ItemButton as={Link} icon='edit' label={t('masterData:tooltips.edit')} to={`/master-data/trailer/${trailer.id}/edit`}></ItemButton>
                                    <ItemButton icon='delete' label={t('masterData:tooltips.delete')} onClick={() => this._deleteTrailer(trailer)}></ItemButton>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    )}
                </Table>
                <Button as={Link} primary
                    content={t('masterData:truckingCompany.trailer.add')}
                    icon='plus'
                    labelPosition='left'
                    to={`/master-data/trailer/add/trucking-company/${truckingCompany.id}`}
                    disabled={truckingCompany.id === undefined}
                />
            </>
        )
    }

    _getPanes(truckingCompany) {
        return [
            {
                menuItem: t('masterData:truckingCompany.tab.trucks'),
                render: () => this._renderTrucks(truckingCompany),
            },
            {
                menuItem: t('masterData:truckingCompany.tab.trailers'),
                render: () => this._renderTrailers(truckingCompany),
            },
        ]
    }

    _renderMainContent(truckingCompany) {
        return (
            <Tab cache='false' menu={{ secondary: true, pointing: true }} panes={this._getPanes(truckingCompany)} />
        )
    }

    _renderSidebarContent(truckingCompany) {
        return (
            <Form>
                <TargetTextInput
                    required
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.name.label')}
                    name="name"
                />
                <TargetTextInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.specialization.label')}
                    name="specialization"
                />
                <TargetTextInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.phone.label')}
                    name="phone"
                />

                <TargetTextInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.iban.label')}
                    name="iban"
                />
                <TargetTextInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.swiftBic.label')}
                    name="swiftBic"
                />
                <TargetTextInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.chamberOfCommerce.label')}
                    name="chamberOfCommerce"
                />
                <TargetTextInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.vatCode.label')}
                    name="vatCode"
                />
                <TargetNumberInput
                    target={truckingCompany}
                    label={t('masterData:truckingCompany.field.paymentTerms.label')}
                    name="paymentTerms"
                    autoComplete="off"
                />
            </Form>
        )
    }

    _renderToolbarContent(truckingCompany) {
        return (
            <>
                <RightDivider />
                <CancelButton compact
                    onClick={() => { if (window.confirm(t('masterData:form.clearConfirmation'))) this.cancel() }} content={t('masterData:form.cancel')}
                    loading={truckingCompany.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ saveAndBack: true })}
                    content={t('masterData:form.saveAndBack')}
                    loading={truckingCompany.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ createNew: true })}
                    content={t('masterData:form.saveCreateEmpty')}
                    loading={truckingCompany.isLoading}
                />
                <SaveButton primary compact
                    loading={truckingCompany.isLoading}
                    onClick={this.save}
                    data-test-save-same-trucking-company-button
                />
            </>
        )
    }

    _renderTitle(truckingCompany) {
        return truckingCompany.id
            ? t('masterData:truckingCompany.edit.title', { name: truckingCompany.name })
            : t('masterData:truckingCompany.create.title')

    }

    render() {
        const { truckingCompany } = this.props;

        return (
            <ViewEdit
                renderTitle={() => this._renderTitle(truckingCompany)}
                renderSidebarContent={() => this._renderSidebarContent(truckingCompany)}
                renderMainContent={() => this._renderMainContent(truckingCompany)}
                renderToolbarContent={() => this._renderToolbarContent(truckingCompany)}
                Toolbar={Toolbar}>
            </ViewEdit>
        );
    }

}
