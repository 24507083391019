import { Casts, Model, Store } from '@code-yellow/spider';
import { computed, observable } from 'mobx';
import { DateTime, Duration } from 'luxon';
import { Driver } from './Driver';
import { round } from 'lodash';

export class Route extends Model {
    static backendResourceName = 'route';

    @observable id = null;

    @observable createdAt: DateTime | null = null;
    @observable updatedAt: DateTime | null = null;

    @observable validFrom: DateTime | null = null;
    @observable validTo: DateTime | null = null;

    @observable driver = this.relation(Driver);


    @computed get hasUpcomingValidFromIn14Days() {
        const now = DateTime.now()
        return this.validFrom && this.validFrom >= now && this.validFrom <= now.plus({ day: 14 }).startOf('day');
    }

    @computed get hasUpcomingValidToIn14Days() {
        const now = DateTime.now()
        return this.validTo && this.validTo >= now && this.validTo <= now.plus({ day: 14 }).startOf('day');
    }

    daysLeftForUpcomingEvent = (): number => {
        const currentDate = DateTime.now();
        let result: Duration | null = null;

        if (this.hasUpcomingValidFromIn14Days || this.hasUpcomingValidToIn14Days) {
            const targetDate: DateTime | null = this.hasUpcomingValidFromIn14Days
                ? this.validFrom
                : this.validTo;

            result = targetDate?.startOf('day').diff(currentDate.startOf('day')) ?? null;
        }

        return result ? round(result.as('days'), 0) : -1;
    }

    casts() {
        return {
            validFrom:  Casts.datetime,
            validTo: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime
        };
    }
}

export class RouteStore extends Store {
    Model = Route;
    static backendResourceName = 'route';
}
