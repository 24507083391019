export const PACKAGING_TYPE_BIG_BAGS = 'big bags';
export const PACKAGING_TYPE_BALES = 'bales';
export const PACKAGING_TYPE_BILLETS = 'billets';
export const PACKAGING_TYPE_BUNDLES = 'bundles';
export const PACKAGING_TYPE_COLLI = 'colli';
export const PACKAGING_TYPE_COILS = 'coils';
export const PACKAGING_TYPE_CARTONS = 'cartons';
export const PACKAGING_TYPE_DRUMS = 'drums';
export const PACKAGING_TYPE_EUROPALLETS = 'europallets';
export const PACKAGING_TYPE_PALLETS = 'pallets';
export const PACKAGING_TYPE_REELS = 'reels';
export const PACKAGING_TYPE_SLABS = 'slabs';
export const PACKAGING_TYPE_LOTS = 'lots';
export const PACKAGING_TYPE_LDM = 'ldm';
export const PACKAGING_TYPE_FTL = 'ftl';
export const PACKAGING_TYPES = [PACKAGING_TYPE_FTL, PACKAGING_TYPE_BIG_BAGS, PACKAGING_TYPE_BALES, PACKAGING_TYPE_BILLETS, PACKAGING_TYPE_BUNDLES, PACKAGING_TYPE_COLLI, PACKAGING_TYPE_COILS, PACKAGING_TYPE_CARTONS, PACKAGING_TYPE_DRUMS, PACKAGING_TYPE_EUROPALLETS, PACKAGING_TYPE_PALLETS, PACKAGING_TYPE_REELS, PACKAGING_TYPE_SLABS, PACKAGING_TYPE_LOTS, PACKAGING_TYPE_LDM];
