import _, { omit, result, throttle } from 'lodash';
import { action } from 'mobx';
import { Store } from '@code-yellow/spider';

const UPPER_ELEMENTS_LIMIT = 1000;
const THROTTLE_LOAD = 250;
const VIRTUAL_PAGE_SIZE_LOAD = 25;

export class VirtualizedStore extends Store {

    vIdsToFetchStack = [];
    loadedPagesIndices = [];

    getPageIndexForVid = (vId) => {
        return Math.floor(vId / VIRTUAL_PAGE_SIZE_LOAD)
    }



    @action
    fromBackend({ data, repos, relMapping, reverseRelMapping }) {
        this.vIdsToFetchStack = [];
        this.loadedPagesIndices = [];

        const fetchedData = data.map(record => {
            const model = this._newModel();
            model.fromBackend({
                data: record,
                repos,
                relMapping,
                reverseRelMapping,
            });
            return model;
        });

        this.models.replace(
            Array.from({ length: this.getVirtualLength() }).map((_v, index) => {
                return this.getVirtualData(index, fetchedData);
             })
        );
    }

    getByVId(vId) {
        return this.models.find(
            model => model.vId == vId // eslint-disable-line eqeqeq
        );
    }

    async loadVirtualModel(vId, options = {}) {
        this.vIdsToFetchStack.push(vId);

        await this._fetchVirtualModel(options);
    }

    _updateModel = async (pageStart, offset, res) => {
        const model = this._newModel();
        model.fromBackend({
            data: res.data[offset],
            repos: res.repos,
            relMapping: res.relMapping,
            reverseRelMapping: res.reverseRelMapping,
        });

        // Check for out of bounds
        if (this.models.length > pageStart + offset) {
            this.models[pageStart + offset] = model;
        }
    }

    _updateModelById = async (id, data, res) => {
        const model = this._newModel();
        model.fromBackend({
            data: data,
            repos: res.repos,
            relMapping: res.relMapping,
            reverseRelMapping: res.reverseRelMapping,
        });

        const index = this.models.findIndex(m => m.id === id)
        if (index >= 0) {
            this.models[index] = model;
        }
    }


    _fetchVirtualModel = throttle(async (options = {}) => {
        const vId = this.vIdsToFetchStack.pop();
        const delay = ms => new Promise(res => setTimeout(res, ms));

        if (vId == null) {
            return;
        }

        const pageIndex = this.getPageIndexForVid(vId);

        if (this.loadedPagesIndices.find(idx => idx === pageIndex)) {
            return;
        }
        this.loadedPagesIndices.push(pageIndex);

        const data = this.buildFetchData(options);
        data.limit = VIRTUAL_PAGE_SIZE_LOAD;
        data.offset = this.getPageIndexForVid(vId) * VIRTUAL_PAGE_SIZE_LOAD;
        _.remove(this.vIdsToFetchStack, (id) => data.offset <= id && id < data.offset + data.limit)

        const promise = this.__getApi()
            .fetchStore({
                url: options.url || result(this, 'url'),
                data,
                requestOptions: omit(options, 'data'),
            })
            .then(action(async (res) => {
                for (let offset = 0; offset < res.data.length; offset++) {
                    await this._updateModel(data.offset, offset, res);

                    // This trick provides smooth user experience
                    await delay(1);
                }

                return res.response;
            }))

        return promise;
    }, THROTTLE_LOAD)

    getVirtualLength() {
        return Math.min(this.__state.totalRecords, UPPER_ELEMENTS_LIMIT);
    }

    refreshModel(dataOptions) {
        const options = {}
        let data = this.buildFetchData(options);

        data = { ...data, ...dataOptions }
        const promise = this.__getApi()
            .fetchStore({
                url: options.url || result(this, 'url'),
                data,
                requestOptions: omit(options, 'data'),
            })
            .then(action(async (res) => {
                for (let offset = 0; offset < res.data.length; offset++) {
                    await this._updateModelById(res.data[offset].id, res.data[offset], res);

                    // This trick provides smooth user experience
                    // await delay(1);
                }

                return res.response;
            }))
        return promise;
    }

    getVirtualData(index, fetchedData) {
        let data = null;

        if (fetchedData.length > index) {
            data = fetchedData[index];
        }

        if (!data) {
            data = this._newModel();
            data.vId = index;
        }
        return data
    }
}