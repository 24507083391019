import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { IInvoiceLineLinkedObject } from 'react-core-finance/src';
import { Customer } from 'react-logistics-administration/src';
import { SelfBill } from 'react-logistics-finance/src/store/SelfBill';

export class SelfBillItem extends Model implements IInvoiceLineLinkedObject {
    static backendResourceName = 'self_bill_item';
    static omitFields = ['invoiced'];

    @observable id = null;

    @observable costCentreNumber = '';
    @observable type = '';
    @observable amount = 0;

    @observable selfBill = this.relation(SelfBill);
    @observable distributionItem = this.relation(Customer);

    @observable invoiced = false;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getDescription(language?: string): string {
        return `Cost centre number: ${this.costCentreNumber} | Type: ${this.type}`;
    }

    getAmount(): number {
        return this.amount;
    }
}

export class SelfBillItemStore extends Store<SelfBillItem> {
    Model = SelfBillItem;
    static backendResourceName = 'self_bill_item';
}
