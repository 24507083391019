import { Model, Store, Casts } from '@code-yellow/spider';
import { action, computed, observable } from 'mobx';
import { Contract } from './Contract';
import { Ledger } from './Ledger';
import { InvoiceLine, InvoiceLineStore } from './InvoiceLine';
import InvoiceStatus from './enums/InvoiceStatus';

import { groupBy, round, uniq } from 'lodash';
import { Customer } from 'react-core-administration/src';
import { Entity } from 'react-core-administration/src/store/Entity';
import InvoicelineType from 'react-core-finance/src/store/enums/InvoiceLineType';
import InvoiceLanguage from 'react-core-finance/src/store/enums/InvoiceLanguage';
import { DateTime } from 'luxon';
import { VatCodeDe, VatCodeEn, VatCodeNl } from 'react-core-administration/src/store/enums/VatCode';
import { getVatPercentage } from 'react-core-administration/src/helpers';

export class Invoice<L extends InvoiceLine = InvoiceLine> extends Model {
    static backendResourceName = 'invoice';
    static omitFields = ['totalAmount'];

    @observable id: number | null = null;

    @observable number = '';
    @observable issueDate: DateTime | null = null;
    @observable dueDate = null;
    @observable reference = '';
    @observable status: InvoiceStatus =  InvoiceStatus.DRAFTED;
    @observable sentAt = null;
    @observable remarks = '';
    @observable notes = '';
    @observable language = InvoiceLanguage.ENGLISH;
    @observable vatCode: VatCodeDe | VatCodeEn | VatCodeNl | null = null;

    @observable contract = this.relation(Contract);
    @observable lines = this.relation(InvoiceLineStore<L>);
    @observable customer = this.relation(Customer);
    @observable entity = this.relation(Entity);
    @observable ledger = this.relation(Ledger);

    // To be able to override it in subclasses
    @computed get lineStore(): Store<L> {
        return this.lines;
    }

    // To be able to override it in subclasses
    @computed get lineStore(): Store<L> {
        return this.lines;
    }

    casts() {
        return {
            issueDate: Casts.luxonDate,
            dueDate: Casts.luxonDate,
            sentAt: Casts.luxonDatetime,
        }
    }

    @computed get totalAmount(): number {
        return round(this.lineStore.models.reduce((total, line) => total + line.totalAmount, 0), 0);
    }

    addCustomLine(lineData): L {
        return this.addLine({
            ...lineData,
            type: InvoicelineType.CUSTOM,
        });
    }

    addLine(lineData): L {
        const ordering = this.getLinesByGroup(lineData.group ?? 0).length;
        return this.lineStore.add({ ordering, vat: getVatPercentage(this.entity.invoiceTemplate, this.vatCode), ...lineData });
    }

    removeLine(line) {
        this.lineStore.remove(line);
        this.fixOrderingWithinGroup(line.group);
    }

    // removeLineGroup(group: number) {
    //     this.getLinesByGroup(group).forEach(line => this.lineStore.remove(line));
    //     this.fixGroupsOrdering();
    // }

    getGroupTotalAmount(group: number): number {
        return round(this.getGroupAmount(group) + this.getGroupVat(group), 0);
    }

    getGroupAmount(group: number): number {
        return round(this.getLinesByGroup(group).reduce((total, line) => total + (line.amount ?? 0), 0), 0);
    }

    getGroupVat(group: number): number {
        return round(this.getLinesByGroup(group).reduce((total, line) => total + line.vatAmount, 0), 0);
    }

    getLinesByGroup(group: number) {
        return this.lineStore.models.filter(line => line.group === group).sort((a, b) => a.ordering - b.ordering);
    }

    @action
    moveLineUpWithinGroup(lineToMove) {
        if (lineToMove.ordering === 0) {
            return;
        }
        const linesInGroup = this.getLinesByGroup(lineToMove.group);

        const lineAbove = linesInGroup[lineToMove.ordering - 1];

        lineAbove.ordering++;
        lineToMove.ordering--;
    }

    @action
    moveLineDownWithinGroup(lineToMove) {
        const linesInGroup = this.getLinesByGroup(lineToMove.group);
        const maxOrdering = linesInGroup.length - 1

        if (lineToMove.ordering >= maxOrdering) {
            return;
        }

        const lineBelow = linesInGroup[lineToMove.ordering + 1];

        lineBelow.ordering--;
        lineToMove.ordering++;
    }

    @action
    moveToGroupAbove(lineToMove) {
        const linesInGroup = this.getLinesByGroup(lineToMove.group);
        if (lineToMove.group === 0) {
            if(linesInGroup.length === 1) {
                return;
            }

            lineToMove.ordering = 0;
            this.lineStore.filter(line => line !== lineToMove).forEach(line => {
                line.group = line.group ?? 0;
                line.group++;
            });
        }
        else {
            const linesInAboveGroup = this.getLinesByGroup(lineToMove.group - 1);
            lineToMove.group--;
            lineToMove.ordering = linesInAboveGroup.length;
        }

        this.fixOrderingWithinGroup(lineToMove.group + 1);
    }

    @action
    moveToGroupBelow(lineToMove) {
        const linesInGroup = this.getLinesByGroup(lineToMove.group);
        const linesInBelowGroup = this.getLinesByGroup(lineToMove.group + 1);

        if (linesInBelowGroup.length) {
            lineToMove.ordering = linesInBelowGroup.length;
            lineToMove.group++;
        }
        else if(linesInGroup.length > 1) {
            lineToMove.ordering = 0;
            lineToMove.group++;
        }
        else {
            lineToMove.ordering = 0;
        }

        this.fixOrderingWithinGroup(lineToMove.group - 1);
    }

    @action
    fixOrderingWithinGroup(group: number) {
        const linesInGroup = this.getLinesByGroup(group);
        linesInGroup.forEach((line, index) => line.ordering = index);
    }

    @action
    fixGroupsOrdering() {
        const groups = groupBy(this.lineStore.models, 'group');
        Object.keys(groups).map(Number).sort((a, b) => a - b).forEach((group, index) => {
            this.getLinesByGroup(group).forEach(line => line.setInput('group', index));
        });
    }

    getLineGroups(): number[] {
        return uniq(this.lineStore.map(line=>line.group)).sort((a, b) => a - b);
    }
}

export class InvoiceStore<M extends Invoice = Invoice> extends Store<M> {
    Model = Invoice;
    static backendResourceName = 'invoice';
}
