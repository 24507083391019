import React from 'react';
import AdminOverview, { TableHeader } from 'component/AdminOverview';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { Driver } from 'react-logistics-masterdata/src/store/Driver';
import { RouteStore } from 'react-logistics-masterdata/src/store/Route';
import { RouteEditModal } from '../Route/EditModal';
import { ItemButton } from '@code-yellow/spider';
import { formatLuxonDate } from 'react-logistics-planning/helpers';

const TripHeader = styled(TableHeader)`
    padding-top: 0!important;
    padding-bottom: 0!important;
    color: rgba(0, 0, 0, 0.4)!important;
    background-color: transparent!important;
`

const StyledTableCell = styled(Table.Cell)`
    padding-top: 3px!important;
    padding-bottom: 3px!important;
`

const StyledTable = styled(Table)`
    border: none!important;
`

export default class DriverExpandRow extends AdminOverview {
    static propTypes = {
        ...AdminOverview.propTypes,
        driver: PropTypes.instanceOf(Driver).isRequired,
    }
    modal = true; // To remove unnecessary components
    bindUrlParams = false;
    defaultShowSidebar = false;
    highlightedRows = false;
    defaultSidebars() {
        return [];
    }
    fullWidth = true;
    selectedRenderType = this.RENDER_TYPE_COMPACT;
    TableHeader = TripHeader;
    TableCell = StyledTableCell;
    Table = StyledTable;

    @observable store = new RouteStore({
        relations: ['driver'],
        params: {
            '.driver.id': this.props.driver?.id,
            limit: 'none',
            order_by: 'valid_from',
        }
    });

    settings = [
        { label: '', attr: () => '', collapsing: true, props: { style: { borderRight: '1px solid #00469E' } }, cellProps: { style: { borderRight: '1px solid #00469E', paddingLeft: '58px' } } },
        { attr: (route)=> formatLuxonDate(route.validFrom), label: t('masterData:route.field.validFrom.label'), collapsing: true, sortKey: 'valid_from' },
        { attr: (route)=> formatLuxonDate(route.validTo), label: t('masterData:route.field.validTo.label'), sortKey: 'valid_to', },
        { collapsing: true },
    ]

    buttons = [
        (route) => (
            <RouteEditModal
                model={route}
                trigger={
                    <ItemButton data-test-route-edit-button
                        icon='edit'
                        label={t('masterData:route.overview.editButton')}
                    />
                }
                afterSave={() => {
                    const { driver } = this.props;
                    this.store.fetch()
                    driver.fetch()
                }}
            />
        ),
        { type: 'delete' }
    ]

    tableProps = () => ({
        ...super.tableProps(),
        'data-test-expanded-driver': this.props.driver.id
    });

    rowProps = (route) => ({
        ...super.rowProps(route),
        'data-test-route': route.id
    });

    renderBody() {
        return this.renderOverviewTable();
    }
}
