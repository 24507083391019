export enum VatCodeEn {
    ICP = 'ICP',
    VH = 'VH',
}

export enum VatCodeNl {
    ICP = 'ICP',
    ICV = 'ICV',
    IH = 'IH',
    IHMWST = 'IHMWST',
    IL = 'IL',
    IN = 'IN',
    VH = 'VH',
    VL = 'VL',
    VN = 'VN',
}

export enum VatCodeDe {
    IH = 'IH',
    IHMWST = 'IHMWST',
    IHMWST16 = 'IHMWST16',
    IL = 'IL',
    IN = 'IN',
    VH = 'VH',
    VHMWST = 'VHMWST',
    VHMWST16 = 'VHMWST16',
    VHMWST19 = 'VHMWST19',
    VL = 'VL',
    VN = 'VN',
}
