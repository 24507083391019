// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

i18next.addResourceBundle('nl', 'communication', nl, true);
i18next.addResourceBundle('en', 'communication', en, true);
i18next.fallbackNS = ['communication']

// Components
export * from './component/Chat/Icon';
export * from './component/Chat/Message/Bubble';
export { default as ChatBox, DateStamp } from './component/Chat/Box';
export { default as InnerRow } from './component/Chat/Message/InnerRow';
export { default as ReceivedSeenPopup } from './component/Chat/Message/ReceivedSeenPopup';
export { default as MessageSeenStatus, SeenStatus } from './component/Chat/Message/SeenStatus';
export { default as MessageTime } from './component/Chat/Message/Time';
export { default as MessageInput } from './component/Chat/MessageInput';
export { default as MessageOverviewItem } from './component/Chat/MessageOverviewItem';
export { default as MessageTypeTextExternal } from './component/Chat/MessageTypes/TextExternal';
export { default as MessageTypeTextInternal } from './component/Chat/MessageTypes/TextInternal';

// Modals
export * from './modals/EntityEmailModal'

// Containers
export { default as MessageOverviewContainer } from './container/Chat/MessageOverview';
export { default as ChatBoxContainer, mergeStores } from './container/Chat/Box';

// Screen

// Store
export * from './store/ChatMessage'
export * from './store/ChatMessageRecipient'
export * from './store/Message'
export * from './store/enums/ChatMessageEnums'
export * from './store/enums/StatusEnums'

// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as CommunicationSettings from './settings';




