import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChatMessage } from '../../../store/ChatMessage';
import { observer } from 'mobx-react';
import InnerRow from '../Message/InnerRow';
import MessageTime from '../Message/Time';
import MessageSeenStatus from '../Message/SeenStatus';
import { MyTextBubble, Text, StyledSender } from '../Message/Bubble';

@observer
export default class MessageTypeTextInternal extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(ChatMessage).isRequired,
        showAttachedFiles: PropTypes.func.isRequired,
    };

    _showAttachedFiles = () => {
        const { model, showAttachedFiles } = this.props;

        if (showAttachedFiles) {
            showAttachedFiles(model.file);
        }
    }

    render() {
        const { model } = this.props;

        return (
            <InnerRow isFromExternal={false} data-test-chat-box-message-from-internal data-test-message={model.id}>
                <MessageTime model={model} />
                <MyTextBubble message={model} unread={false}>
                    <StyledSender data-test-message-sender>{model.writtenBy}</StyledSender>
                    <Text text={model.text} />
                </MyTextBubble>
                <MessageSeenStatus model={model} />
            </InnerRow>
        );
    }
}
