export default {
    nav: {
        main: {
            assets: 'Master data',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog',
            users: 'Gebruikers',
            accessLog: 'Access Log',
            globalValues: 'Instellingen',
            globalFiles: 'Speciale bestanden',
        }
    },
    myFilter: {
        custom: 'Aangepast filter',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Maak Default',
            setCurrent: 'Bewaar huidige filters',
            delete: 'Verwijder filter',
        },
    },
    globalValue: {
        edit: {
            title: 'Globale instellingen bewerken',
        },
        overview: {
            title: 'Globale instellingen',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Speciaal bestand bewerken',
        },
        overview: {
            title: 'Speciale bestanden',
        },
        field: {
            key: {
                label: 'Bestandsnaam',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Voordoen als gebruiker',
            },
            ipAddress: {
                label: 'IP adress',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijd van toegang',
            },
        },
    },
    crash: {
        heading: 'Applicatie is gecrasht',
        subHeading1: 'Oh nee de applicatie is gecrasht!',
        subHeading2: 'Ons team is op de hoogte gebracht.',
        reload: 'Herlaad pagina'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{week}}',
            value: 'W{{week}} - {{year}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'april',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
        period: {
            am: 'AM',
            pm: 'PM'
        }
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    pageNotAllowedMessage: 'U heeft geen toestemming om deze pagina te bekijken',
    common: {
        pagination: {
            limit: 'Limiet'
        },
        overviewRender: {
            mode: 'Tabel stijl',
            type: {
                relaxed: 'Relaxed',
                compact: 'Compact'
            }
        }
    },
    history: {
        title: {
            label: 'Historie',
        },
        emptyBox: {
            label: 'Leeg',
        },
        at: 'om',
        for: 'naar',
        added: 'toegevoegd',
        changed: 'veranderd',
        removed: 'verwijderd',
    },
    modal: {
        copyToClipboard: 'Kopieer naar clipboard',
        copiedSuccessfully: 'Gekopieerd',
    },
    color: {
        yellow: 'Geel',
        orange: 'Oranje',
        darkorange: 'Donkeroranje',
        lightred: 'Lichtrood',
        red: 'Rood',
        darkred: 'Donkerrood',
        lightpink: 'Lichtroze',
        pink: 'Roze',
        darkpink: 'Donkerroze',
        lightviolet: 'Lichtviolet',
        violet: 'Violet',
        darkviolet: 'Donkerviolet',
        lightpurple: 'Lichtpaars',
        purple: 'Paars',
        darkpurple: 'Donkerpaars',
        lightindigo: 'Lichtindigo',
        indigo: 'Indigo',
        darkindigo: 'Donkerindigo',
        lightblue: 'Lichtblauw',
        blue: 'Blauw',
        darkblue: 'Donkerblauw',
        lightteal: 'Licht blauwgroen',
        teal: 'Blauwgroen',
        darkteal: 'Donker blauwgroen',
        lightgreen: 'Lichtgroen',
        green: 'Groen',
        darkgreen: 'Donkergroen',
        lightlime: 'Licht geelgroen',
        lime: 'Geelgroen',
        darklime: 'Donker geelgroen',
        lightbrown: 'Lichtbruin',
        brown: 'Bruin',
        darkbrown: 'Donkerbruin',
        lightgrey: 'Lichtgrijs',
        grey: 'Grijs',
        black: 'Zwart',
        none: 'Geen kleur',
    },
    serialNumberFormat: {
        part: {
            text: {
                label: 'Tekst',
                content: 'Inhoud',
            },
            date: {
                label: 'Datum',
                part: {
                    label: 'Onderdeel',
                    year: { label: 'Jaar' },
                    month: {
                        label: 'Maand',
                        text: 'Tekst',
                        names: {
                            january: {
                                label: 'Januari',
                                value: 'JAN',
                            },
                            february: {
                                label: 'Februari',
                                value: 'FEB',
                            },
                            march: {
                                label: 'Maart',
                                value: 'MAA',
                            },
                            april: {
                                label: 'April',
                                value: 'APR',
                            },
                            may: {
                                label: 'Mei',
                                value: 'MEI',
                            },
                            june: {
                                label: 'Juni',
                                value: 'JUN',
                            },
                            july: {
                                label: 'Juli',
                                value: 'JUL',
                            },
                            august: {
                                label: 'August',
                                value: 'AUG',
                            },
                            september: {
                                label: 'September',
                                value: 'SEP',
                            },
                            october: {
                                label: 'Oktober',
                                value: 'OKT',
                            },
                            november: {
                                label: 'November',
                                value: 'NOV',
                            },
                            december: {
                                label: 'December',
                                value: 'DEC',
                            },
                        },
                    },
                    day: { label: 'Dag' },
                    isoyear: { label: 'ISO-Jaar' },
                    isoweek: { label: 'ISO-Week' },
                    isoweekday: {
                        label: 'ISO-Weekdag',
                        text: 'Tekst',
                        names: {
                            monday: {
                                label: 'Maandag',
                                value: 'MA',
                            },
                            tuesday: {
                                label: 'Dinsdag',
                                value: 'DI',
                            },
                            wednesday: {
                                label: 'Woensdag',
                                value: 'WO',
                            },
                            thursday: {
                                label: 'Donderdag',
                                value: 'DO',
                            },
                            friday: {
                                label: 'Vrijdag',
                                value: 'VR',
                            },
                            saturday: {
                                label: 'Zaterdag',
                                value: 'ZA',
                            },
                            sunday: {
                                label: 'Zondag',
                                value: 'ZO',
                            },
                        },
                    },
                },
                format: 'Format',
            },
            code: {
                label: 'Code',
                alphabet: 'Alfabet',
                digits: 'Aantal',
                expand: 'Uitbreiden',
                defaultAlphabets: {
                    decimal: 'Dec',
                    binary: 'Bin',
                    hexadecimalLower: 'Hex (a)',
                    hexadecimalUpper: 'Hex (A)',
                    octal: 'Oct',
                    alphanumericLower: 'Alfanum (a)',
                    alphanumericUpper: 'Alfanum (A)',
                    alphabeticLower: 'Alfa (a)',
                    alphabeticUpper: 'Alfa (A)',
                    custom: 'Anders, namelijk:',
                },
            },
            anything: {
                label: 'Alles',
            },
            article_type: {
                label: 'Artikeltype Eigenschap',
                prop: {
                    label: 'Eigenschap',
                    code: 'Code',
                    barcode: 'Barcode',

                    metafield: 'Metaveld',
                },
            },
        },
    },
};
