import { observable } from 'mobx';
import { Model, Store, Casts } from '@code-yellow/spider';
import { Driver } from './Driver';
import { TruckPosition } from './TruckPosition';

export const ACTION_NONE = 'none';
export const ACTION_DRIVE = 'drive';
export const ACTION_WORK = 'work';
export const ACTION_WAIT = 'wait';
export const ACTION_REST = 'rest';
export const ACTION_AVAILABILITY = 'availability';
export const ACTIONS = [ACTION_NONE, ACTION_DRIVE, ACTION_WORK, ACTION_WAIT, ACTION_REST, ACTION_AVAILABILITY];

export const DATA_SOURCE_FLEETVISOR = 'fleetvisor';
export const DATA_SOURCE_TRANSICS = 'transics';
export const DATA_SOURCE_DAF = 'daf';
export const DATA_SOURCE_MOBILE_OFFICE = 'mobile office';
export const DATA_SOURCE_FLEETBOARD = 'fleetboard';
export const DATA_SOURCES = [DATA_SOURCE_FLEETVISOR, DATA_SOURCE_TRANSICS, DATA_SOURCE_DAF, DATA_SOURCE_MOBILE_OFFICE, DATA_SOURCE_FLEETBOARD];

export class TachoEvent extends Model {
    static backendResourceName = 'tacho_event';

    @observable id = null;
    @observable action = ACTION_NONE;
    @observable point = { lng: '', lat: '' };
    @observable measuredAt = null;
    @observable dataExternalId = null;
    @observable dataSource = '';
    @observable endDate = null;
    @observable transicsDataResult = '';

    @observable driver = this.relation(Driver);
    @observable truckPosition = this.relation(TruckPosition);

    casts() {
        return {
            measuredAt: Casts.datetime,
            endDate: Casts.datetime,
        };
    }
}

export class TachoEventStore extends Store<TachoEvent> {
    Model = TachoEvent;
    static backendResourceName = 'tacho_event';
}
