import React from 'react';
import { t, TargetSelect } from '@code-yellow/spider';
import AdminOverview from 'component/AdminOverview';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ActiveButtons from 'react-logistics-masterdata/src/component/ActiveButtons';
import { TrailerStore } from '../../store/Trailer';
import { TruckingCompanyStore } from '../../store/TruckingCompany';
import { debounce } from 'lodash';

@observer
export default class TrailerOverviewScreen extends AdminOverview {
    store = new TrailerStore({
        'relations': ['truckingCompany'],
        params: {
        },
    });
    fullWidth = true;
    truckingCompanyStore = new TruckingCompanyStore();
    @observable trailerContent = {};

    title = t('masterData:trailer.overview.title');
    myFilterKey = 'trailer-overview';

    allowExport = true;
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;

    componentDidMount() {
        super.componentDidMount();
        this.truckingCompanyStore.fetch();
    }

    rowProps(item, i) {
        return { 'data-test-trailer': item.id };
    }

    renderButtonActive = (trailer) => {
        return (
            <ActiveButtons
                asset={trailer}
                renderType={this.selectedRenderType}
            />
        );
    };

    formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('ES-CL') : '';
    };

    settings = [
        {
            label: t('masterData:trailer.field.fleetNumber.label'),
            attr: ({ fleetNumber }) => fleetNumber,
            sortKey: 'fleet_number',
            collapsing: true,
            cellProps: { 'data-test-fleet-number': true },
        },
        {
            label: t('masterData:trailer.field.licensePlate.label'),
            attr: ({ licensePlate }) => licensePlate,
            sortKey: 'license_plate',
            cellProps: { 'data-test-license-plate': true },
        },
        {
            label: t('masterData:trailer.field.apkExpiryDate.label'),
            attr: ({ apkExpiryDate }) => this.formatDate(apkExpiryDate),
            sortKey: 'apk_expiry_date',
            cellProps: { 'data-test-apk-expiry-date': true },
        },
        {
            label: t('masterData:trailer.field.cooled.overviewLabel'),
            attr: ({ cooled }) => cooled,
            cellProps: { 'data-test-cooled': true },
        },
        {
            label: t('masterData:trailer.field.lift.overviewLabel'),
            attr: ({ lift }) => lift,
            cellProps: { 'data-test-lift': true },
        },
        {
            label: t('masterData:trailer.field.truckingCompany.label'),
            attr: ({ truckingCompany }) => truckingCompany.name,
            sortKey: 'trucking_company.name',
            cellProps: { 'data-test-trucking-company': true },
        },
        {
            label: t('masterData:trailer.field.active.overviewColumn'),
            attr: this.renderButtonActive,
            sortKey: 'active',
            collapsing: true,
            cellProps: { 'data-test-active': true },
        },
        {
            collapsing: true,
        },
    ];

    buttons = [
        { type: 'edit', to: (u) => `/master-data/trailer/${u.id}/edit` },
    ];

    toolbar = [
        { type: 'add', to: '/master-data/trailer/add', label: t('masterData:trailer.overview.addButton') },
    ];

    filters = [
        {
            type: 'radioButtons',
            name: '.active',
            label: t('masterData:trailer.field.active.label'),
            options: [
                { text: t('masterData:trailer.field.active.options.all'), value: undefined },
                { text: t('masterData:trailer.field.active.options.yes'), value: 'false' },
                { text: t('masterData:trailer.field.active.options.no'), value: 'true' },
            ],
            primary: true
        },
        {
            type: 'text',
            label: t('masterData:truck.field.fleetNumber.label'),
            name: '.fleet_number:icontains',
        },
        {
            type: 'search',
            label: t('masterData:trailer.field.licensePlate.label'),
            name: '.license_plate:icontains',
        },
        {
            type: 'custom',
            callback: () => (
                <TargetSelect
                    multiple
                    remote clearable
                    store={this.truckingCompanyStore}
                    name=".trucking_company.name:icontains"
                    target={this.store}
                    label={t('masterData:trailer.field.owner.label')}
                    placeholder={t('masterData:trailer.field.owner.label')}
                    afterChange={debounce(() => this.store.fetch(), 250)}
                    options={this.truckingCompanyStore.map(item => ({
                        value: item.id,
                        text: item.name,
                    }))}
                />
            )
        },
        {
            type: 'radioButtons',
            name: '.cooled',
            label: t('masterData:trailer.field.cooled.label'),
            options: [
                { text: t('masterData:trailer.field.cooled.options.all'), value: undefined },
                { text: t('masterData:trailer.field.cooled.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.cooled.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.lift',
            label: t('masterData:trailer.field.lift.label'),
            options: [
                { text: t('masterData:trailer.field.lift.options.all'), value: undefined },
                { text: t('masterData:trailer.field.lift.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.lift.options.no'), value: 'false' },
            ],
            primary: true
        },
    ];
}
