import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ChatMessageStore, ChatMessage } from '../../store/ChatMessage';
import Item from '../../component/Chat/MessageOverviewItem';
import { MessageOverview } from '../../component/Chat/Box';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { throttle, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { format } from 'helpers';


export const SCROLL_FETCH_THRESHOLD = 500;

// Delay to render images
export const FINAL_BOTTOM_SCROLL_DELAY = 1000;

const MyDropzone = styled(Dropzone)`
    position: relative;
    display: inline;
`;

const activeStyle = {
    opacity: '0.5'
}

const DateDivider = styled.div`
    position: relative;
    margin: 0 15px;
    width: calc(100% - 30px);
    text-align: center;
    margin-bottom: 15px;
    color: #A9A9A9;

    &:first-child {
        margin-top: 4rem;
    }

    &::before, &&::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 35%;
        transform: translateY(-50%);
        height: 2px;
        background-color: #E9E9E9;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
`;


/**
* Given a list of objects with distances, compute the minimum non-negative
* distance and return the object which satisfies this property.
*
* @param arr array of refs
* @returns object with minimum non-negative distance
*/
const findMinDistance = (arr: { ref: HTMLDivElement, distance: number }[]) => {
   if (arr.length === 0 ) {
       return null;
   }

   return arr.reduce((minDistMessage, message) => {
       return message.distance >= 0 && message.distance < minDistMessage.distance ? message : minDistMessage;
   }, arr[0]);
}


export type MessageOverviewContainerProps<
    MessageType extends ChatMessage = ChatMessage,
    MessageStoreType extends ChatMessageStore<MessageType> = ChatMessageStore<MessageType>
> = {
    onMessageAdd: (f: () => void) => (() => void),
    fetchPage: () => Promise<void>,
    setDatestamp: (text: string) => void,
    onFileUploaded: (files: File[], invalidFiles: File[]) => void,
    showAttachedFiles: (file?: File) => void,
    markRead: (message: MessageType) => void,
    markUnread: (message: MessageType) => void,
    store: MessageStoreType,
}

@observer
export default class MessageOverviewContainer<
    MessageType extends ChatMessage = ChatMessage,
    MessageStoreType extends ChatMessageStore<MessageType> = ChatMessageStore<MessageType>,
    T extends MessageOverviewContainerProps<MessageType, MessageStoreType> = MessageOverviewContainerProps<MessageType, MessageStoreType>
> extends Component<T> {

    @observable firstRender = true;
    inlineDatestampRefs: HTMLDivElement[] = [];
    datestampRef?: HTMLDivElement;
    shouldScrollBottom = false;
    scrollView?: HTMLDivElement;

    componentDidMount() {
        this.props.onMessageAdd(this.guaranteedScrollToBottom);
        this.handleScroll = throttle(this.handleScroll, 50);

        this.guaranteedScrollToBottom();
    }

    UNSAFE_componentWillUpdate() {
        if (!this.scrollView) {
            return;
        }

        this.shouldScrollBottom =
            this.scrollView.scrollTop + this.scrollView.offsetHeight ===
            this.scrollView.scrollHeight;
    }


    // We have a bug where sometimes the older chat message can be added _under_
    // the current scrollPosition.
    // This means a user has scrolled up, sees a message, and suddenly
    // the DOM jumps to a freshly loaded message.
    //
    // This is because the DOM will sometimes "stick" at the current scrollPosition
    // We use these 2 vars to keep track of the scrollPosition before the fetch.
    //
    // After new data is loaded, the scrollPosition of the user should never be
    // higher than the height of the newly added content.
    scrollTopBeforeFetch: number | null = null;
    scrollHeightBeforeFetch: number | null = null;

    // We only want to fetch new data if the scrollTop of the user
    // has dipped below the threshold. Otherwise we might fetch multiple times
    // if the treshold has been reached once.
    contentLoaded = false;

    // eslint-disable-next-line
    componentDidUpdate(props: { [key: string]: any }) {
        if (this.firstRender || this.shouldScrollBottom) {
            this.guaranteedScrollToBottom()
        }
    }

    // After a message has been added, we scroll to bottom.
    // We have to use a setTimeout because the new element is not yet rendered
    // after it has been added to the store.
    //
    // This does introduce a bug:
    // the scrollbar randomly disappears on mac
    deferredScrollToBottom = (delay = FINAL_BOTTOM_SCROLL_DELAY) => {
        setTimeout(this.scrollToBottom, delay);
    };

    scrollToBottom = () => {
        if (this.scrollView) {
            this.scrollView.scrollTop = this.scrollView.scrollHeight;
        }
    };

    guaranteedScrollToBottom = () => {
        // Guarantee scrolling to the bottom by scrolling right away and scrolling
        // again after a short delay to allow for image loading
        this.scrollToBottom();
        this.deferredScrollToBottom(100);
        this.deferredScrollToBottom();
    }

    maybeFixScrollPosition = () => {
        // We have a new bug.
        if (
            this.scrollView &&
            this.scrollView.scrollTop < this.newContentHeight
        ) {
            this.restorePreviousScrollPosition();
        }
    };


    handleScroll = () => {
        const { setDatestamp } = this.props;

        if (!(this.scrollView && this.scrollView.clientHeight)) {
            return;
        }

        const correctionFactor = 5;
        const distances: { ref: HTMLDivElement, distance: number }[] = []

        for (const ref of this.inlineDatestampRefs) {
            distances.push({
                ref: ref,
                distance: this.scrollView.scrollTop - ref?.offsetTop + correctionFactor
            });
        }


        // Take the minimum non-negative ref and set the Datestemp ref innerText to the innerText of that ref
        const closestInlineDatestamp = findMinDistance(distances);

        if (closestInlineDatestamp) {
            setDatestamp?.(closestInlineDatestamp.ref.innerText);
        }


        const thresholdReached =
            this.scrollView.scrollTop <= SCROLL_FETCH_THRESHOLD;

        if (!this.contentLoaded && !thresholdReached) {
            this.contentLoaded = true;
        }

        if (!thresholdReached) {
            return;
        }

        if (!this.contentLoaded) {
            return;
        }

        this.firstRender = false;
        this.snapShotScrollPosition();
        this.props.fetchPage().then(() => {
            this.contentLoaded = false;
            this.maybeFixScrollPosition();
        });
    };

    get newContentHeight() {
        if (!this.scrollView) {
            return 0;
        }

        const scrollHeightBeforeFetch = this.scrollHeightBeforeFetch ?? 0;
        return this.scrollView.scrollHeight - scrollHeightBeforeFetch;
    }

    restorePreviousScrollPosition() {
        if (!this.scrollView) {
            return;
        }

        const scrollTopBeforeFetch = this.scrollTopBeforeFetch ?? 0;
        this.scrollView.scrollTop =
            this.newContentHeight + scrollTopBeforeFetch;
    }

    snapShotScrollPosition() {
        if (!this.scrollView) {
            return;
        }

        this.scrollHeightBeforeFetch = this.scrollView.scrollHeight;
        this.scrollTopBeforeFetch = this.scrollView.scrollTop;
    }

    renderMessage = (model: MessageType) => {
        return (
            <Item
                model={model}
                key={model.id || model.cid}
                markRead={this.props.markRead}
                markUnread={this.props.markUnread}
                showAttachedFiles={this.props.showAttachedFiles}
            />
        );
    };

    handleRef = ref => {
        this.scrollView = ref;
    };

    handleInlineDatestampRef = ref => {
        this.inlineDatestampRefs.push(ref);
    }


    /**
     * Constructs a list of chat messages and datestamps
     *
     * Note that the resulting list contains a number of consecutive chat messages,
     * interrupted by a single datestamp. There can not be consecutive datestamps.
     *
     * @param messageList list of chat messages
     * @returns list of chat messages and objects indicating inline datestamps
     */
    constructContentList = (messageList: MessageType[]): (MessageType | { date?: DateTime })[] => {
        const contentList: ({ date?: DateTime } | MessageType )[] = [];

        // The store is ordered from newest to oldest. The newest messages must
        // be rendered at the bottom, so reverse this page
        const sorted = messageList.sort((a, b) => (a.writtenAt ?? 0) - (b.writtenAt ?? 0))

        for (const message of sorted) {
            const clLength = contentList.length;

            // Base case, add a datestamp and the message
            if (clLength === 0) {
                contentList.push({ date: message.writtenAt ?? undefined });
                contentList.push(message);
                continue;
            }

            // Select the previous message. If the previous element is a datestamp,
            // then the element before it must be a message by construction.
            const prevMessage = 'id' in contentList[clLength - 1] ? contentList[clLength - 1] : contentList[clLength - 2];

            // As a result of the list structure, this case can never occur, but as
            // Typescript isn't aware of the list structure, this is required.
            if (!('id' in prevMessage)) {
                continue;
            }

            // Insert line element if current date and previous date aren't equal
            if (format(message.writtenAt, 'ddLLyyyy') !== format(prevMessage.writtenAt, 'ddLLyyyy')) {
                contentList.push({ date: message.writtenAt ?? undefined });
            }

            contentList.push(message);
        }

        return contentList;
    }

    /**
     * Render a message or date change line
     *
     * @param {MessageType | Object} item Message or date change line to render
     */
    renderContent = (item: MessageType | { date?: DateTime }) => {
        if ('id' in item) {
            return this.renderMessage(item)
        }

        // WARNING: Code assumes that innerRef={this.handleInlineDatestampRef} only calls
        // handleInlineDatestampRef on initial render and NOT during updates. The list is
        // never reset to empty.
        return <DateDivider innerRef={this.handleInlineDatestampRef}>{format(item.date, 'dd LLL yyyy')}</DateDivider>
    }

    render() {
        const { onFileUploaded } = this.props;

        const messageList = uniqBy(this.props.store.models, 'id');
        const contentList: (MessageType | { date?: DateTime })[] = this.constructContentList(messageList);

        return (
            <MessageOverview data-test-chat-box-messages
                innerRef={this.handleRef}
                onScroll={this.handleScroll}
            >
                <MyDropzone
                    activeStyle={activeStyle}
                    onDrop={onFileUploaded}
                    accept={'image/jpeg, image/png, application/pdf'}
                    multiple={true}
                    disableClick={true}
                >
                    {contentList.map(this.renderContent)}
                </MyDropzone>
            </MessageOverview>
        );
    }
}
