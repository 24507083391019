import Load from '../../../container/Loadable';

const ContractOverviewScreen = Load(() => import('react-core-finance/src/screen/Contract/Overview'));
const ContractEditScreen = Load(() => import('react-core-finance/src/screen/Contract/Edit'));
const EntityOverviewScreen = Load(() => import('react-core-finance/src/container/Entity/Overview'));
const EntityEditScreen = Load(() => import('react-core-finance/src/container/Entity/Edit'));
const LedgerOverviewScreen = Load(() => import('react-core-finance/src/screen/Ledger/Overview'));
const LedgerEditScreen = Load(() => import('react-core-finance/src/screen/Ledger/Edit'));
const InvoiceOverviewScreen = Load(() => import('../screen/Invoice/Overview'));
const InvoiceEditScreen = Load(() => import('../screen/Invoice/Edit'));

export const financeRoutesConfig = [
    { path: '/finance/contract/overview', component: ContractOverviewScreen },
    { path: '/finance/contract/:id/edit', component: ContractEditScreen },
    { path: '/finance/contract/add', component: ContractEditScreen },

    { path: '/finance/invoice/overview', component: InvoiceOverviewScreen },
    { path: '/finance/invoice/:id/edit', component: InvoiceEditScreen },
    { path: '/finance/invoice/add', component: InvoiceEditScreen },

    { path: '/finance/entity/overview', component: EntityOverviewScreen },
    { path: '/finance/entity/:id/edit', component: EntityEditScreen },
    { path: '/finance/entity/add', component: EntityEditScreen },

    { path: '/finance/ledger/overview', component: LedgerOverviewScreen },
    { path: '/finance/ledger/:id/edit', component: LedgerEditScreen },
    { path: '/finance/ledger/add', component: LedgerEditScreen },
];
