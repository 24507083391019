enum InvoiceStatus {
    DRAFTED = 'drafted',
    SENT = 'sent',
    PAID = 'paid',
    OVERDUE = 'overdue',
    ERROR = 'error',
}

export default InvoiceStatus;
export type InvoiceStatusType = keyof typeof InvoiceStatus;
