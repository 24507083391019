// Hacky helper to ease working with modals.
import { Component } from 'react';
import { SpecialViewStore } from '../index';

let viewStore: SpecialViewStore | null = null;

export function configureModal(givenViewStore: SpecialViewStore) {
    viewStore = givenViewStore;
}

export default function show(Modal: typeof Component, props = {}) {
    viewStore?.setModal({
        render: Modal,
        ...props,
    });
}

export function hideModal() {
    viewStore?.setModal(null);
}