import { tint } from 'polished';
import { IS_STAGE, IS_UAT } from 'helpers';

const COLOR_PRIMARY_PROD = '#00469E';
const COLOR_PRIMARY_STAGE = '#7dcfb6';
const COLOR_PRIMARY_UAT = '#00b2ca';

const get_primary_color = () => {
	switch (true) {
		case IS_STAGE:
			return COLOR_PRIMARY_STAGE;
        case IS_UAT:
            return COLOR_PRIMARY_UAT;
        default:
			return COLOR_PRIMARY_PROD;
	}
};

const primaryColor = get_primary_color();
const lightTintedPrimaryColor = tint(0.05, primaryColor);
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';
const toolbarColor = '#e0e0e0';
const lightColor = '#f5f5f5';
const lightAgBlue = 'var(--ag-light-blue)';

export const theme = {
    primaryColor,
    lightTintedPrimaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    lightColor,
    warningColor,
    toolbarColor,
    lightAgBlue,
};
