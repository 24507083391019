import { observable } from 'mobx';
import { Model, Store } from '@code-yellow/spider';
import { DriverActionDocumentStore } from './DriverActionDocument';

export class DriverAction extends Model {
    static backendResourceName = 'driver_app_action';

    @observable id = null;
    @observable type = '';
    @observable label = '';
    @observable description = '';
    @observable required = false;
    @observable value = '';
    @observable checked = false;
    @observable documents = this.relation(DriverActionDocumentStore);
}

export class DriverActionStore extends Store {
    Model = DriverAction;
    static backendResourceName = 'driver_app_action';
}