import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import React, { Component } from 'react';
import { observer } from 'mobx-react';

const baseCss = css`
    position: relative;
    padding: 12px 16px 22px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    border-radius: 8px;
    flex: 1;
    max-width: 80%;
    white-space: pre-line;
    font-size: 12px;
`;

export const OtherTextBubble = styled.div`
    ${baseCss};
    background-color: #e9e9e9;
    font-weight: ${props => (props.unread ? 'bold' : 'normal')};
    border-bottom-left-radius: 0;
`;

export const MyTextBubble = styled.div`
    ${baseCss};
    ${props => props.message && props.message.sendAfter
        ? 'background-color: #cbdfe650;'
        : 'background-color: #cbdfe6;'
    }
    border-bottom-right-radius: 0;
    padding-bottom: 20px;
`;

export const DangerTextBubble = styled.div`
    ${baseCss};
    background-color: #ed8587;
`;

export const StyledText = styled.div`
    font-size: 12px;
    display: inline;
`;

export const StyledSender = styled.div`
    margin-bottom: 4px;
    font-size: 10px;
    display: block;
    float: right;
    position: relative;
`;

@observer
export class Text extends Component {
    static propTypes = {
        text: PropTypes.string,
    };

    render() {
        const { text } = this.props;

        return <StyledText data-test-message-text>{text}</StyledText>;
    }
}
