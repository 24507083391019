// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

i18next.addResourceBundle('nl', 'driverApp', nl, true);
i18next.addResourceBundle('en', 'driverApp', en, true);
i18next.fallbackNS = ['driverApp']

// Components
export * from './component/Driver/DriverActivitiesPreview';
export * from './component/Driver/UserAuthentication';

// Screen

// Store
export * from './store/DriverAction'
export * from './store/DriverActionDocument'
export * from './store/DriverActionTemplate'
export * from './store/DriverActivity'
export * from './store/DriverLocation'


// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as DriverAppSettings from './settings';




