import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';

export class DriverActionTemplate extends Model {
    static backendResourceName = 'driver_app_action_template';

    @observable id = null;
    @observable name = '';
    @observable type = '';
    @observable label = '';
    @observable description = '';
    @observable required = false;
    @observable value = '';
    @observable checked = false;
}

export class DriverActionTemplateStore extends Store {
    Model = DriverActionTemplate;
    static backendResourceName = 'driver_app_action_template';
}
