export const NEW = 'new';
export const ACCEPTED = 'accepted';
export const PLANNED = 'planned';
export const IN_PROGRESS = 'in_progress';
export const COMPLETED = 'completed';
export const ERROR = 'error';
export const CANCELED = 'canceled';
export const ORDER_STATUSES = [ACCEPTED, PLANNED, IN_PROGRESS, COMPLETED, ERROR, CANCELED]
export const ORDER_INVOICE_STATUSES = [ACCEPTED, PLANNED, IN_PROGRESS, COMPLETED]

export default { NEW, ACCEPTED, PLANNED, IN_PROGRESS, COMPLETED, CANCELED, ERROR }
