export enum COLORS {
    yellow='#FFD000',
    orange='#FFA000',
    darkorange='#FF6D00',
    lightred='#FF7A73',
    red='#FF3D00',
    darkred='#B71C1C',
    lightpink='#FF5F96',
    pink='#F50057',
    darkpink='#871C55',
    lightviolet='#E98FFF',
    violet='#CC36EE',
    darkviolet='#9100B2',
    lightpurple='#C2A0FB',
    purple='#9562F5',
    darkpurple='#5F1FE5',
    lightindigo='#7785FA',
    indigo='#304FFE',
    darkindigo='#0008B7',
    lightblue='#82B1FF',
    blue='#448AFF',
    darkblue='#2962FF',
    lightteal='#00DEBC',
    teal='#12BBA0',
    darkteal='#00A8A8',
    lightgreen='#67DA95',
    green='#00AF58',
    darkgreen='#1B5E20',
    lightlime='#CDD652',
    lime='#C3B432',
    darklime='#857800',
    lightbrown='#A1887F',
    brown='#795548',
    darkbrown='#3E2723',
    lightgrey='#AFAFB9',
    grey='#676770',
    black='#1B1B1B',
    none='#00469E'
}


export function getColor(string: string | undefined) {
    if (!string || string === '') {
        return COLORS['none']
    }

    return COLORS[string]
}
