import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Table, Popup } from 'semantic-ui-react';
import { format, DATETIME_FORMAT } from 'helpers';
import { ChatMessage, ChatMessageRecipientStore } from 'react-core-communication/src/index';
import { snakeCase } from 'lodash';
import { t } from '@code-yellow/spider';

type ReceivedSeenPopupProps = {
    message: ChatMessage,
    trigger: React.ReactNode,
    label: string,
    messageField: string,
    messageRecipientField: string,
};

@observer
export default class ReceivedSeenPopup extends Component<ReceivedSeenPopupProps> {

    @observable messageRecipientStore = new ChatMessageRecipientStore({
        params: {
            '.message': this.props.message.id,
            [`.${snakeCase(this.props.messageRecipientField)}:not:isnull`]: true,
        },
    });

    render() {
        const { message, label, messageField, messageRecipientField, ...props } = this.props;

        return (
            <Popup flowing
                {...props}
                position="bottom right"
                onOpen={() => this.messageRecipientStore.fetch()}
            >
                {`${label}: ${format(message[messageField], DATETIME_FORMAT)}`}
                <br />
                {this.messageRecipientStore.filter(mr => !!mr[messageRecipientField]).length > 0 && (
                    <Table compact="very">
                        <Table.Header>
                            <Table.HeaderCell content={t('user.field.name.label')} />
                            <Table.HeaderCell content={t(`message.field.${messageField}.label`)} />
                        </Table.Header>
                        {this.messageRecipientStore.filter(mr => !!mr[messageRecipientField]).map(mr => (
                            <Table.Row>
                                <Table.Cell>{mr.user.fullName}</Table.Cell>
                                <Table.Cell>{format(mr[messageRecipientField], DATETIME_FORMAT)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table>
                )}
            </Popup>
        );
    }
}
