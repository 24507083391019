export default {
    nav: {
        finance: {
            label: 'Finance',
            invoice: 'Invoices',
            contract: 'Contracts',
            ledger: 'Ledgers',
        },
    },
    contract: {
        create: {
            title: 'Add contract',
        },
        edit: {
            title: 'Edit contract {{name}}'
        },
        overview: {
            title: 'Contracts',
            addButton: 'Add contract',
        },
        tab: {
            info: 'Basic information'
        },
        field: {
            name: {
                label: 'Name'
            },
            customer: {
                label: 'Customer'
            },
            startDate: {
                label: 'Start date'
            },
            endDate: {
                label: 'End date'
            }
        }
    },
    ledger: {
        overview: {
            title: 'Ledger overview',
        },
        create: {
            title: 'New Ledger'
        },
        edit: {
            title: 'Edit Ledger',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            number: {
                label: 'Number',
            },
            description: {
                label: 'Description',
            },
            vatCode: {
                label: 'Vat Code',
            },
            vatName: {
                label: 'Vat Name',
            },
            vatPercentage: {
                label: 'Vat Percentage',
                noVat: 'No Vat',
            },
        },
    },
    invoice: {
        create: {
            title: 'New invoice {{number}}'
        },
        edit: {
            noContract: 'No contract',
        },
        overview: {
            title: 'Invoices',
        },
        field: {
            id: {
                label: 'ID',
            },
            contract: {
                label: 'Contract'
            },
            issueDate: {
                label: 'Issue date'
            },
            number: {
                label: 'Number'
            },
            reference: {
                label: 'Reference'
            },
            remarks: {
                label: 'Remarks'
            },
            totalAmount: {
                label: 'Total amount'
            },
            dueDate: {
                label: 'Due date'
            },
            customer: {
                label: 'Customer'
            },
        }
    },
    invoiceLine: {
        field: {
            description: {
                label: 'Description'
            },
            amount: {
                label: 'Amount'
            },
            vat: {
                label: 'VAT'
            },
            totalAmount: {
                label: 'Total amount'
            }
        }
    }
};
