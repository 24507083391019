import React from 'react'
import { AdminEditScreen as BaseAdminEditScreen } from '@code-yellow/spider'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Content } from 're-cy-cle'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import Toolbar from 'component/Toolbar'


const StyledBackIcon = styled(Icon)`
    cursor: pointer;
    margin-right: 1.5rem!important;
`

export const TopSection = styled.section`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 8px 8px 24px;
    background-color: var(--gray-50);
    width: 100%;
    height: 48px;
    border-bottom: 2px solid var(--gray-300);
`

@observer
export default class AdminEditScreen extends BaseAdminEditScreen {
    @observable title = ''
    Toolbar = Toolbar

    renderTitle() {
        return this.title
    }

    renderHeader() {
        return (
            <TopSection>
                {!this.isModal ? <StyledBackIcon onClick={() => window.history.back()} name='arrow left' size='small' /> : null}
                {this.renderTitle()}
            </TopSection>
        )
    }

    renderFullContent() {
        return (
            <>
                {this.renderHeader()}
                <Content>
                    {this.renderContent()}
                </Content>
            </>
        )
    }

    renderEdit() {
        return (
            <this.Body>
                {this.renderFullContent()}
                {this.renderToolbar()}
            </this.Body>
        )
    }
}
