import { showSaveNotification, showErrorNotification, Model, Store } from '@code-yellow/spider';
import { computed, observable, action } from 'mobx';
import { Entity } from './Entity';
import { CustomerGroup } from './CustomerGroup';
import { ContractStore } from 'react-core-finance/src/store/Contract';
import InvoiceLanguage from 'react-core-finance/src/store/enums/InvoiceLanguage';
import { VatCodeDe, VatCodeEn, VatCodeNl } from 'react-core-administration/src/store/enums/VatCode';

export class Customer extends Model {
    static backendResourceName = 'customer';

    @observable id: number | null = null;
    @observable name = '';
    @observable reference = '';
    @observable phone = '';
    @observable emailAddress = ''
    @observable chamberOfCommerce = '';
    @observable iban = '';
    @observable emailFromName = '';
    @observable emailFromAddress = '';
    @observable emailFromNameDocumentsInvoices = '';
    @observable emailFromAddressDocumentsInvoices = '';
    @observable paymentTerm = 60;
    @observable creditLimitAmount = 1000000; // in cents
    @observable creditLimitExpiryDate = null;
    @observable visitingAddress = '';
    @observable visitingNumber = '';
    @observable visitingZipCode = '';
    @observable visitingCity = '';
    @observable visitingCountry = '';

    @observable invoicingAddress = '';
    @observable invoicingNumber = '';
    @observable invoicingZipCode = '';
    @observable invoicingCity = '';
    @observable invoicingCountry = '';
    @observable contactType = null;
    @observable emailAddressUpdate ='';
    @observable debtorNumber = '';
    @observable vatNumber = '';
    @observable vatCode: VatCodeEn | VatCodeDe | VatCodeNl | null = null;
    @observable invoiceLanguage: InvoiceLanguage = InvoiceLanguage.ENGLISH;


    @observable createdAt = null;
    @observable updatedAt = null;

    @observable entity = this.relation(Entity);
    @observable group = this.relation(CustomerGroup);
    @observable contracts = this.relation(ContractStore);

    @computed get displayName() {
        // Filters are there to prevent empty strings from being joined
        let address = [this.visitingZipCode, this.visitingCity].filter(x=>!!x).join(', ');
        address = address ? `[${address}]` : address;

        return [this.debtorNumber, this.name, address].filter(x=>!!x).join(' ');
    }

    @action
    saveCustomerLocation(locationId, companyName) {
        return this.api.post(`customer/${this.id}/store-customer-location/`, {
            locationId: locationId,
            companyName: companyName,

        }).then(() => {
            showSaveNotification()
        })
        .catch((e) => {
            showErrorNotification(e)
        });
    }
}

export class CustomerStore<M extends Customer = Customer> extends Store<M> {
    Model = Customer;
    static backendResourceName = 'customer';
}
