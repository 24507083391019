import React, { Component } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { t, RightDivider, TargetSelect, showSaveNotification, CancelButton } from '@code-yellow/spider';

import { CustomerGroup, CustomerGroupStore } from 'react-core-administration/src/store/CustomerGroup';


@observer
export default class AssignToCustomerGroupModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        selection: PropTypes.array.isRequired,
        afterSave: PropTypes.func,
        onClose: PropTypes.func,
    };

    @observable selectedCustomerGroup = new CustomerGroup();
    @observable customerGroupStore = new CustomerGroupStore();

    close = () => this.props.onClose?.()

    allocate = () => {
        const { selection } = this.props;

        const promiseArray = [];

        // Perform the bulk action
        selection.forEach(customer => {
            customer.group = this.selectedCustomerGroup || new CustomerGroup();
            promiseArray.push(customer.save())
        });

        Promise.all(promiseArray)
            .then(showSaveNotification)
            .then(() => this.selectedCustomerGroup = null)
            .then(this.props.afterSave?.())
            .then(this.close);
    }

    renderContent() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <Form>
                <TargetSelect search remote clearable fluid
                    searchKey=".name:icontains"
                    label={t('administration:customer.field.customerGroup.label')}
                    name="group"
                    store={this.customerGroupStore}
                    toOption={(cg) => ({ value: cg.id, text: cg.name })}
                    onChange={(value) => this.selectedCustomerGroup = this.customerGroupStore.get(value)}
                />
            </Form>
        );
    }

    render() {
        return (
            <Modal data-test-assign-to-customer-group-modal
                open={this.props.isOpen}
                onClose={this.close}
                size="mini"
            >
                <Modal.Header>
                    {t('administration:customer.overview.assignToCustomerGroupModal.title')}
                </Modal.Header>
                <Modal.Content>
                    {this.renderContent()}
                </Modal.Content>
                <Modal.Actions style={{ display: 'flex' }}>
                    <CancelButton data-test-assign-to-customer-group-modal-cancel
                        content={t('administration:customer.overview.assignToCustomerGroupModal.cancelButton')}
                        onClick={this.close}
                    />
                    <RightDivider />
                    <Button primary data-test-assign-to-customer-group-modal-confirm
                        labelPosition="left" icon="check" content={t('administration:customer.overview.assignToCustomerGroupModal.saveButton')}
                        onClick={this.allocate}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
